
<template>
  <div id="app">
    <!-- <TopNav
      :userLoggedIn="userLoggedIn"
      :goToDrawing="goToDrawing"
      :goToStore="goToStore"
      :goToProfile="goToProfile"
    /> -->
    <div class="bg" :style="{height: pageHeight}"  v-resize="handleResize">
      <div class="leftnav" v-if="!isMobile" :class="lightmode=='day' ? 'day-left-nav-bg' : 'dark-left-nav-bg'">
        <div>
          <div class="router-item-back">
            <div @click="gotoPage(0)" class="router-item">
              <img class="nav-image" v-show="selectItem==0" src="@/assets/nav-chat-highlight.png" alt="">
              <img class="nav-image" v-show="selectItem!=0 && lightmode=='day'" src="@/assets/nav-chat-normal.png" alt="">
              <img class="nav-image" v-show="selectItem!=0 && lightmode=='dark'" src="@/assets/dark-nav-chat-normal.png" alt="">
            </div>
            <span class="item-text" v-if="lightmode=='day'">聊天</span>
            <span class="item-text" :class="selectItem==0 ? 'dark-left-nav-text-highlight' : 'dark-left-nav-text-normal'" v-if="lightmode=='dark'">聊天</span>
          </div>
          <div class="router-item-back">
            <div @click="gotoPage(1)" class="router-item">
              <img class="nav-image" v-show="selectItem==1" src="@/assets/nav-draw-highlight.png" alt="">
              <img class="nav-image" v-show="selectItem!=1 && lightmode=='day'" src="@/assets/nav-draw-normal.png" alt="">
              <img class="nav-image" v-show="selectItem!=1 && lightmode=='dark'" src="@/assets/dark-nav-draw-normal.png" alt="">
            </div>
            <span class="item-text" v-if="lightmode=='day'">绘画</span>
            <span class="item-text" :class="selectItem==1 ? 'dark-left-nav-text-highlight' : 'dark-left-nav-text-normal'" v-if="lightmode=='dark'">绘画</span>
          </div>
          <div class="router-item-back">
            <div @click="gotoPage(2)" class="router-item">
              <img class="nav-image" v-show="selectItem==2" src="@/assets/nav-gallery-highlight.png" alt="">
              <img class="nav-image" v-show="selectItem!=2 && lightmode=='day'" src="@/assets/nav-gallery-normal.png" alt="">
              <img class="nav-image" v-show="selectItem!=2 && lightmode=='dark'" src="@/assets/dark-nav-gallery-normal.png" alt="">
            </div>
            <span class="item-text" v-if="lightmode=='day'">画廊</span>
            <span class="item-text" :class="selectItem==2 ? 'dark-left-nav-text-highlight' : 'dark-left-nav-text-normal'" v-if="lightmode=='dark'">画廊</span>
          </div>
          <div class="router-item-back">
            <div @click="gotoPage(3)" class="router-item">
              <img class="nav-image" v-show="selectItem==3" src="@/assets/nav-notice-highlight.png" alt="">
              <img class="nav-image" v-show="selectItem!=3 && lightmode=='day'" src="@/assets/nav-notice-normal.png" alt="">
              <img class="nav-image" v-show="selectItem!=3 && lightmode=='dark'" src="@/assets/dark-nav-notice-normal.png" alt="">
            </div>
            <span class="item-text" v-if="lightmode=='day'">公告</span>
            <span class="item-text" :class="selectItem==3 ? 'dark-left-nav-text-highlight' : 'dark-left-nav-text-normal'" v-if="lightmode=='dark'">公告</span>
          </div>
        </div>
        <div class="bottom">
          <Tooltip max-width="200" content="主题" placement="right">
            <img class="theme-icon" v-if="lightmode=='day'" @click="changeTheme()" src="@/assets/theme-day.png"/>
            <img class="theme-icon" v-if="lightmode=='dark'" @click="changeTheme()" src="@/assets/theme-dark.png"/>
          </Tooltip>
          <!-- <div style="width: 100%;text-align: center;background-color: red;">
            <img class="theme-icon" v-if="lightmode=='day'" @click="changeTheme()" src="@/assets/theme-day.png"/>
            <img class="theme-icon" v-if="lightmode=='dark'" @click="changeTheme()" src="@/assets/theme-dark.png"/>
          </div> -->
          <!-- <div class="login-back" v-if="isLogin==false" @click="showLogin=true">账号登录</div> -->
          <div class="login-back" v-if="isLogin==false" @click="showSMSLogin=true">登录</div>
          <div class="bottom-head-back" v-if="isLogin==true && avatar != null && avatar.length > 0" alt="头像丢失">
            <Tooltip max-width="200" content="个人中心" placement="right">
              <img @click="gotoPage(5)" :src="avatar" class="head-back" />
            </Tooltip>
          </div>
          <div class="bottom-head-back" v-if="isLogin==true && (avatar == null || avatar.length == 0)">
            <Tooltip max-width="200" content="个人中心" placement="right">
              <img @click="gotoPage(5)" src="@/assets/default_head.png" class="head-back" />
            </Tooltip>
          </div>
        </div>
      </div>
      

      <Modal v-model="showSMSLogin" footer-hide>
        <div class="sms-back">
          <h1 class="sms-login-title">{{ loginOrRegister == 1 ? '登录郭天祥 AI，开启你的AI旅程' : '注册郭天祥 AI' }}</h1>
          <Form :model="smsLogin">
            <FormItem>
              <div class="sms-login-item">
                <Input type="tel" v-model="smsLogin.phone" :border="false" placeholder="手机号" />
              </div>
            </FormItem>
            <FormItem>
              <div class="sms-login-item">
                <Input v-model="smsLogin.code" size="small" :border="false" placeholder="验证码" />
                <span @click="sendSMS" class="send-sms" :style="{color: sendSMSColor}">{{ sendSmsText }}</span>
              </div>
            </FormItem>
            <Button type="success" class="login-btn" long @click="performSmsLogin">{{ loginOrRegister == 1 ? '登录': '注册并登录'}}</Button>
            <div class="register-back">
              <span @click="changeLoginOrRegister" class="register-btn">{{ loginOrRegister == 1 ? '没有账号？点击注册' : '我有账号，直接登录' }}</span>
            </div>
            <div class="sms-login-footer">
              <span>登录/注册代表同意</span>
              <span class="book" @click="ruleClicked">《郭天祥AI服务条款》</span>
              <!-- <span class="book" @click="privacyClicked">《隐私政策》</span> -->
            </div>
          </Form>
        </div>
      </Modal>

      <Modal v-model="showRuleModal" footer-hide width="1080">
        <div class="sms-back">
          <h1 class="sms-login-title">郭天祥AI服务条款</h1>
          <div>
            <p class="sms-login-content">
本网站及位于 ai.pnxuetang.com 及其他子域名的服务，包括网站、小程序、移动端App（统称为“本网站”）是乐为公司的著作产品。
<br>本网站旨在提高您的工作和学习效率，解决您工作和学习中的问题。
<br>在使用本网站提供的服务前，请您务必仔细阅读并理解本《服务条款》（以下简称"本条款”），登录本网站将视为您接受本条款的全部内存
<br>&nbsp;&nbsp;&nbsp;&nbsp;*禁止发布、传播危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一，损害国家荣誉，损害国家利益的内容；
<br>&nbsp;&nbsp;&nbsp;&nbsp;*禁止发布、传播歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的的内容；
<br>&nbsp;&nbsp;&nbsp;&nbsp;*禁止发布、传播宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的内容；
<br>&nbsp;&nbsp;&nbsp;&nbsp;*禁止发布、传播任何违法、淫秽、色情、赌博、暴力、恐怖或煽动犯罪的内容；
<br>&nbsp;&nbsp;&nbsp;&nbsp;*禁止发布、传播侵犯他人知识产权或其他合法权益的内容；
<br>&nbsp;&nbsp;&nbsp;&nbsp;*禁止发布、传播误导、欺诈、虚假信息或进行任何不诚实的行为；
<br>&nbsp;&nbsp;&nbsp;&nbsp;*禁止发布、传播政治宣传或进行任何政治活动；
<br>&nbsp;&nbsp;&nbsp;&nbsp;*禁止从事危害网络安全的行为，包括但不限于恶意攻击、恶意破坏、恶意干扰等；
<br>&nbsp;&nbsp;&nbsp;&nbsp;*禁止从事影响本网站正常运行的行为，包括但不限于非法使用本网站的资源、恶意注册、恶意请求等；
<br>&nbsp;&nbsp;&nbsp;&nbsp;*禁止发布、传播中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容等；
<br>&nbsp;&nbsp;&nbsp;&nbsp;若用户违反上述规定，我们有权立即终止本协议并禁止用户使用本网站。
<br><br>隐私政策
<br>&nbsp;&nbsp;&nbsp;&nbsp;*我们尊重用户的隐私权，并承诺在使用用户的个人信息时遵守相关法律法规。我们将采取合理的安全措施保护用户的个人信息，但不对因不可抗力或非因我们的原因导致的信息泄露承担责任。
<br><br>免责声明
<br>&nbsp;&nbsp;&nbsp;&nbsp;*本网站 提供的信息和服务仅供参考，不构成任何担保或承诺。我们不保证本网站 的信息和服务的准确性、可靠性、完整性、有效性、及时性、适用性。用户使用本网站的信息和服务所产生的风险由用户自行承担。
<br><br>变更和终止
<br>&nbsp;&nbsp;&nbsp;&nbsp;*我们有权随时修改本协议的任何条款，并将修改后的协议在本网站上公布。若用户继续使用本网站，即表示用户同意受修改后的协议约束。若用户不同意修改后的协议，应立即停止使用本网站。
<br>&nbsp;&nbsp;&nbsp;&nbsp;*我们有权在任何时候终止本协议，且无需提前通知用户。在协议终止后，用户无权继续使用本网站。
<br><br>适用法律
<br>&nbsp;&nbsp;&nbsp;&nbsp;*本协议的订立、执行、解释及争议的解决均适用中华人民共和国法律。如发生本协议与中华人民共和国法律相抵触时，应以中华人民共和国法律的明文规定为准。
如双方就本协议内容或执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向本网站所在地的人民法院提起诉讼。
<br><br>其他
<br>&nbsp;&nbsp;&nbsp;&nbsp;*本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。
<br>&nbsp;&nbsp;&nbsp;&nbsp;*如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
<br>&nbsp;&nbsp;&nbsp;&nbsp;*本协议中的标题仅供方便参阅，不具有实际意义，不能作为本协议涵义解释的依据。
<br>&nbsp;&nbsp;&nbsp;&nbsp;*本协议未尽事宜，您需遵守我们不时发布的其他服务条款和操作规则。
<br>&nbsp;&nbsp;&nbsp;&nbsp;*本协议自您接受之日起生效，对我们和用户均具有约束力。
            </p>
          </div>
          <div class="rulebtn-back">
            <Button type="success" @click="showRuleModal=false">我已知悉并愿意遵守上述的相关服务条款</Button>
          </div>
        </div>
      </Modal>

      <!-- Login Dialog -->
      <Modal v-model="showLogin" title="登录" @on-cancel="closeLoginDialog" footer-hide>
        <Tabs v-model="currentTab">
          <Tab-pane label="账号密码登录" name="account">
            <Form :model="accountLogin">
              <FormItem>
                <i-input v-model="accountLogin.username" placeholder="用户名" />
              </FormItem>
              <FormItem>
                <i-input v-model="accountLogin.password" type="password" placeholder="密码" />
              </FormItem>
              <Button type="success" class="makesure-btn" long @click="performAccountLogin">登录</Button>
            </Form>
          </Tab-pane>
        </Tabs>
      </Modal>
      <Modal
          v-model="payModal"
          title="支付结果" footer-hide width="400">
          <!-- <div class="pay-result-back" v-if="paySuccess == 0">等待支付中</div> -->
          <div class="pay-result-back" v-if="paySuccess == 1">
            <Icon size="50" color="#08C160" type="ios-checkmark-circle" />
            支付成功
          </div>
          <div class="pay-result-back" v-if="paySuccess == 2">
            <Icon size="50" color="#ff0000" type="ios-close-circle" />
            支付失败
          </div>
      </Modal>

      <div class="routerview-back">
        <div class="routerview">
          <router-view/>
        </div>
        <div class="bootom-nav" v-if="isMobile && selectItem < 10">
          <div @click="gotoPage(0)" :class="selectItem==0 ? 'bottom-nav-item-highlight': 'bottom-nav-item-normal'">
            <!-- <Icon type="md-chatboxes" size="30" /> -->
            <img class="phone-nav-icon" v-if="selectItem==0" src="@/assets/phone-nav-chat-highlight.png"/>
            <img class="phone-nav-icon" v-if="selectItem!=0" src="@/assets/phone-nav-chat-normal.png"/>
            聊天
          </div>
          <div @click="gotoPage(1)" :class="selectItem==1 ? 'bottom-nav-item-highlight': 'bottom-nav-item-normal'">
            <!-- <Icon type="md-color-palette" size="30" /> -->
            <img class="phone-nav-icon" v-if="selectItem==1" src="@/assets/phone-nav-draw-highlight.png"/>
            <img class="phone-nav-icon" v-if="selectItem!=1" src="@/assets/phone-nav-draw-normal.png"/>
            绘画
          </div>
          <div @click="gotoPage(2)" :class="selectItem==2 ? 'bottom-nav-item-highlight': 'bottom-nav-item-normal'">
            <!-- <Icon type="ios-image" size="30" /> -->
            <img class="phone-nav-icon" v-if="selectItem==2" src="@/assets/phone-nav-gallery-highlight.png"/>
            <img class="phone-nav-icon" v-if="selectItem!=2" src="@/assets/phone-nav-gallery-normal.png"/>
            画廊
          </div>
          <div @click="gotoPage(3)" :class="selectItem==3 ? 'bottom-nav-item-highlight': 'bottom-nav-item-normal'">
            <!-- <Icon type="ios-alert" size="30" /> -->
            <img class="phone-nav-icon" v-if="selectItem==3" src="@/assets/phone-nav-notice-highlight.png"/>
            <img class="phone-nav-icon" v-if="selectItem!=3" src="@/assets/phone-nav-notice-normal.png"/>
            公告
          </div>
          <div @click="gotoPage(5)" :class="selectItem==5 ? 'bottom-nav-item-highlight': 'bottom-nav-item-normal'">
            <!-- <Icon type="md-person" size="30" /> -->
            <img class="phone-nav-icon" v-if="selectItem==5" src="@/assets/phone-nav-personal-highlight.png"/>
            <img class="phone-nav-icon" v-if="selectItem!=5" src="@/assets/phone-nav-personal-normal.png"/>
            个人中心
          </div>
        </div>
        <!-- <div class="bottom-seize-seat" v-if="isMobile"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
//import TopNav from "@/components/Nav/TopNav.vue";
//import LeftNav from '@/components/LeftNav.vue';
import { getOrderListAPI } from '@/api/OrderAPI.js'
import { acountLoginAPI, sendVerifyCodeAPI, registerPhoneAPI, loginPhoneAPI } from '@/api/LoginAPI.js'
import emitter from '@/hooks/bus.js'

export default {
  components: {
    //TopNav,
    //LeftNav
  },
  data() {
    return {
      lightmode: 'day',
      selectItem: 0,
      title: '',
      isLogin: false,
      token: '',
      avatar: '',
      showLogin: false,
      showSMSLogin: false,
      payModal: false,
      paySuccess: 0,
      isMobile: false,
      themeOverrides: {
        common: {
          primaryColor: '#FF0000'
        },
        Button: {
          textColor: '#FF0000'
        },
        Select: {
          peers: {
            InternalSelection: {
              textColor: '#FF0000'
            }
          }
        }
      },
      accountLogin: {
        username: "",
        password: "",
      },
      smsLogin: {
        phone: "",
        code: "",
      },
      editTopicRuleInline: {
        old_password: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          { type: 'string', min: 3, message: '最少输入3位旧密码', trigger: 'blur' }
        ],
        new_password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { type: 'string', min: 3, message: '最少输入3位新密码', trigger: 'blur' }
        ],
        new_password2: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { type: 'string', min: 3, message: '最少输入3位确认新密码', trigger: 'blur' }
        ]
      },
      smsSendInterval: null,
      watingSmsCount: 0,
      sendSmsText: "发送验证码",
      showRuleModal: false,
      loginOrRegister: 1, //1-登录，2-注册
    }
  },
  methods: {
    goToDrawing() {
      console.log("goToDrawing...");
    },
    goToStore() {
      console.log("goToStore...");
    },
    goToProfile() {
      console.log("goToProfile...");
    },
    handleResize() {
      console.log("handleResize.....")
      let screenWidth = document.documentElement.clientWidth
      let screeHeight = document.documentElement.clientHeight
      console.log("screenWidth..." + screenWidth + ", screeHeight..." + screeHeight)
      if(screenWidth / screeHeight < 1) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      console.log("this.isMobile === " + this.isMobile)
    },
    gotoPage(index) {
      console.log("gotoPage..." + index)
      this.selectItem = index
      //localStorage.setItem('pageIndex', index)
      if(index == 0) {
        this.$router.push("/")
      } else if(index == 1) {
        this.$router.push("/imagehome")
      } else if(index == 2) {
        this.$router.push('/galleryhome')
      } else if(index == 3) {
        this.$router.push("/noticehome")
      } else if(index == 5) {
        this.$router.push("/personnalcenter")
      }
    },
    performAccountLogin() {
      // Logic for account login
      this.accountLoginReuest(this.accountLogin.username, this.accountLogin.password);
    },
    async getCurrentOrderRequest(orderID) {
          console.log("getCurrentOrderRequest......" + orderID)
          // if(this.currentOrderID == null) {
          //   return
          // }
          // this.orderIntervalCount = this.orderIntervalCount + 1
          // if(this.orderIntervalCount >= 100 || this.payModal == false) {
          //   this.stopOrderInterval()
          //   return
          // }
          const res = await getOrderListAPI(this.token, 1, 5);
            if (res.data.code == 0) {
                // this.orderList = res.data.records;
                // this.ipagination2.total = res.data.total;
                res.data.records.forEach(element => {
                  if(element.id == orderID) {
                    if(element.state == 1) {
                      this.paySuccess = 1
                      this.payModal = true
                    } else if(element.state == 2) {
                      this.paySuccess = 2
                      this.payModal = true
                    }
                  }
                });
            }
        },
    performSmsLogin() {
      // Logic for SMS login
      if(this.smsLogin.phone.length < 11) {
        this.$Message.error('请输入正确的手机号')
        return
      }
      if(this.smsLogin.code.length < 4) {
        this.$Message.error('请输入正确验证码')
        return
      }
      if(this.loginOrRegister == 1) {
        this.smsLoginRequest()
      } else {
        this.smsRegisterRequest()
      }
      
    },
    async sendSMSRequest() {
      const res = await sendVerifyCodeAPI(this.smsLogin.phone)
      console.log(res)
      this.watingSmsCount = 120
      if (res.data.code !== 0) {
        this.$Message.error('发送验证码失败：' + res.data.message)
        this.watingSmsCount = 0
        return
      } else {
        this.$Message.success('发送验证码成功')
        this.startSMSSendInterval()
      }
      
    },
    async smsRegisterRequest() {
      const res = await registerPhoneAPI(this.smsLogin.phone, this.smsLogin.code)
      console.log(res)
      if (res.data.code !== 0) {
        if(res.data.code == 3) {
          //this.smsLoginRequest()
          this.$Message.warning('手机号已注册，请直接登录')
          return
        }
        this.$Message.error('登录失败：' + res.data.message)
        return
      }
      this.showSMSLogin = false
      this.avatar = res.data.sys_user_data.avatar
      if(this.avatar == undefined || this.avatar == 'undefined' || this.avatar == null || this.avatar == 'null') {
        this.avatar = ''
      }
      this.token = res.data.token
      this.isLogin = true
      this.$Message.success('登录成功')
      localStorage.setItem('username', res.data.sys_user_data.username)
      localStorage.setItem('avatar', res.data.sys_user_data.avatar)
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('role_type', '1')
      emitter.emit('loginsuccess', '1')
    },
    async smsLoginRequest() {
      const res = await loginPhoneAPI(this.smsLogin.phone, this.smsLogin.code)
      if (res.data.code !== 0) {
        this.$Message.error('登录失败：' + res.data.message)
        return
      }
      this.showSMSLogin = false
      this.avatar = res.data.sys_user_data.avatar
      if(this.avatar == undefined || this.avatar == 'undefined' || this.avatar == null || this.avatar == 'null') {
        this.avatar = ''
      }
      this.token = res.data.token
      this.isLogin = true
      this.$Message.success('登录成功')
      localStorage.setItem('username', res.data.sys_user_data.username)
      localStorage.setItem('avatar', res.data.sys_user_data.avatar)
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('role_type', '1')
      emitter.emit('loginsuccess', '1')
      emitter.emit('loginsuccess-mobile', '1')
    },
    // async editPwdReuest() {
    //   let token = localStorage.getItem('token');
    //   const res = await editPwd(token, this.editPwdFormInline.old_password, this.editPwdFormInline.new_password)
    //   if (res.data.code === 0) {
    //     this.$Message.success('修改密码成功')
    //   } else {
    //     this.$Message.error('修改密码失败：' + res.data.message)
    //   }
    // },
    async accountLoginReuest(username, password) {
      const res = await acountLoginAPI(username, password)
      if (res.data.code !== 0) {
        this.$Message.error('登录失败：' + res.data.message)
        return
      }
      if (res.data.sys_user_data.role_type !== 1) {
        this.$Message.error('请使用用户登录')
        return
      }
      this.showLogin = false
      this.avatar = res.data.sys_user_data.avatar
      this.token = res.data.token
      this.isLogin = true
      this.$Message.success('登录成功')
      localStorage.setItem('username', username)
      localStorage.setItem('avatar', res.data.sys_user_data.avatar)
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('role_type', '1')
      emitter.emit('loginsuccess', '1')
    },
    startSMSSendInterval() {
      //this.watingSmsCount = 120
      this.smsSendInterval = setInterval(() => {
        this.watingSmsCount = this.watingSmsCount - 1
        this.sendSmsText = this.watingSmsCount + "秒"
        if(this.watingSmsCount <= 0) {
          this.stopSMSSendInterval()
        }
      }, 1000)
    },
    stopSMSSendInterval() {
      console.log("stopSMSSendInterval...")
      if(this.smsSendInterval) {
        clearInterval(this.smsSendInterval)
      }
      this.watingSmsCount = 0
      this.sendSmsText = "发送验证码"
    },
    sendSMS() {
      if(this.watingSmsCount > 0) {
        return
      }
      if(this.smsLogin.phone.length < 11) {
        this.$Message.error('请输入正确的手机号')
        return
      }
      this.sendSMSRequest()
    },
    changeTheme() {
      if(this.lightmode == null || this.lightmode == 'day') {
        this.lightmode = 'dark'
        localStorage.setItem('lightmode', 'dark')
        this.$Message.success('夜间模式')
      } else {
        this.lightmode = 'day'
        localStorage.setItem('lightmode', 'day')
        this.$Message.success('日间模式')
      }
      this.$store.lightmode = this.lightmode
      emitter.emit('chagelightmode', this.lightmode)
      emitter.emit('chagelightmodechild', this.lightmode)
      emitter.emit('chagelightmodechild2', this.lightmode)
    },
    ruleClicked() {
      this.showRuleModal = true
    },
    privacyClicked() {
      this.showRuleModal = true
    },
    changeLoginOrRegister() {
      if(this.loginOrRegister == 1) {
        this.loginOrRegister = 2
      } else {
        this.loginOrRegister = 1
      }
    }
  },
  beforeUnmount () {
    emitter.off('logout')
    emitter.off('changehead')
    emitter.off('requestLogin')
  },
  created () {
    let path = window.location.href
    let sItem = 0 //不做一次转换，this.selectItem无法赋值？
    if(path.indexOf('/imagehome') != -1) {
      sItem = 1
    } else if(path.indexOf('/galleryhome') != -1) {
      sItem = 2
    } else if(path.indexOf('/noticehome') != -1) {
      sItem = 3
    } else if(path.indexOf('/personnalcenter') != -1) {
      sItem = 4
    } else if(path.indexOf('/guide') != -1) {
      sItem = 100
    } else {
      sItem = 0
    }
    this.selectItem = sItem
    let lightmode = localStorage.getItem('lightmode')
    if(lightmode == null || lightmode == 'day') {
      this.lightmode = 'day'
    } else {
      this.lightmode = 'dark'
    }
    this.$store.lightmode = this.lightmode
    this.token = localStorage.getItem('token')
    if(this.token && this.token != "") {
      this.isLogin = true
    }
    this.avatar = localStorage.getItem('avatar')
    if(this.avatar == undefined || this.avatar == 'undefined' || this.avatar == null || this.avatar == 'null') {
      this.avatar = ''
    }
    const that = this
    // 用于ajax拦截token不合法跳转登录页面
    window.gotoLogin = function (errMsg) {
      that.$Message.error(errMsg)
      that.token = ''
      localStorage.setItem('token', '')
      localStorage.setItem('username', '')
      localStorage.setItem('role_type', '')
      that.isLogin = false
    }
    let role_type = localStorage.getItem('role_type')
    if (role_type && role_type !== '1') {
      localStorage.setItem('token', '')
      localStorage.setItem('username', '')
      localStorage.setItem('role_type', '')
    }
    emitter.on('logout', (msg) => {
      console.log("logout...")
      if (msg === '1') {
        this.isLogin = false
        this.token = ''
        this.avatar = ''
        this.selectItem = 0
        this.$router.push("/")
      }
    })
    emitter.on('changehead', (msg) => {
      console.log("changehead...")
      this.avatar = msg
    })
    emitter.on('requestLogin', (msg) => {
      console.log("requestLogin..." + msg)
      //this.showLogin = true
      this.showSMSLogin = true
    })
    this.stopSMSSendInterval()
  },
  mounted() {
    let currentOrder = localStorage.getItem('currentOrder')
    console.log("currentOrder..." + currentOrder)
    if(currentOrder && currentOrder.length > 0) {
      this.getCurrentOrderRequest(currentOrder)
      localStorage.setItem('currentOrder', '')
    }
  },
  computed: {
    sendSMSColor: function() {
      if(this.watingSmsCount <= 0) {
        return "#08C160"
      } else {
        return "#999999"
      }
    },
    pageHeight: function() {
      if(this.isMobile) {
        return '88vh';
      } else {
        return '100vh';
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';
html, body {
  //height: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}
.bg {
  //height: 100vh;
  padding-top: 0;
  display: flex;
  .leftnav {
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    .bottom {
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      padding-bottom: 20px;
      display: grid;
      .bottom-head-back {
        display: flex;
        justify-content: center;
        .head-back {
          max-width: 40px;
          border-radius: 5px;
          border-width: 1px;
          border-style: solid;
          border-color: #08C160;
          aspect-ratio: 1/1;
          cursor: pointer;
        }
      }
      .login-back {
        border-radius: 5px;
        border-width: 1px;
        border-style: solid;
        border-color: #08C160;
        background-color: black;
        color: white;
        text-align: center;
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
        margin-top: 10px;
        max-width: 50px;
      }
      .theme-icon {
        max-width: 25px;
        margin-bottom: 20px;
        cursor: pointer;
        text-align: center;
      }
    }
  }
  // .routerview {
  //   top: 0;
  //   height: 100%;
  //   width: 90%;
  //   display: inline-block;
  //   vertical-align: top;
  // }
  .routerview-back {
    flex: 1; //父组件display:flex，占满剩余宽度
    display: flex;
    flex-direction: column;
    .routerview {
      flex: 20; //父组件display:flex，占满剩余宽度
      overflow: hidden;
    }
    .bootom-nav {
      height: 80px;
      display: flex;
      flex-direction: row;
      //background-color: red;
      .bottom-nav-item-normal {
        flex: 1;
        align-items: center;
        justify-content: center;
        //border: 1px solid #E0E0E0;
        color: #181818;
        display: flex;
        flex-direction: column;
        //background-color: transparent;
        cursor: pointer;
      }
      .bottom-nav-item-highlight {
        flex: 1;
        align-items: center;
        justify-content: center;
        //border: 1px solid #E0E0E0;
        color: #08C160;
        display: flex;
        flex-direction: column;
        //background-color: #E0E0E0;
        cursor: pointer;
      }
    }
    .bottom-seize-seat {
      flex: 1;
      //background-color: red;
    }
  }
  
}

.router-item-back {
  text-align: center;
  margin-top: 20px;
  .nav-image {
    max-width: 45px;
  }
}
.router-item {
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 3px;
  cursor: pointer;
}
.item-text {
  font-weight: bold;
}
.router-item-normal {
  background-color: #08C160;
}
.router-item-highlight {
  background-color: #008a05;
}

.makesure-btn {
  background-color: #08C160;
}

.sms-back {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 20px;

  .sms-login-title {
    text-align: center;
    font-weight: bolder;
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 1.2rem;
  }
  .sms-login-content {
    font-weight: bold;
  }

  .sms-login-item {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    height: 50px;
    border-color: #08C160;
    border-radius: 25px;
    border-style: solid;
    border-width: 1px;

    .send-sms {
      min-width: 150px;
      text-align: center;
      font-size: 1rem;
      cursor: pointer;
      font-weight: bolder;
    }
  }

  .login-btn {
    margin-top: 20px;
    height: 50px;
    border-radius: 25px;
    font-size: 1.2rem;
  }

  .sms-login-footer {
    margin-top: 50px;
    align-items: center;
    text-align: center;
  }
}

.book {
  cursor: pointer;
  color: #08C160;
}
.rulebtn-back {
  margin-top: 20px;
  text-align: center;
}

.register-back {
  margin-top: 10px;
  display: flex;
  .register-btn {
    color: #08C160;
    cursor: pointer;
  }
}

.phone-nav-icon {
  max-width: 50px;
  max-height: 50px;
}
</style>

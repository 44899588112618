<template>
  <!-- <div class="flex-shrink-0 z-[1000] h-full"> -->
  <div>
    <div class="sidebar">
      <div class="session-list">
        <div class="topiclisttitleback" :class="lightmode=='day' ? 'day-topiclist-top-bg' : 'dark-topiclist-top-bg'">
          <Button type="primary" long class="new-chat-button" @click="newChatClick">{{ chat_type==0?'新对话':'新绘画' }}</Button>
        </div>
        <div class="session-item-back" :class="lightmode=='day' ? 'day-topiclist-bottom-bg' : 'dark-topiclist-bottom-bg'">
          <div
            v-for="(session, index) in sessions"
            :key="session.id">
            <div class="time-title" v-if="session.timeTitle && session.timeTitle.length > 0">{{ session.timeTitle }}</div>
            <div class="session-item" v-if="lightmode=='day'"
              @click="selectTopic(index)"
              :class="{ active: index === selectedTopicIndex }">
              <img v-if="index === selectedTopicIndex" class="dropdown-icon" src="@/assets/little-chat-highlight.png" />
              <img v-if="index !== selectedTopicIndex" class="dropdown-icon" src="@/assets/little-chat-normal.png" />
              <div class="topicname">
                {{ session.title }}
              </div>
              <Dropdown trigger="click" v-show="session.id != -1 && index === selectedTopicIndex" @on-click="onTopicMoreClick($event, session.id, session.title, session.top)" style="margin-left: 0px">
                <!-- <img v-if="index === selectedTopicIndex" class="dropdown-icon" src="@/assets/little-chat-highlight.png" />
                <img v-if="index !== selectedTopicIndex" class="dropdown-icon" src="@/assets/little-chat-normal.png" /> -->
                <Icon type="md-create" />
                <template #list>
                  <DropdownMenu>
                      <DropdownItem name="editname">修改对话名称</DropdownItem>
                      <DropdownItem name="edittop">{{ session.top === 1 ? '取消置顶' : '置顶' }}</DropdownItem>
                      <DropdownItem name="clear">清空对话消息</DropdownItem>
                      <DropdownItem name="delete" style="color: #ed4014">删除对话</DropdownItem>
                  </DropdownMenu>
                </template>
              </Dropdown>
            </div>
            <div class="dark-session-item" v-if="lightmode=='dark'"
              @click="selectTopic(index)"
              :class="{ active: index === selectedTopicIndex }">
              <img v-if="index === selectedTopicIndex" class="dropdown-icon" src="@/assets/dark-little-chat-highlight.png" />
              <img v-if="index !== selectedTopicIndex" class="dropdown-icon" src="@/assets/dark-little-chat-normal.png" />
              <div class="topicname">
                {{ session.title }}
              </div>
              <Dropdown trigger="click" v-show="session.id != -1 && index === selectedTopicIndex" @on-click="onTopicMoreClick($event, session.id, session.title, session.top)" style="margin-left: 0px">
                <Icon type="md-create" />
                <template #list>
                  <DropdownMenu>
                      <DropdownItem name="editname">修改对话名称</DropdownItem>
                      <DropdownItem name="edittop">{{ session.top === 1 ? '取消置顶' : '置顶' }}</DropdownItem>
                      <DropdownItem name="clear">清空对话消息</DropdownItem>
                      <DropdownItem name="delete" style="color: #ed4014">删除对话</DropdownItem>
                  </DropdownMenu>
                </template>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal v-model="modifyTopicNameModal" footer-hide width="400">
      <div>
        <h1 class="modal-title">修改对话名称</h1>
        <Form style="margin-top: 20px;" ref="editTopicFormInline" :model="editTopicFormInline" :rules="editTopicRuleInline">
          <Form-item prop="type_name">
              <Input type="text" v-model="editTopicFormInline.title" placeholder="请输入对话名称">
                <template #prepend>
                    <span>对话名称</span>
                  </template>
              </Input>
          </Form-item>
        </Form>
        <div class="modal-footer-btn-back">
          <Button class="modal-footer-btn" @click="handleEditTopicName('editTopicFormInline')" type="success">确定</Button>
          <Button class="modal-footer-btn" @click="handleCancel">取消</Button>
        </div>
      </div>
    </Modal>

    <Modal v-model="clearOrDeleteTopicModal" footer-hide width="400">
      <div>
        <h1 class="modal-title">{{ clearOrDelete===1?'清空对话消息':'删除对话' }}</h1>
        <h1 v-if="clearOrDelete===1" class="modal-content">确定要清空 {{ clearOrDeleteTopicInfo.topicTitle }} 中的消息吗？</h1>
        <h1 v-if="clearOrDelete===2" class="modal-content">确定要删除对话 {{ clearOrDeleteTopicInfo.topicTitle }} 吗？</h1>
        <div class="modal-footer-btn-back">
          <Button class="modal-footer-btn" @click="handleClearOrDeleteTopic" type="success">确定</Button>
          <Button class="modal-footer-btn" @click="handleCancel">取消</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import {clearMessage } from '@/api/MessageAPI.js'
import { getCurrentTimeStamp } from "@/utils/timeutil.js"
import { getTopicList, addTopic, editTopicTitle, editTopicTop, deleteTopic, queryTopicDetailByID } from '@/api/TopicAPI.js'
import emitter from '@/hooks/bus.js'
export default {
  // props: {
  //   isLogin: {
  //     type: Boolean,
  //     default: false,
  //     required: true
  //   }
  // },
  props: {
    chat_type: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      lightmode: 'day',
      token: '',
      // sessions: [
      //   { 
      //     id: -1, 
      //     title: '新对话',
      //     user_id: -1,
      //     top: -1,
      //     messages: []
      //   }
      // ],
      sessions: [],
      todayTopicList: [],
      sevenDayTopicList: [],
      earlyTopicList: [],
      selectedTopicIndex: 0,
      editTopicFormInline: {},
      modifyTopicNameModal: false,
      editTopicRuleInline: {
        title: [
          { required: true, message: '请输入对话名称', trigger: 'blur' }
        ]
      },
      clearOrDeleteTopicModal: false,
      clearOrDelete: -1, //1-清空，2-删除
      clearOrDeleteTopicInfo: {
        topicID: -1,
        topicTitle: ''
      }
    }
  },
  methods: {
    async queryTopicDetailRequest(topicID) {
      const res = await queryTopicDetailByID(this.token, topicID)
      console.log('queryTopicDetailRequest...')
      console.log(res)
    },
    async modifyTopicTitleRequest () {
      const res = await editTopicTitle(this.token, this.editTopicFormInline.edit_id, this.editTopicFormInline.title);
      if (res.data.code === 0) {
        console.log("发。。。")
        this.$Message.success('修改成功')
        console.log("发送修改名称。。。" + this.editTopicFormInline.title)
        emitter.emit('topiclistchange', {direct: 'topicNameModifyed', topicID: this.editTopicFormInline.edit_id, topicName: this.editTopicFormInline.title})
        this.topicListRequest(false)
        // if (this.selectedTopicIndex >= 0 && this.sessions.length > 0 && this.selectedTopicIndex < this.sessions.length) {
        //   //this.$parent.onTopicNameModifyed(this.editTopicFormInline.edit_id, this.editTopicFormInline.title)
        //   console.log("发送修改名称。。。" + this.editTopicFormInline.title)
          
        // }
        
      }
    },
    async modifyTopicTopRequest (topicID, topicTop) {
      let top = topicTop===1 ? 0 : 1
      const res = await editTopicTop(this.token, topicID, top);
      if (res.data.code === 0) {
        if (top === 1) {
          this.$Message.success('置顶成功')
        } else {
          this.$Message.success('取消置顶成功')
        }
        this.topicListRequest(true)
      }
    },
    async clearTopicMsgRequest (topicID) {
      console.log("clearTopicMsgRequest..." + topicID)
      const res = await clearMessage(this.token, topicID);
      if (res.data.code === 0) {
        this.$Message.success('清空对话成功')
        //this.topicListRequest(false)
        //this.$parent.onTopicClearMessage(topicID)
        emitter.emit('topiclistchange', {direct: 'clearMsg', topicID: topicID})
      }
    },
    async deleteTopicRequest (topicID) {
      const res = await deleteTopic(this.token, topicID);
      if (res.data.code === 0) {
        this.$Message.success('删除对话成功')
        this.topicListRequest(true)
      }
    },
    async topicListRequest (selectTop) {
      const res = await getTopicList(this.token, 1, 1000, this.chat_type)
      if (res.data.code === 0) {
        //this.sessions = res.data.records
        let tList = res.data.records
        let topicList = []
        let topIn = false
        let toadyIn = false
        let sevenDayIn = false
        let earlyIn = false
        let currentTimeStamp = getCurrentTimeStamp() / 1000
        tList.forEach(element => {
          if (element.chat_type === this.chat_type) {
            if (element.title == null || element.title == '') {
              element.title = '未命名'
            }
            element.messages = []
            if(topIn == false) {
              if(element.top == 1) {
                element.timeTitle = "置顶"
                topIn = true
              }
            }
            if(toadyIn == false && element.top != 1) {
              if((currentTimeStamp - element.create_time) < (24 * 60 * 60)) {
                element.timeTitle = "1日内"
                toadyIn = true
              }
            }
            if(sevenDayIn == false && element.top != 1) {
              if((currentTimeStamp - element.create_time >= 24 * 60 * 60) && 
              (currentTimeStamp - element.create_time <= 24 * 7 * 60 * 60)) {
                element.timeTitle = "7日内"
                sevenDayIn = true
              }
            }
            if(earlyIn == false && element.top != 1) {
              if(currentTimeStamp - element.create_time > 24 * 7 * 60 * 60) {
                element.timeTitle = "更早"
                earlyIn = true
              }
            }


            topicList.push(element)
          }
        });
        if (topicList.length == 0) {
          this.cleanSessions()
        } else { //有对话列表
          this.sessions = topicList
          if (selectTop === true) {
            this.selectedTopicIndex = 0;
            this.selectTopic(0)
          }
        }
      }
    },
    cleanSessions() {
      this.sessions = []
      this.todayTopicList = []
      this.sevenDayTopicList = []
      this.earlyTopicList = []
      this.selectedTopicIndex = -1;
      //this.$parent.onTopicsClean()
      emitter.emit('topiclistchange', {direct: 'clearTopics', topicID: -1})
    },
    selectTopic(index) {
      this.selectedTopicIndex = index;
      //this.$parent.onTopicSelected(this.sessions[index])
      emitter.emit('topiclistchange', {direct: 'selectTopic', topic: this.sessions[index]})
    },
    async newTopicRequest() {
      const res = await addTopic(this.token, this.chat_type);
      if (res.data.code !== 0) {
        this.$Message.error('添加对话失败：' + res.data.message)
        return
      }
      let topic = {
        id: res.data.data.id,
        title: res.data.data.title,
        top: res.data.data.top,
        user_id: res.data.data.user_id,
        create_time: res.data.data.create_time,
        messages: []
      }
      this.sessions.unshift(topic)
      this.selectedTopicIndex = 0;
      this.selectTopic(0)
    },
    onTopicMoreClick(event, topicID, topicTitle, topicTop) {
      if(event == 'editname') {
        this.editTopicFormInline = {
          edit_id: topicID,
          title: topicTitle
        }
        this.modifyTopicNameModal = true
      } else if(event == 'edittop') {
        this.modifyTopicTopRequest(topicID, topicTop);
      } else if(event == 'clear') {
        this.clearOrDeleteTopicInfo = {
          topicID: topicID,
          topicTitle: topicTitle
        }
        this.clearOrDelete = 1
        this.clearOrDeleteTopicModal = true
      } else if(event == 'delete') {
        this.clearOrDeleteTopicInfo = {
          topicID: topicID,
          topicTitle: topicTitle
        }
        this.clearOrDelete = 2
        this.clearOrDeleteTopicModal = true
      }
    },
    handleCancel() {
      this.modifyTopicNameModal = false
      this.clearOrDeleteTopicModal = false
    },
    handleClearOrDeleteTopic() {
      this.clearOrDeleteTopicModal = false
      if (this.clearOrDelete === 1) {
        this.clearTopicMsgRequest(this.clearOrDeleteTopicInfo.topicID)
      } else if (this.clearOrDelete === 2) {
        this.deleteTopicRequest(this.clearOrDeleteTopicInfo.topicID)
      }
    },
    newChatClick() {
      //this.$parent.newTopicCreated("111") //子组件调用父组件的方法
      if (this.token == null || this.token === '') {
        this.$Message.warning('请先登录')
        return
      }
      if(this.sessions && this.sessions.length >= 30) {
        this.$Message.warning('最多创建30个对话')
        return
      }
      this.newTopicRequest()
    },
    handleEditTopicName(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.modifyTopicTitleRequest()
          this.modifyTopicNameModal = false
        } else {
          this.$Message.error('请按提示正确填入信息')
        }
      })
    }
  },
  created() {
    this.lightmode = this.$store.lightmode
    console.log("topiclist this.lightmode = " + this.lightmode)
    this.token = localStorage.getItem('token');
    if(this.token && this.token != "") {
      this.topicListRequest(true)
    }
  },
  beforeUnmount () {
    emitter.off('loginsuccess');
    emitter.off('requestCreateNewChat');
    emitter.off('chagelightmodechild')
  },
  mounted() {
    emitter.on('loginsuccess', (msg) => {
      console.log('TopicList loginsuccess..' + msg)
      if (msg === '1') {
        this.token = localStorage.getItem('token');
        this.topicListRequest(true)
      } else {
        this.token = ''
        this.cleanSessions()
      }
    })
    emitter.on('requestCreateNewChat', (msg) => {
      console.log('requestCreateNewChat..' + msg)
      this.newChatClick()
    })
    emitter.on('chagelightmodechild', (mode) => {
      this.lightmode = mode
    })
  }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';
.sidebar {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.session-list {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.session-item-back {
  width: 100%;
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
  overflow-y: auto;
}

.session-item-back::-webkit-scrollbar {
    display: none;
}

.time-title {
  margin-top: 10px;
  color: #ADADAD;
  font-size: 0.8rem;
}

.session-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  border-color: #E0E0E0;
  border-width: 1px;
  border-style: solid;
  margin-top: 10px;
  color: #191919;
  font-weight: normal;
  cursor: pointer;
}

.session-item.active {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-radius: 8px;
  border-color: #08C160;
  border-width: 1px;
  border-style: solid;
  margin-top: 10px;
  color: #08C160;
  font-weight: bold;
  cursor: pointer;
}

.dark-session-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  border-color: #E0E0E0;
  border-width: 1px;
  border-style: solid;
  margin-top: 10px;
  color: white;
  font-weight: normal;
  cursor: pointer;
}

.dark-session-item.active {
  display: flex;
  background-color: #373737;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-radius: 8px;
  border-color: #08C160;
  border-width: 1px;
  border-style: solid;
  margin-top: 10px;
  color: #08C160;
  font-weight: bold;
  cursor: pointer;
}

// .topic-back {

// }

.dropdown-icon {
  width: 20px;
  height: 20px;
}



.topiclisttitleback {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 8px;
  padding-right: 8px;

  .new-chat-button {
    //color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    border-radius: 8px;
    background-color: #08C160;
  }
  .topiclisttitle {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    //color: white;
    font-size: 1rem;
    font-weight: bolder;
  }
}

.horsplitline {
  width: 100%;
  height: 1px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  opacity: 0.2;
  background-color: rgba(0, 0, 0, 0.3);
}

.horsplitline2 {
  width: 100%;
  height: 1px;
  margin-top: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  opacity: 0.2;
  background-color: rgba(0, 0, 0, 0.3);
}

.topicname {
  display: inline-block;
  display: 1;
  margin-left: 10px;
  margin-right: 10px;
  word-break: break-all;
}


</style>
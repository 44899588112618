import { copyObj } from '@/utils/deepcopy.js'
import { getTimeStr } from "@/utils/timeutil.js"

export const getMJListResult = (records) => {
  let messages = []
  records.forEach(element => {
    //chat_reply
    //chat_type
    //chatgpt_uuid
    //completion_tokens
    //create_time
    //id
    //mj_action
    //mj_botId
    //mj_description
    //mj_fail_reason
    //mj_flags
    //mj_hash
    //mj_id
    //mj_image_url
    //mj_label
    //mj_options  [{\"type\":2,\"style\":2,\"label\":\"U1\",\"custom\":\"MJ::JOB::upsample::1::17dabb3b-30d1-4953-90ef-3ad17b6a6650\"},{\"type\":2,\"style\":2,\"label\":\"U2\",\"custom\":\"MJ::JOB::upsample::2::17dabb3b-30d1-4953-90ef-3ad17b6a6650\"},{\"type\":2,\"style\":2,\"label\":\"U3\",\"custom\":\"MJ::JOB::upsample::3::17dabb3b-30d1-4953-90ef-3ad17b6a6650\"},{\"type\":2,\"style\":2,\"label\":\"U4\",\"custom\":\"MJ::JOB::upsample::4::17dabb3b-30d1-4953-90ef-3ad17b6a6650\"},{\"type\":2,\"style\":2,\"label\":\"🔄\",\"custom\":\"MJ::JOB::reroll::0::17dabb3b-30d1-4953-90ef-3ad17b6a6650::SOLO\"},{\"type\":2,\"style\":2,\"label\":\"V1\",\"custom\":\"MJ::JOB::variation::1::17dabb3b-30d1-4953-90ef-3ad17b6a6650\"},{\"type\":2,\"style\":2,\"label\":\"V2\",\"custom\":\"MJ::JOB::variation::2::17dabb3b-30d1-4953-90ef-3ad17b6a6650\"},{\"type\":2,\"style\":2,\"label\":\"V3\",\"custom\":\"MJ::JOB::variation::3::17dabb3b-30d1-4953-90ef-3ad17b6a6650\"},{\"type\":2,\"style\":2,\"label\":\"V4\",\"custom\":\"MJ::JOB::variation::4::17dabb3b-30d1-4953-90ef-3ad17b6a6650\"}]
    //mj_prompt
    //mj_status
    //mj_task_uuid
    //prompt_tokens
    //topic_id
    //total_tokens
    //user_ask
    //user_id
    if (element.mj_task_uuid) {
      //console.log("mj_task_uuid..." + element.mj_task_uuid)
      let msg = copyObj(element)
      msg.isUser = false
      msg.timeStr = getTimeStr(msg.create_time)
      if(msg.mj_status == 4) {
        msg.failMsg = '失败（绘画次数返还）'
      } else if(msg.mj_status == 6) {
        msg.failMsg = '超时（绘画次数返还）'
      }

      //mj回复
      if(element.mj_action == "DESCRIBE") { //图生文
        let desJson = JSON.parse(msg.mj_description)
        //console.log("desJson...")
        //console.log(desJson)
        if(desJson) {
          let desStr = ""
          for(let i=0;i<desJson.length;i++) {
            if (i == desJson.length - 1) {
              desStr = desStr + desJson[i]
            } else {
              desStr = desStr + desJson[i] + "\n"
            }
          }
          //console.log("desStr..." + desStr)
          msg.desStr = desStr
        }
      } else { //非图生文
        if(msg.mj_options) {
          let optionsData = getMJOptions(msg.mj_options)
          msg.canAsReferenceImage = getCanAsReferenceImage(msg)
          msg.uOptions = optionsData.uOptions
          msg.sOptions = optionsData.sOptions
          msg.moveOptions = optionsData.moveOptions
          msg.vOptions = optionsData.vOptions
          msg.otherOptions = optionsData.otherOptions
        }
      }
      messages.unshift(msg)
    }

    //下面是用户发送的
    if(element.mj_action == "DESCRIBE") { // 图生文
      let msg2 = copyObj(element)
      msg2.isUser = true
      msg2.timeStr = getTimeStr(msg2.create_time)
      if(msg2.mj_input_img && msg2.mj_input_img.length > 0) {
        // let urls = msg2.mj_input_img.split(',')
        // if(urls.length >= 2) {
        //   msg2.sourceUrl1 = urls[0]
        //   msg2.sourceUrl2 = urls[1]
        // }
        msg2.sourceUrl1 = msg2.mj_input_img
      }
      messages.unshift(msg2)
    } else if(element.mj_action == "LIKE") { // 图生图
      let msg2 = copyObj(element)
      msg2.isUser = true
      msg2.timeStr = getTimeStr(msg2.create_time)
      msg2.mj_labelStr = msg2.mj_prompt
      if(msg2.mj_input_img && msg2.mj_input_img.length > 0) {
        msg2.sourceUrl1 = msg2.mj_input_img
      }
      messages.unshift(msg2)
    } else if(element.mj_action == "BLEND") { //混图
      let msg2 = copyObj(element)
      msg2.isUser = true
      msg2.timeStr = getTimeStr(msg2.create_time)
      msg2.mj_labelStr = getMJOptionStr(msg2.mj_prompt)
      if(msg2.mj_input_img && msg2.mj_input_img.length > 0) {
        let urls = msg2.mj_input_img.split(',')
        if(urls.length >= 2) {
          msg2.sourceUrl1 = urls[0]
          msg2.sourceUrl2 = urls[1]
        }
      }
      messages.unshift(msg2)
    } else {
      if (element.mj_prompt || element.mj_label) {
        let msg2 = copyObj(element)
        msg2.isUser = true
        msg2.timeStr = getTimeStr(msg2.create_time)
        msg2.mj_labelStr = getMJOptionStr(msg2.mj_label)
        messages.unshift(msg2)
      }
    }
    
  })
  return messages
}


// export const getMJLoopResult = (currentMessages, task) => {
//   if(task.data.mj_status == 3) {
//     let mjFetchResult = null
//     for(let i=0;i<currentMessages.length;i++) {
//       let element = currentMessages[i]
//       if(element.isUser == false && element.id == task.data.id) {
//         console.log("element.id =" + element.id + ", res.data.task.data.id = " + task.data.id)
//         //element = res.data.task.data
//         mjFetchResult = task.data
//         mjFetchResult.isUser = false
//         mjFetchResult.progress = task.progress
//         currentMessages.splice(i, 1)
//       }
//     }
//     currentMessages.push(mjFetchResult)
//   } else if(task.data.mj_status == 5) {
//     let mjFetchResult = null
//     for(let i=0;i<currentMessages.length;i++) {
//       let element = currentMessages[i]
//       if(element.isUser == false && element.id == task.data.id) {
//         console.log("element.id =" + element.id + ", res.data.task.data.id = " + task.data.id)
//         //element = res.data.task.data
//         mjFetchResult = task.data
//         mjFetchResult.isUser = false
//         mjFetchResult.progress = task.progress
//         if(mjFetchResult.mj_options) {
//           let optionsJson = JSON.parse(mjFetchResult.mj_options)
//           let uOptions = []
//           let sOptions = []
//           let vOptions = []
//           for(let j=0;j<optionsJson.length;j++) {
//             let option = optionsJson[j]
//             //const phoneNumber = "13812345678";
//             const regex = /U[1-9]/
//             if(regex.test(option.label) || option.label.indexOf("Upscale") == 0) {
//               option.title = option.label
//               uOptions.push(option)
//             } else if (option.label.indexOf("Zoom Out") == 0) {
//               option.title = option.label
//               sOptions.push(option)
//             } else {
//               option.title = option.label
//               vOptions.push(option)
//             }
//           }
//           mjFetchResult.uOptions = uOptions
//           mjFetchResult.sOptions = sOptions
//           mjFetchResult.vOptions = vOptions
//         }
//         currentMessages.splice(i, 1)
//       }
//     }
//     currentMessages.push(mjFetchResult)
//     return currentMessages
//   }
// }


// export const getMJOptions = (mj_options) => {
//   if(mj_options) {
//     let optionsJson = JSON.parse(mj_options)
//     console.log("optionsJson....")
//     console.log(optionsJson)
//     let uOptions = []
//     let sOptions = []
//     let vOptions = []
//     for(let j=0;j<optionsJson.length;j++) {
//       let option = optionsJson[j]
//       const regex = /U[1-9]/
//       if(regex.test(option.label) || option.label.indexOf("Upscale") == 0) {
//         option.title = option.label
//         uOptions.push(option)
//       } else if (option.label.indexOf("Zoom Out") == 0) {
//         option.title = option.label
//         sOptions.push(option)
//       } else {
//         option.title = option.label
//         vOptions.push(option)
//       }
//     }
//     return {
//       uOptions: uOptions,
//       sOptions: sOptions,
//       vOptions: vOptions
//     }
//   } else {
//     return {
//       uOptions: [],
//       sOptions: [],
//       vOptions: []
//     }
//   }
// }

export const getMJOptions = (mj_options) => {
  if(mj_options) {
    let optionsJson = JSON.parse(mj_options)
    //console.log("optionsJson....")
    //console.log(optionsJson)
    let uOptions = []
    let sOptions = []
    let moveOptions = []
    let vOptions = []
    let otherOptions = []
    for(let j=0;j<optionsJson.length;j++) {
      let option = optionsJson[j]
      //const phoneNumber = "13812345678";
      const regeU = /U[1-9]/
      const regexV = /V[1-9]/
      if(regeU.test(option.label)) {
        option.title = option.label.replace("U", "图")
        option.content =  "放大" + option.label.replace("U", "图")
        uOptions.push(option)
      } else if(option.label.indexOf("Upscale") == 0) {
        // if(option.label.indexOf("2x") != -1) {
        //   option.title = '放大至2K'
        //   option.content =  "放大本图至2K"
        // } else if(option.label.indexOf("4x") != -1) {
        //   option.title = '放大至4K'
        //   option.content =  "放大本图至4K"
        // } else 

        // if(option.label.indexOf("Subtle") != -1) {
        //   option.title = '高清（微妙变换）'
        //   option.content =  "高清展示本图（微妙变换）"
        //   uOptions.push(option)
        // } else if(option.label.indexOf("Creative") != -1) {
        //   option.title = '高清（强烈变换）'
        //   option.content =  "高清展示本图（强烈变换）"
        //   uOptions.push(option)
        // }
      } else if (option.label.indexOf("Zoom Out") == 0) {
        if(option.label.indexOf("2x") != -1) {
          option.title = '2倍'
          option.content =  "图片缩焦两倍"
        } else if(option.label.indexOf("1.5x") != -1) {
          option.title = '1.5倍'
          option.content =  "图片缩焦1.5倍"
        }
        sOptions.push(option)
      } else if (option.label == '⬅️' || 
        option.label == '➡️' ||
        option.label == '⬆️' ||
        option.label == '⬇️') {
          if(option.label == '⬅️') {
            option.title = "左移"
            option.content = "图片向左扩展"
          } else if(option.label == '➡️') {
            option.title = "右移"
            option.content = "图片向右扩展"
          } else if(option.label == '⬆️') {
            option.title = "上移"
            option.content = "图片向上扩展"
          } else if(option.label == '⬇️') {
            option.title = "下移"
            option.content = "图片向下扩展"
          }
          moveOptions.push(option)
      } else if(regexV.test(option.label)) {
        option.title = option.label.replace("V", "图")
        option.content =  "以" + option.label.replace("V", "图") + "为基准重新生成图片"
        vOptions.push(option)
      } else if(option.label.indexOf('Vary') != -1) {
        if(option.label.indexOf('Subtle') != -1) {
          option.title = "微妙变换"
          option.content = "微妙变换本图"
          vOptions.push(option)
        } else if(option.label.indexOf('Strong') != -1) {
          option.title = "强烈变换"
          option.content = "强烈变换本图"
          vOptions.push(option)
        }
        // else if(option.label.indexOf('Region') != -1) {
        //   option.title = "局部变换"
        //   option.content = "局部变换本图"
        // }
        
      } else {
        if(option.label != '❤️' && option.label != 'Custom Zoom') {
          if (option.label == "🔄") {
            option.title = "重做"
            option.content = "重新做图"
            otherOptions.push(option)
          } else if(option.label.indexOf('Redo') != -1) {
            // if(option.label.indexOf('Upscale (Subtle)') != -1) {
            //   option.title = "重做高清（微妙变换）"
            //   option.content = "重做高清展示本图（微妙变换）"
            //   otherOptions.push(option)
            // } else if(option.label.indexOf('Upscale (Creative)') != -1) {
            //   option.title = "重做高清（强烈变换）"
            //   option.content = "重做高清展示本图（强烈变换）"
            //   otherOptions.push(option)
            // }
          }
          // else {
          //   option.title = option.label
          //   option.content = option.label
          //   otherOptions.push(option)
          // }
        }
        // if (option.label == "🔄") {
        //   option.title = "重做"
        //   option.content = "重新做图"
        //   otherOptions.push(option)
        // } else if(option.label == "Vary (Subtle)") {
        //   option.title = option.label
        //   option.content = option.label
        //   otherOptions.push(option)
        // } else if(option.label == "Vary (Strong)") {
        //   option.title = option.label
        //   option.content = option.label
        //   otherOptions.push(option)
        // } else if(option.label == "Vary (Region)") {
        //   option.title = option.label
        //   option.content = option.label
        //   otherOptions.push(option)
        // } else if(option.label == "Custom Zoom") {
        //   option.title = option.label
        //   option.content = option.label
        //   otherOptions.push(option)
        // } else if(option.label == "Redo Upscale (2x)") {
        //   option.title = option.label
        //   option.content = option.label
        //   otherOptions.push(option)
        // } else if(option.label == "Redo Upscale (4x)") {
        //   option.title = option.label
        //   option.content = option.label
        //   otherOptions.push(option)
        // }
      }
    }
    return {
      uOptions: uOptions,
      sOptions: sOptions,
      moveOptions: moveOptions,
      vOptions: vOptions,
      otherOptions: otherOptions
    }
  } else {
    return {
      uOptions: [],
      sOptions: [],
      moveOptions: [],
      vOptions: [],
      otherOptions: []
    }
  }
}

export const getMJOptionStr = (optionLabel) => {
  const regeU = /U[1-9]/
  const regexV = /V[1-9]/
  if(regeU.test(optionLabel)) {
    return "放大" + optionLabel.replace("U", "图")
  } else if(optionLabel.indexOf("Upscale") == 0) {
    if(optionLabel.indexOf("2x") != -1) {
      return '放大至2K'
    } else if(optionLabel.indexOf("4x") != -1) {
      return '放大至4K'
    } else if(optionLabel.indexOf("Subtle") != -1) {
      return '高清（微妙变换）'
    } else if(optionLabel.indexOf("Creative") != -1) {
      return '高清（强烈变换）'
    }
  } else if (optionLabel.indexOf("Zoom Out") == 0) {
    if(optionLabel.indexOf("2x") != -1) {
      return '缩焦2倍'
    } else if(optionLabel.indexOf("1.5x") != -1) {
      return '缩焦1.5倍'
    }
  } else if (optionLabel == '⬅️' || 
    optionLabel == '➡️' ||
    optionLabel == '⬆️' ||
    optionLabel == '⬇️') {
      if(optionLabel == '⬅️') {
        return "左移"
      } else if(optionLabel == '➡️') {
        return "右移"
      } else if(optionLabel == '⬆️') {
        return "上移"
      } else if(optionLabel == '⬇️') {
        return "下移"
      }
  } else if(regexV.test(optionLabel)) {
    return "变换" + optionLabel.replace("V", "图")
  } else if(optionLabel.indexOf('Vary') != -1) {
    if(optionLabel.indexOf('Subtle') != -1) {
      return "微妙变换"
    } else if(optionLabel.indexOf('Strong') != -1) {
      return "强烈变换"
    } else if(optionLabel.indexOf('Region') != -1) {
      return "局部变换"
    }
  } else {
    if(optionLabel != '❤️' && optionLabel != 'Custom Zoom') {
      if (optionLabel == "🔄") {
        return "重做"
      } else if(optionLabel.indexOf('Redo') != -1) {
        if(optionLabel.indexOf('Upscale (Subtle)') != -1) {
          return "重做高清（微妙变换）"
        } else if(optionLabel.indexOf('Upscale (Creative)') != -1) {
          return "重做高清（强烈变换）"
        }
      } else {
        if(optionLabel == undefined || optionLabel =='undefined' || optionLabel == null || optionLabel == 'null') {
          return ""
        } else {
          return optionLabel
        }
        
      }
    }
  }
}

export const getCanAsReferenceImage = (message) => {
  if(message.mj_action == "DESCRIBE") {
    return false
  }
  if(message.mj_status == 5) {
    let optionsJson = JSON.parse(message.mj_options)
    let uIn = false
    for(let j=0;j<optionsJson.length;j++) {
      let option = optionsJson[j]
      //const phoneNumber = "13812345678";
      const regeU = /U[1-9]/
      if(regeU.test(option.label)) {
        uIn = true
        break
      }
    }
    if(!uIn) {
      return true
    }
  }
  return false
}
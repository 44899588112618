import request from '@/utils/request'

export const getTopicList = function (token, page_no, page_size, chat_type) {
  return request.post('/sysTopic/list', {
    token,
    page_no,
    page_size,
    chat_type,
    order_by: 'create_time DESC'
  })
}

export const deleteTopic = function (token, id) {
  return request.post('/sysTopic/delete', {
    token,
    id
  })
}

export const queryTopicDetailByID = function (token, id) {
  return request.post('/sysTopic/queryById', {
    token,
    id
  })
}

// export const addTopic = function (token, topic_uuid, user_id, title, system_message) {
//   return request.post('/sysTopic/add', {
//     token,
//     sys_topic: {
//       topic_uuid,
//       user_id,
//       title,
//       system_message
//     }
//   })
// }
export const addTopic = function (token, chat_type) {
  return request.post('/sysTopic/add', {
    token,
    chat_type
  })
}

export const editTopicTitle = function (token, id, title) {
  return request.post('/sysTopic/edit', {
    token,
    sys_topic: {
      id,
      title
    }
  })
}

export const editTopicTop = function (token, id, top) {
  return request.post('/sysTopic/edit', {
    token,
    sys_topic: {
      id,
      top
    }
  })
}
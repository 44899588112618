import request from '@/utils/request'

export const acountLoginAPI = function (username, password) {
  return request.post('/auth/login', {
    username: username,
    password: password
  })
}

export const logoutAPI = function (token) {
  return request.post('/auth/logout', {
    token
  })
}


export const registerPhoneAPI = function (phone, code) {
  return request.post('/auth/registerPhone', {
    phone,
    code
  })
}

export const loginPhoneAPI = function (phone, code) {
  return request.post('/auth/loginPhone', {
    phone,
    code
  })
}

export const sendVerifyCodeAPI = function (phone) {
  return request.post('/auth/sendVerifyCode', {
    phone
  })
}

export const modifyBindPhoneAPI = function (phone, code) {
  return request.post('/auth/bindPhone', {
    phone,
    code
  })
}

export const changeBindPhoneAPI = function (token, phone, code) {
  return request.post('/auth/bindPhone', {
    token,
    phone,
    code
  })
}
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="chongzhi-bigback" >
    <!--兑换码-->
    <div class="voucher-back">
      <span>兑换码</span>
      <div :class="lightmode == 'day' ? 'day-input-back' : 'dark-input-back'">
        <Input :border="false" style="width: 250px" v-model="voucherCode" placeholder="请输入兑换码"/>
      </div>
      <Button class="btn" type="success" @click="useCVoucherClicked">兑换</Button>
    </div>
    <!--充值-->
    <div class="chongzhi-back">
      <!-- <h1 class="title">套餐</h1> -->
      <div class="package-item" v-for="(item) in packageList" :key="item.id" :class="lightmode=='day'?'day-package-item-bg':'dark-package-item-bg'">
        <div class="package-title">{{ item.title }}</div>
        <div class="package-desc">{{ item.desc }}</div>
        <div v-if="item.ai_token > 0">对话：<span class="ai-count">{{ item.ai_token }}</span> Token <span class="exp-date">(有效期：{{ item.ai_validity_period }}个月)</span></div>
        <div v-if="item.mj_token > 0">绘画：<span class="mj-count">{{ item.mj_token }}</span> 次 <span class="exp-date">(有效期：{{ item.mj_validity_period }}个月)</span></div>
        <div>原价：<span class="show-price">{{ item.show_price }}</span> 元</div>
        <div>现价：<span class="real-price">{{ item.real_price }}</span> 元</div>
        <Button type="success" class="buy" @click="buy(item)">立即购买</Button>
      </div>
    </div>
    <div class="chongzhi-back">
      <h1 class="sub-title">充值记录</h1>
      <!-- <Table stripe :columns="columns2" :data="orderList"></Table> -->
      <div class="table-title-back">
        <div class="table-title-little-back">订单编号</div>
        <div class="table-title-little-back">充值金额</div>
        <div class="table-title-little-back">支付方式</div>
        <div class="table-title-little-back">支付状态</div>
        <div class="table-title-little-back">创建时间</div>
      </div>
      <div class="table-content-back" v-for="(item, index) in orderList" :key="item.id" :class="index % 2 == 0 ? 'table-content-bg1': 'table-content-bg2'">
        <div class="table-content-little-back item-max-width">{{ item.order_no }}</div>
        <div class="table-content-little-back item-max-width">{{ item.rmb_price }}元</div>
        <div class="table-content-little-back item-max-width">{{ item.methodStr }}</div>
        <div class="table-content-little-back item-max-width">{{ item.stateStr }}</div>
        <div class="table-content-little-back item-max-width">{{ item.createTimeStr }}</div>
      </div>
      <div class="pageback">
        <Page :total="ipagination2.total" :page-size="ipagination2.pagesize" class="page" @on-change="changePage1" show-total></Page>
      </div>
    </div>

    <Modal v-model="selectPayMethodModal" footer-hide width="400"
    :styles="{'background-color':'#ff0000'}">
      <div style="margin-top: 0px;">
        <div class="package-item" style="margin-left: 5px;margin-right:5px;border: none;padding: 0px;">
          <div class="package-title">{{ selectPackage.title }}</div>
          <div class="package-desc">{{ selectPackage.desc }}</div>
          <div v-if="selectPackage.ai_token > 0">对话：<span class="ai-count">{{ selectPackage.ai_token }}</span> Token <span class="exp-date">(有效期：{{ selectPackage.ai_validity_period }}个月)</span></div>
          <div v-if="selectPackage.mj_token > 0">绘画：<span class="mj-count">{{ selectPackage.mj_token }}</span> 次 <span class="exp-date">(有效期：{{ selectPackage.mj_validity_period }}个月)</span></div>
          <div>原价：<span class="show-price">{{ selectPackage.show_price }}</span> 元</div>
          <div>现价：<span class="real-price">{{ selectPackage.real_price }}</span> 元</div>
          <div class="pay-method-back">
            付款：
            <!-- <div class="pay-method-item" :class="{ active: payMethod=='wechat' }" @click="onSelectPayMethod('wechat')"><img src="@/assets/pay_wechat.png"/>微信</div> -->
            <div class="pay-method-item" :class="{ active: payMethod=='aliyun' }" @click="onSelectPayMethod('aliyun')"><img src="@/assets/pay_aliyun.png"/>支付宝</div>
          </div>
        </div>
        <div class="modal-footer-btn-back">
          <Button long type="success" @click="makeSureBuy">确认购买</Button>
        </div>
      </div>
    </Modal>

    <Modal
        v-model="payModal"
        title="支付结果" footer-hide width="400">
        <div class="pay-result-back" v-if="paySuccess == 0">
          <strong>等待支付中</strong>
          <p style="margin-top: 20px;">iPhone Safari浏览器无法唤起支付宝登录页面解决方法：</p>
          <p style="margin-top: 10px;">1.打开系统设置；</p>
          <p>2.找到Safari浏览器；</p>
          <p>3.关闭“阻止弹出式窗口”选项；</p>
          <p>4.重启浏览器下单支付；</p>
        </div>
        <div class="pay-result-back" v-if="paySuccess == 1">
          <Icon size="100" color="#08C160" type="ios-checkmark-circle" />
          <strong>支付成功</strong>
        </div>
        <div class="pay-result-back" v-if="paySuccess == 2">
          <Icon size="100" color="#ff0000" type="ios-close-circle" />
          <strong>支付失败</strong>
        </div>
    </Modal>
  </div>
</template>
<script>
import { getOrderListAPI, createAliyunOrderAPI } from '@/api/OrderAPI.js'
import { getPackageListAPI } from '@/api/PackageAPI.js'
import { useVoucherAPI } from '@/api/VoucherAPI.js'
import { getTimeStr } from "@/utils/timeutil.js"
import emitter from '@/hooks/bus.js'
export default {
    data() {
        return {
            lightmode: 'day',
            token: '',
            userInfo: {},
            orderList: [],
            selectPackage: {},
            selectPayMethodModal: false,
            voucherCode: '',
            payMethod: 'aliyun',
            payModal: false,
            paySuccess: 0, //0-未支付，1-支付成功，2-支付失败
            packageList: [],
            ipagination2: {
                current: 1,
                total: 0,
                pagesize: 10
            },
            currentOrderID: null,
            orderIntervalCount: 0,
            orderInterval: null,
            columns2: [
                {
                    title: '订单编号',
                    key: 'order_no',
                    align: 'center'
                },
                // {
                //     title: '套餐',
                //     key: 'package_id',
                //     align: 'center'
                // },
                {
                    title: '充值金额',
                    key: 'rmb_price',
                    align: 'center',
                    render: (h, params) => {
                        let priceStr = params.row.rmb_price + "元";
                        return h('span', {
                            style: {
                                color: '#000000'
                            }
                        }, priceStr);
                    }
                },
                {
                    title: '支付方式',
                    align: 'center',
                    render: (h, params) => {
                        let methodStr = "";
                        if (params.row.order_method == 1) {
                            methodStr = "微信";
                        }
                        else if (params.row.order_method == 2) {
                            methodStr = "支付宝";
                        }
                        return h('span', {
                            style: {
                                color: '#000000'
                            }
                        }, methodStr);
                    }
                },
                {
                    title: '支付状态',
                    align: 'center',
                    render: (h, params) => {
                        let stateStr = "";
                        if (params.row.state == 0) {
                            stateStr = "未支付";
                        }
                        else if (params.row.state == 1) {
                            stateStr = "支付成功";
                        }
                        else if (params.row.state == 2) {
                            stateStr = "支付失败";
                        }
                        return h('span', {
                            style: {
                                color: '#000000'
                            }
                        }, stateStr);
                    }
                },
                {
                    title: '创建时间',
                    align: 'center',
                    render: (h, params) => {
                        const createTime = params.row.create_time === null ? '无' : getTimeStr(params.row.create_time);
                        return h('span', {
                            style: {
                                color: '#000000'
                            }
                        }, createTime);
                    }
                }
            ]
        };
    },
    methods: {
        async getCurrentOrderRequest() {
          console.log("getCurrentOrderRequest......")
          if(this.currentOrderID == null) {
            return
          }
          // const res = await getOrderByIDAPI(this.token, this.currentOrderID)
          // console.log(res)
          this.orderIntervalCount = this.orderIntervalCount + 1
          if(this.orderIntervalCount >= 100 || this.payModal == false) {
            this.stopOrderInterval()
            return
          }
          const res = await getOrderListAPI(this.token, 1, 5);
            if (res.data.code == 0) {
                // this.orderList = res.data.records;
                // this.ipagination2.total = res.data.total;
                res.data.records.forEach(element => {
                  if(element.id == this.currentOrderID) {
                    console.log("当前订单。。。")
                    console.log(element)
                    if(element.state == 1) {
                      this.stopOrderInterval()
                      //this.payModal = false
                      this.$Message.success("支付成功")
                      this.paySuccess = 1
                      this.orderListRequest()
                    } else if(element.state == 2) {
                      this.stopOrderInterval()
                      //this.payModal = false
                      this.$Message.error("支付失败")
                      this.paySuccess = 2
                      this.orderListRequest()
                    }
                  }
                });
            }
        },
        async useVoucherRequest() {
          const res = await useVoucherAPI(this.token, this.voucherCode)
          if(res.data.code == 0) {
            this.$Message.success("兑换成功")
          } else {
            this.$Message.error("兑换失败：" + res.data.message)
          }
        },
        async createAliyunOrderRequest() {
          const res = await createAliyunOrderAPI(this.token, this.selectPackage.id)
          if(res.data.code == 0) {
            this.paySuccess = 0
            this.payModal = true
            this.alipayurl = res.data.url
            localStorage.setItem('currentOrder', res.data.order.id)
            window.open(res.data.url, '支付')
            this.currentOrderID = res.data.order.id
            this.startOrderInterval()
          } else {
            this.$Message.error("创建订单失败")
          }
        },
        async orderListRequest() {
            const res = await getOrderListAPI(this.token, this.ipagination2.current, this.ipagination2.pagesize);
            if (res.data.code == 0) {
                //this.orderList = res.data.records;
                let oList = []
                res.data.records.forEach(element => {
                  let methodStr = "";
                  if (element.order_method == 1) {
                      methodStr = "微信";
                  }
                  else if (element.order_method == 2) {
                      methodStr = "支付宝";
                  }
                  element.methodStr = methodStr
                  let stateStr = "";
                  if (element.state == 0) {
                      stateStr = "未支付";
                  }
                  else if (element.state == 1) {
                      stateStr = "支付成功";
                  }
                  else if (element.state == 2) {
                      stateStr = "支付失败";
                  }
                  element.stateStr = stateStr
                  element.createTimeStr = element.create_time === null ? '无' : getTimeStr(element.create_time)
                  oList.push(element)
                  console.log(element)
                });
                this.orderList = oList
                this.ipagination2.total = res.data.total;
            }
        },
        async packageListRequest() {
            const res = await getPackageListAPI(this.token, 1, 100);
            if (res.data.code == 0) {
                this.packageList = res.data.records;
            }
        },
        changePage1(val) {
            this.ipagination2.current = val;
            this.orderListRequest();
        },
        buy(item) {
          console.log("buy..." + item.id)
          this.selectPackage = item
          //this.$Message.error("功能开发中...")
          this.selectPayMethodModal = true
        },
        onClickWechatPay() {
          console.log('onClickWechatPay...')
        },
        onClickAliyunPay() {
          console.log('onClickAliyunPay...')
          this.selectPayMethodModal = false
          this.createAliyunOrderRequest()
        },
        makeSureBuy() {
          if(this.payMethod == 'wechat') {
            this.$Message.warning('微信支付暂不支持')
          } else if(this.payMethod == 'aliyun') {
            this.selectPayMethodModal = false
            this.createAliyunOrderRequest()
          }
        },
        onSelectPayMethod(method) {
          this.payMethod = method
        },
        iframeloaded() {
          console.log("iframeloaded.....")
          this.getCurrentOrderRequest()
        },
        startOrderInterval() {
          this.stopOrderInterval()
          console.log("startOrderInterval...")
          this.orderIntervalCount = 0
          this.orderInterval = setInterval(() => {
            this.getCurrentOrderRequest()
          }, 5000)
        },
        stopOrderInterval() {
          console.log("stopOrderInterval...")
          if(this.orderInterval) {
            clearInterval(this.orderInterval)
          }
        },
        useCVoucherClicked() {
          if(this.voucherCode == null || this.voucherCode.length == 0) {
            this.$Message.error("请输入兑换码")
            return
          }
          this.useVoucherRequest()
        }
    },
    created() {
      this.token = localStorage.getItem('token');
      if (this.token && this.token != "") {
          this.orderListRequest()
          this.packageListRequest()
      }
      this.stopOrderInterval()
    },
    beforeUnmount() {
      this.stopOrderInterval()
      emitter.off('chagelightmodechild')
    },
    mounted() {
      this.lightmode = this.$store.lightmode
      emitter.on('chagelightmodechild', (mode) => {
        this.lightmode = mode
      })
    }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';
.sub-title {
  color: #181818;
  margin-top: 20px;
  margin-bottom: 10px;
}

.chongzhi-bigback {
  //padding: 20px;
  overflow: auto;
  height: 100%;
  padding-bottom: 20px;
  .title {
    font-weight: bold;
  }
  .chongzhi-back {
    // width: 50%;
    //padding: 10px;
    //border: 1px solid #ccc;
  }

  .pageback {
    text-align: center;
    margin-top: 20px;
  }
}

.package-item {
  display: inline-flex;
  flex-direction: column;
  
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
  margin-top: 20px;

  div {
    text-align: left;
    margin-top: 10px;
    //color: black;
  }
  .package-title {
    text-align: left;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .package-desc {
    min-width: 200px;
    max-width: 300px;
    margin-bottom: 10px;
  }
  .ai-count {
    font-weight: left;
    font-size: 1.3rem;
  }
  .mj-count {
    font-weight: left;
    font-size: 1.3rem;
  }
  .exp-date {
    font-size: 0.8rem;
    margin-left: 10px;
  }
  .show-price {
    color: #999;
    font-size: 1.3rem;
    text-decoration: line-through;
  }
  .real-price {
    font-weight: bolder;
    color: #FA6400;
    font-size: 2.5rem;
  }

  .pay-method-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }

  .pay-method-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    color: #000000;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    cursor: pointer;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
  .pay-method-item.active {
    border: 1px solid #08C160;
  }

  .buy {
    background-color: #19be6b;
    margin-top: 20px;
  }
}
.select-pay-method-back {
  display: flex;
  justify-content: space-between;
}

.voucher-back {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .dark-input-back {
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 0px;
    margin-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .day-input-back {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    padding: 0px;
    margin-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  //.input {
    //margin-left: 15px;
    //padding-top: 10px;
    //padding-bottom: 10px;
    //color: #181818;
  //}
  .btn {
    margin-left: 15px;
  }
}

.pay-result-back {
  padding: 10px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.dark-package-item-bg {
  border: 1px solid #ffffff;
  color: white;
}
.day-package-item-bg {
  border: 1px solid #E0E0E0;
  color: #181818;
}
.item-max-width {
  max-width: 20%;
}

// /deep/.ivu-modal-content {
//   background-color: #FA6400;
// }
</style>
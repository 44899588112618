
<template>
  <div>
    <h1 class="child-title margin-left5">AI将根据图片生成文字描述</h1>
    <input ref="file" class="native-input" type="file" v-on:change="selectFile" nullMsg="文件不能为空" id="fileInput" accept="image/*">
    <div class="new-input-back">
      <img class="new-input-img" v-show="file && file.base64.length > 0" @click="selectImgClicked" :src="file.base64"/>
      <div v-show="file == null || file.base64.length == 0" @click="selectImgClicked" :class="lightmode=='day'?'day-new-input-btn':'dark-new-input-btn'">选择图片</div>
      <span class="new-input-hint">{{ file.file_name }}</span>
    </div>
    <Button class="send-to-father-btn" type="success" @click="send" long>图生文</Button>
  </div>
</template>
<script>
//import { imageToBase64, base64ToFile } from '@/utils/imageutil.js'
import emitter from '@/hooks/bus.js'
export default {
  props: {
      token: {
          type: String,
          required: true
      }
  },
  data() {
    return {
      lightmode: 'day',
      file: {
        base64: '',
        file_name: '请选择图片'
      }
    }
  },
  methods: {
    selectFile() {
      console.log("selectFile --- ")
      const file = document.getElementById('fileInput').files[0]
      console.log(file)
      if(file == undefined || file == null) {
        return
      }
      if(file.size > 1024 * 1024 * 5) {
        this.$Message.warning("图片大小不能超过5M")
        return
      }
      let reader = new FileReader();
      let that = this
      reader.onload = function() {
        let content = reader.result;
        // console.log("content.......")
        // console.log(content);
        // that.send(content, file.name)
        that.file = {
          base64: content,
          file_name: file.name
        }
      };
      reader.readAsDataURL(file)
      // const fileInput = document.getElementById('fileInput' + index).files[0]
      // console.log(fileInput)
      console.log("---------------")
      //let img = new Image()
    },
    send() {
      //console.log("send --- " + base64 + ",,," + file_name)
      //if(this.file && this.file.base64 && this.file.base64.length > 0)
      if(this.file && this.file.base64 && this.file.base64.length > 0) {
        emitter.emit('imageCreateText', this.file)
      } else {
        this.$Message.warning("请选择图片")
      }
    },
    selectImgClicked() {
      this.$refs.file.click();
    },
  },
  created() {
    //
  },
  beforeUnmount() {
    emitter.off('chagelightmodechild2')
  },
  mounted() {
    this.lightmode = this.$store.lightmode
    emitter.on('chagelightmodechild2', (mode) => {
      this.lightmode = mode
    })
  }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';
</style>
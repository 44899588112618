<template>
  <div class="bigback" :class="lightmode == 'day' ? 'day-normal-big-bg' : 'dark-normal-big-bg'">
    <h1 class="title">个人中心</h1>
    <Menu class="menu" mode="horizontal" :theme="theme" active-name="1" @on-select="onMenuSelected">
        <MenuItem name="1">
          <!-- <Icon type="ios-people" /> -->
          个人信息
        </MenuItem>
        <MenuItem name="2">
          <!-- <Icon type="md-body" /> -->
          消耗
        </MenuItem>
        <MenuItem name="3">
          <!-- <Icon type="md-body" /> -->
          充值
        </MenuItem>
    </Menu>
    <div class="content-back">
      <Person v-if="currentPage == '1'"></Person>
      <Consumption v-if="currentPage == '2'"></Consumption>
      <Recharge v-if="currentPage == '3'"></Recharge>
    </div>
  </div>
</template>
<script>
import Person from '@/components/person/Person.vue'
import Consumption from '@/components/person/Consumption.vue'
import Recharge from '@/components/person/Recharge.vue'
import emitter from '@/hooks/bus.js'
export default {
  components: {
    Person,
    Consumption,
    Recharge
  },
  data() {
    return {
      lightmode: 'day',
      currentPage: '1',
    }
  },
  methods: {
    onMenuSelected(name) {
      this.currentPage = name
    }
  },
  created() {
    this.lightmode = this.$store.lightmode
    emitter.on('chagelightmode', (mode) => {
      this.lightmode = mode
    })
  },
  beforeUnmount() {
    emitter.off('chagelightmode')
  }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';
// .personnalcenter-title {
//   padding: 20px;
//   font-weight: bolder;
//   font-size: 1.5rem;
// }
.bigback {
  //background-color: red;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;

  .title {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 1.3rem;
    font-weight: bolder;
  }

  .content-back {
    flex: 1;
    overflow: scroll;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 0px; //水平的隐藏
}
//背景
// ::-webkit-scrollbar-track {
//   background-color: #F7F7F7;
// }
// //滑块
// ::-webkit-scrollbar-thumb {
//   background-color: #cccccc;
// }
</style>
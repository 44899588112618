// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import { createRouter,createWebHistory,createWebHashHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
//import HomeChat from '@/views/Home/HomeChat.vue'
import ChatHome from '@/views/Home/ChatHome.vue'
import ImageHome from '@/views/MJ/ImageHome.vue'
import GalleryHome from '@/views/Gallery/GalleryHome.vue'
import NoticeHome from '@/views/Notice/NoticeHome.vue'
import PersonalCenter from '@/views/PersonalCenter/PersonalCenter.vue'
import GuidePage from '@/views/PersonalCenter/GuidePage.vue'

// Vue.use(VueRouter)

// // 修复重复点击导航报错问题
// const originalPush = VueRouter.prototype.push
// const originalReplace = VueRouter.prototype.replace
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }
// VueRouter.prototype.replace = function replace (location) {
//   return originalReplace.call(this, location).catch(err => err)
// }

const routes = [
  // {
  //   path: '/',
  //   name: 'homechat',
  //   component: HomeChat
  // },
  {
    path: '/',
    name: 'chathome',
    component: ChatHome
  },
  {
    path: '/imagehome',
    name: 'imagehome',
    component: ImageHome
  },
  {
    path: '/galleryhome',
    name: 'galleryhome',
    component: GalleryHome
  },
  {
    path: '/noticehome',
    name: 'noticehome',
    component: NoticeHome
  },
  {
    path: '/personnalcenter',
    name: 'personnalcenter',
    component: PersonalCenter
  },
  {
    path: '/guide',
    name: 'guide',
    component: GuidePage
  }
]


// const router = new VueRouter({
//   // mode: 'history',
//   routes
// })

const router = createRouter({
  // history: createWebHistory(),
  history:createWebHashHistory(),
  routes,
})


export default router


<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="userinfo-bigback">
    <div class="yue-back">
      <!-- <h1 class="title">余额</h1>
      <h2>聊天剩余Token：<span class="count">{{ userInfo.ai_token }}</span></h2>
      <h2>绘画剩余次数：<span class="count">{{ userInfo.mj_token }}</span></h2> -->
      <div class="yue-little-back" :class="lightmode == 'day' ? 'day-yue-little-bg' : 'dark-yue-little-bg'">
        <div class="yue-top">
          <div>
            <div class="yue-content">
              剩余：{{ userInfo.ai_token }}
            </div>
            <div class="yue-percent" :style="{color: aiPercentColor}">
              {{ aiTokenPercent }}
            </div>
          </div>
          <div class="yue-title">
            AI Token
            <span class="yue-left-time">{{ aiLeftTimeStr }}</span>
          </div>
          <div class="yue-bottom">
            <div class="yue-percent-back">
              <div class="yue-percent-little" :style="{width: aiPercentWidth, 'background-color': aiPercentColor}"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="yue-little-back" :class="lightmode == 'day' ? 'day-yue-little-bg' : 'dark-yue-little-bg'">
        <div class="yue-top">
          <div>
            <div class="yue-content">
              剩余：{{ userInfo.mj_token }}
            </div>
            <div class="yue-percent" :style="{color: mjPercentColor}">
              {{ mjTokenPercent }}
            </div>
          </div>
          <div class="yue-title">
            绘画
            <span class="yue-left-time">{{ mjLeftTimeStr }}</span>
          </div>
          <div class="yue-bottom">
            <div class="yue-percent-back">
              <div class="yue-percent-little" :style="{width: mjPercentWidth, 'background-color': mjPercentColor}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="yue-back">
      <h1 class="sub-title">消耗记录</h1>
      <!-- <Table style="max-width: 100%;" stripe :columns="columns1" :data="cosumeList"></Table> -->
      <div class="table-title-back">
        <div class="table-title-little-back">类型</div>
        <div class="table-title-little-back">编号</div>
        <div class="table-title-little-back">消耗</div>
        <div class="table-title-little-back">时间</div>
      </div>
      <div class="table-content-back" v-for="(item, index) in cosumeList" :key="item.id" :class="index % 2 == 0 ? 'table-content-bg1': 'table-content-bg2'">
        <div class="table-content-little-back">{{ item.typeStr }}</div>
        <div class="table-content-little-back">{{ item.id }}</div>
        <div class="table-content-little-back">{{ item.consumeStr }}</div>
        <div class="table-content-little-back">{{ item.createTimeStr }}</div>
      </div>
      <div class="pageback">
        <Page :total="ipagination1.total" :page-size="ipagination1.pagesize" class="page" @on-change="changePage1" show-total></Page>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserInfo } from '@/api/UserAPI.js'
import { getConsumeListAPI } from '@/api/ConsumeAPI.js'
import { getTimeStr, getLeftTimeStr } from "@/utils/timeutil.js"
import emitter from '@/hooks/bus.js'
export default {
  data() {
    return {
      lightmode: 'day',
      token: '',
      userInfo: {},
      aiTokenPercent: '100%',
      mjTokenPercent: '100%',
      aiLeftTimeStr: '0天',
      mjLeftTimeStr: '0天',
      cosumeList: [],
      ipagination1: {
        current: 1,
        total: 0,
        pagesize: 10
      },
      columns1: [
        {
          title: '类型',
          align: 'center',
          render: (h, params) => {
            let typeStr = ""
            if (params.row.mj_token > 0) {
              typeStr = "绘图"
            } else {
              typeStr = "对话"
            }
            return h('span', {
              style: {
                color: '#000000'
              }
            }, typeStr)
          }
        },
        {
          title: '编号',
          key: 'id',
          align: 'center'
        },
        {
          title: '消耗',
          align: 'center',
          render: (h, params) => {
            let consumeStr = ""
            if (params.row.mj_token > 0) {
              consumeStr = params.row.mj_token + " 次绘画"
            } else {
              consumeStr = params.row.total_tokens + " Token"
            }
            return h('span', {
              style: {
                color: '#000000'
              }
            }, consumeStr)
          }
        },
        {
          title: '时间',
          align: 'center',
          render: (h, params) => {
            const createTime = params.row.create_time === null ? '无' : getTimeStr(params.row.create_time)
            return h('span', {
              style: {
                color: '#000000'
              }
            }, createTime)
          }
        }
      ]
    }
  },
  methods: {
    async userInfoRequest () {
      const res = await getUserInfo(this.token)
      if (res.data.code === 0) {
        this.userInfo = res.data.sys_user_data
        if(this.userInfo.mj_token == null) {
          this.userInfo.mj_token = 0
        }
        if(this.userInfo.ai_token && this.userInfo.max_token && this.userInfo.max_token != 0) {
          if(this.userInfo.max_token < this.userInfo.ai_token) {
            return "100%"
          } else {
            this.aiTokenPercent = (this.userInfo.ai_token / this.userInfo.max_token * 100).toFixed(2) + "%"
          }
        } else {
          this.aiTokenPercent = "0%"
        }
        if(this.userInfo.mj_token && this.userInfo.max_mj_token && this.userInfo.max_mj_token != 0) {
          if(this.userInfo.max_mj_token < this.userInfo.mj_token) {
            return "100%"
          } else {
            this.mjTokenPercent = (this.userInfo.mj_token / this.userInfo.max_mj_token * 100).toFixed(2) + "%"
          }
        } else {
          this.mjTokenPercent = "0%"
        }
        if(this.userInfo.ai_expired) {
          this.aiLeftTimeStr = getLeftTimeStr(this.userInfo.ai_expired)
        }
        if(this.userInfo.mj_expired) {
          this.mjLeftTimeStr = getLeftTimeStr(this.userInfo.mj_expired)
        }
      }
    },
    async consumeListRequest() {
      const res = await getConsumeListAPI(this.token, this.ipagination1.current, this.ipagination1.pagesize)
      if(res.data.code == 0) {
        //this.cosumeList = res.data.records
        let cList = []
        res.data.records.forEach(element => {
          if(element.mj_token > 0) {
            element.typeStr = '绘画'
          } else {
            element.typeStr = '聊天'
          }
          let consumeStr = ""
          if (element.mj_token > 0) {
            consumeStr = element.mj_token + "次绘画"
          } else {
            consumeStr = element.total_tokens + " Token"
          }
          element.consumeStr = consumeStr
          element.createTimeStr = element.create_time === null ? '无' : getTimeStr(element.create_time)
          cList.push(element)
        });
        this.cosumeList = cList
        this.ipagination1.total = res.data.total
      }
    },
    changePage1(val) {
      this.ipagination1.current = val
      this.consumeListRequest()
    }
  },
  created() {
    this.token = localStorage.getItem('token');
    if(this.token && this.token != "") {
      this.userInfoRequest(true)
      this.consumeListRequest()
    }
  },
  beforeUnmount() {
    emitter.off('chagelightmodechild')
  },
  mounted() {
    this.lightmode = this.$store.lightmode
    emitter.on('chagelightmodechild', (mode) => {
      this.lightmode = mode
    })
  },
  computed: {
    aiPercentWidth: function() {
      if(this.userInfo && this.userInfo.ai_token && this.userInfo.max_token) {
        if(this.userInfo.max_token != 0) {
          if(this.userInfo.max_token < this.userInfo.ai_token) {
            return '100%'
          } else {
            return this.userInfo.ai_token / this.userInfo.max_token * 100 + "%"
          }
        }
      }
      return "0%"
    },
    mjPercentWidth: function() {
      if(this.userInfo && this.userInfo.mj_token && this.userInfo.max_mj_token) {
        if(this.userInfo.max_mj_token != 0) {
          if(this.userInfo.max_mj_token < this.userInfo.mj_token) {
            return '100%'
          } else {
            return this.userInfo.mj_token / this.userInfo.max_mj_token * 100 + "%"
          }
        }
      }
      return "0%"
    },
    aiPercentColor: function() {
      if(this.userInfo && this.userInfo.ai_token && this.userInfo.max_token) {
        if(this.userInfo.max_token != 0) {
          if(this.userInfo.max_token < this.userInfo.ai_token) {
            return '#08C160'
          } else {
            if(this.userInfo.ai_token / this.userInfo.max_token < 0.2) {
              return "#FA6400"
            } else {
              return '#08C160'
            }
          }
        }
      }
      return '#FA6400'
    },
    mjPercentColor: function() {
      if(this.userInfo && this.userInfo.mj_token && this.userInfo.max_mj_token) {
        if(this.userInfo.max_mj_token != 0) {
          if(this.userInfo.max_mj_token < this.userInfo.mj_token) {
            return '#08C160'
          } else {
            if(this.userInfo.mj_token / this.userInfo.max_mj_token < 0.2) {
              return "#FA6400"
            } else {
              return '#08C160'
            }
          }
        }
      }
      return "#FA6400"
    }
  }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';

.sub-title {
  color: #181818;
  margin-bottom: 10px;
}

.yue-back {
  padding-top: 10px;
  //border: 1px solid #ccc;
  padding-bottom: 20px;
  .yue-little-back {
    display: inline-block;
    border-radius: 10px;
    padding: 15px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    min-width: 300px;
    .yue-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start; //解决flex布局换行后出现间隙问题
      align-items: center;
      top: 0px;
      padding: 0px;
      .yue-content {
        //color: #000000;
        display: block;
      }
      .yue-percent {
        //color: #08C160;
        font-size: 2rem;
        font-weight: bolder;
        display: block;
      }
      .yue-title {
        font-weight: bolder;
        font-size: 2rem;
        display: flex;
        flex-direction: column;
        //background-color: red;
        align-items: end;
        padding-bottom: 10px;
        .yue-left-time {
          font-size: 0.8rem;
          font-weight: normal;
        }
      }
    }
    .yue-bottom {
      width: 100%;

      .yue-percent-back {
        height: 20px;
        width: 100%;
        background-color: #F3F3F3;
        border-radius: 10px;

        .yue-percent-little {
          display: inline-block;
          height: 20px;
          border-radius: 10px;
          //background-color: #08C160;
        }
      }
    }
  }

  .count {
    font-weight: bolder;
    font-size: 1.2rem;
  }
}

.pageback {
  text-align: center;
  margin-top: 10px;
}

.dark-yue-little-bg {
  background-color: #181818;
  border: 1px solid #000000;
}
.day-yue-little-bg {
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
}


</style>
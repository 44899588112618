import request from '@/utils/request'


export const addMJMessage = function (token, topic_id, prompt, prompt_cn, botId) {
  return request.post('/sysMessage/mj_add', {
    token,
    topic_id,
    prompt,
    prompt_cn,
    botId
  })
}

export const imageCreateImage = function (token, topic_id, prompt, prompt_cn, botId, base64, file_name, url, iw) {
  let data = {}
  if(!url) {
    data = {
      token,
      topic_id,
      prompt,
      prompt_cn,
      botId,
      base64,
      file_name,
      iw
    }
  } else {
    data = {
      token,
      topic_id,
      prompt,
      prompt_cn,
      botId,
      url,
      iw
    }
  }
  return request.post('/sysMessage/mj_like', data)
}


//轮循任务结果
export const fetchMJResult = function (token, task_uuid) {
  return request.post('/sysMessage/mj_fetch', {
    token,
    task_uuid
  })
}

//变换图片
export const changeMJImage = function  (token, message_id, customId, prompt, prompt_cn) {
  return request.post('/sysMessage/mj_change', {
    token,
    message_id,
    customId,
    prompt,
    prompt_cn
  })
}

//图生文
export const imgCreateText = function  (token, topic_id, base64, file_name) {
  return request.post('/sysMessage/mj_describe', {
    token,
    topic_id,
    base64,
    file_name,
    botId: 'MJBot' // MJBot, NijiBot偏向二次元
  })
}

//混图
export const imgBlend = function  (token, topic_id, base64, file_name, base64_2, file_name_2) {
  return request.post('/sysMessage/mj_blend', {
    token,
    topic_id,
    base64,
    file_name,
    base64_2,
    file_name_2
  })
}

//换脸
export const imgFaceWap = function  (token, topic_id, base64, file_name, base64_2, file_name_2) {
  return request.post('/sysMessage/mj_faceswap', {
    token,
    topic_id,
    base64,
    file_name,
    base64_2,
    file_name_2
  })
}
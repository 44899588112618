// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//     token: ''
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })


import { createStore } from 'vuex'

export default createStore({
  state: {
    lightmode: 'day'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
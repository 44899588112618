import request from '@/utils/request'


export const getUserDetail = function (token, id) {
  return request.post('/sysUser/queryById', {
    token,
    id
  })
}

export const addUser = function (token, username, password, realname, phone, email, company_id, role_id) {
  return request.post('/sysUser/add', {
    token,
    sys_user: {
      username,
      password,
      realname,
      phone,
      email,
      company_id,
      role_id
    }
  })
}

export const editUser = function (token, id, realname, email) {
  return request.post('/sysUser/edit', {
    token,
    sys_user: {
      id,
      realname,
      email
    }
  })
}

export const editHead = function (token, id, avatar) {
  return request.post('/sysUser/edit', {
    token,
    sys_user: {
      id,
      avatar
    }
  })
}

export const editPwd = function (token, old_password, new_password) {
  return request.post('/sysUser/changePwd', {
    token,
    old_password,
    new_password
  })
}

export const getUserInfo = function (token) {
  return request.post('/sysUser/user_info', {
    token
  })
}
<!-- 绘画聊天详情 -->
<template>
  <div class="mj-home" v-resize="handleResize">
    <div class="chat-list" :class="lightmode=='day' ? 'day-topiclist-bg' : 'dark-topiclist-bg'" v-if="!isMobile">
      <TopicList :chat_type="chat_type"></TopicList>
    </div>
    <!--手机对话列表-->
    <Drawer v-if="isMobile" title="对话列表" placement="left" :closable="true" :mask-closable="false" v-model="topicsDrawer">
      <TopicList :chat_type="chat_type"></TopicList>
    </Drawer>
    <!--手机选项-->
    <Drawer height="80" v-if="isMobile" title="绘画选项" placement="bottom" :closable="true" :mask-closable="false" v-model="optionDrawer">
      <!-- <div style="background-color: red;">dsafdfssf</div> -->
      <div class="option-back" style="padding: 0px;border-right: none;" :class="lightmode == 'day' ? 'day-option-bg': 'dark-option-bg'">
        <Menu mode="horizontal" active-name="1" @on-select="onMenuSelected">
          <MenuItem name="1">
            绘图
          </MenuItem>
          <MenuItem name="2">
            图生文
          </MenuItem>
          <MenuItem name="3">
            混图
          </MenuItem>
        </Menu>
        <div class="menu-content-bg">
          <AIDrawNew :token="token" v-if="currentMenu == '1'"></AIDrawNew>
          <AIImageCreatText v-if="currentMenu == '2'"/>
          <AIBlend v-if="currentMenu == '3'"/>
        </div>
      </div>
    </Drawer>
    <div class="config" v-if="!isMobile">
      <div class="option-back" :class="lightmode == 'day' ? 'day-option-bg': 'dark-option-bg'">
        <Menu mode="horizontal" active-name="1" @on-select="onMenuSelected">
          <MenuItem name="1">
            绘图
          </MenuItem>
          <MenuItem name="2">
            图生文
          </MenuItem>
          <MenuItem name="3">
            混图
          </MenuItem>
        </Menu>
        <div class="menu-content-bg">
          <AIDrawNew :token="token" v-if="currentMenu == '1'"></AIDrawNew>
          <AIImageCreatText v-if="currentMenu == '2'"/>
          <AIBlend v-if="currentMenu == '3'"/>
        </div>
      </div>
    </div>
    <div class="no-chat-back" :class="lightmode == 'day' ? 'day-option-bg': 'dark-option-bg'" style="flex: 6;" v-if="isLogin==false">
      <div><img class="nochat-img" src="@/assets/big_chat.png"/></div>
      <div class="nochat-title">聊天对话</div>
      <div class="nochat-content">点击下方按钮先登录吧</div>
      <div class="nochat-button-button">
        <Button type="success" @click="noChatLoginClick">登录</Button>
      </div>
    </div>
    <div class="no-chat-back" :class="lightmode == 'day' ? 'day-option-bg': 'dark-option-bg'" style="flex: 6;" v-if="isLogin==true && (!currentTopic || currentTopic.id == -1)">
      <div><img class="nochat-img" src="@/assets/big_chat.png"/></div>
      <div class="nochat-title">聊天对话</div>
      <div class="nochat-content">点击下方按钮开始你的对话吧</div>
      <div class="nochat-button-button">
        <Button type="success" @click="noChatCreateClick">新对话</Button>
      </div>
    </div>
    <div class="chat-back" :class="lightmode == 'day' ? 'day-option-bg': 'dark-option-bg'" v-if="isLogin==true && (currentTopic && currentTopic.id != -1)">
      <div class="chat-top" :class="lightmode == 'day' ? 'day-chat-top-bg' : 'dark-chat-top-bg'">
        <div class="topicname">
          <Icon size=20 class="cursor-pointer" @click="topicsDrawer=true" v-if="isMobile" type="md-chatboxes" />
          <strong>{{ currentTopic.title }}</strong>
          <!-- <span class="token-left">绘画剩余：</span> -->
          <Icon class="token-left" size="20" type="md-color-palette" />
          <strong>{{ mj_token }}</strong>
        </div>
        <div class="dis-flex-center">
          <Icon size="20" class="cursor-pointer" @click="optionDrawer=true" v-if="isMobile" type="ios-hammer" />
        </div>
      </div>
      <!-- <div class="chat-split"></div> --> 
      <div class="chat-content" ref="mjchatbox" @wheel="onChatBoxMouseWheel" @touchmove="onChatBoxMouseWheel">
        <div v-for="(message) in currentMessages" :key="message.id">
          <!--用户发送-->
          <div class="user-send" v-if="message.isUser == true">
            <div class="leftseizeseat"></div>
            <div class="right">
              <div class="user-msg-bigback">
                <div class="user-time-back">
                  <Icon v-if="message.mj_action == 'IMAGINE'" type="ios-copy" class="cursor-pointer msg-tool-btn" size="18" @click="onCopyPrompt(message.mj_prompt)"/>
                  <Icon type="ios-trash" class="cursor-pointer msg-tool-btn" size="18" @click="showHintModal(-1,'delete', message)"/>
                  <span class="chat-time-right">{{ message.timeStr }}</span>
                </div>
                <!--文生图或者change-->
                <div class="user-msg-back bg-[#a0dc94]" v-if="message.mj_action == 'IMAGINE' || message.mj_action == 'CHANGE' || message.mj_action == 'ZOOM'">
                  <span class="mj-label">{{ message.mj_labelStr }}</span> <span class="msg-text">{{ message.mj_prompt }}</span>
                </div>
                <!--图生图-->
                <!-- <div class="user-msg-back bg-[#a0dc94]" v-if="message.mj_action == 'LIKE'">
                  <span class="mj-label">{{ message.mj_labelStr }}</span>
                  <Image :src="message.sourceUrl1" fit="contain" class="mj-Image" preview :preview-list="[message.sourceUrl1]">
                    <template #error>
                          <Icon type="ios-image-outline" size="24" color="#ccc" />
                      </template>
                  </Image>
                </div> -->
                <div class="user-img-back" v-if="message.mj_action == 'LIKE'">
                  <Image :src="message.sourceUrl1" fit="contain" class="mj-Image" :style="{maxWidth: mjMaxWidth}" preview :preview-list="[message.sourceUrl1]">
                    <template #error>
                          <Icon type="ios-image-outline" size="24" color="#ccc" />
                      </template>
                  </Image> <!--:initial-index="index"-->
                  <div>
                    <span class="mj-label">{{ message.mj_labelStr }}</span>
                  </div>
                  <!-- <div>图生文</div> -->
                </div>
                <!--图生文-->
                <div class="user-img-back" v-if="message.mj_action == 'DESCRIBE'">
                  <Image :src="message.sourceUrl1" fit="contain" class="mj-Image" :style="{maxWidth: mjMaxWidth}" preview :preview-list="[message.sourceUrl1]">
                    <template #error>
                          <Icon type="ios-image-outline" size="24" color="#ccc" />
                      </template>
                  </Image> <!--:initial-index="index"-->
                  <!-- <div>图生文</div> -->
                </div>
                <!--混图-->
                <div class="user-img-back" v-if="message.mj_action == 'BLEND'">
                  <Image :src="message.sourceUrl1" fit="contain" class="mj-Image" style="max-width: 40%;" preview :preview-list="[message.sourceUrl1]">
                    <template #error>
                          <Icon type="ios-image-outline" size="24" color="#ccc" />
                      </template>
                  </Image> <!--:initial-index="index"-->
                  X
                  <Image :src="message.sourceUrl2" fit="contain" class="mj-Image" style="max-width: 40%;" preview :preview-list="[message.sourceUrl2]">
                    <template #error>
                          <Icon type="ios-image-outline" size="24" color="#ccc" />
                      </template>
                  </Image> <!--:initial-index="index"-->
                  <!-- <div>混图</div> -->
                </div>
              </div>
              <!-- <Avatar class="user-head" icon="md-person" size="large" v-if="!avatar || avatar.length == 0"/>
              <Avatar :src="avatar" class="user-head" icon="md-person" size="large" v-if="avatar && avatar.length > 0"/> -->
              <img :src="avatar" v-if="avatar != null && avatar.length > 0" class="uhead" />
              <img src="@/assets/default_head.png" v-if="avatar == null || avatar.length == 0" class="uhead" />
            </div>
          </div>
          <!--用户发送End-->
          <!--MJ回复-->
          <div class="mj-reply" v-if="message.isUser == false">
            <div class="left">
              <!-- <Avatar class="mj-head" icon="md-barcode" size="large"/> -->
              <img src="@/assets/ai_head.png" class="uhead" />
              <div class="mj-msg-bigback">
                <div class="mj-time-back">
                  <span class="chat-time-left">{{ message.timeStr }}</span>
                  <!-- <strong class="ml-2">生成图片</strong> -->
                  <Icon v-if="message.mj_action == 'DESCRIBE'" type="ios-copy" class="cursor-pointer msg-tool-btn" size="20" @click="onCopyPrompt(message.desStr)"/>
                  <Tooltip v-if="message.mj_action == 'IMAGINE'" class="cursor-pointer msg-tool-btn" max-width="200" :content="'提示词：' + message.mj_prompt + '\n中文提示词：' + (message.mj_prompt_cn == null ? '无' : message.mj_prompt_cn)">
                    <Icon type="md-book" size="18" @click="onPutPromt(message)"/>
                  </Tooltip>
                  <Tooltip v-if="message.canAsReferenceImage" class="cursor-pointer msg-tool-btn" max-width="200" content="将图片作为参考图">
                    <Icon type="md-create" size="18" @click="onPutImage(message)"/>
                  </Tooltip>
                  <Tooltip v-if="message.mj_action != 'DESCRIBE' && message.mj_image_url" class="cursor-pointer msg-tool-btn" max-width="200" content="添加到画廊">
                    <Icon type="ios-share-alt" size="18" @click="showHintModal(-1,'share', message)"/>
                  </Tooltip>
                  <Icon type="ios-trash" class="cursor-pointer msg-tool-btn" size="18" @click="showHintModal(-1,'delete', message)"/>
                </div>
                <div class="mj-msg-back" v-if="message.mj_action != 'DESCRIBE' && !message.mj_image_url && message.mj_status != 4 && message.mj_status != 6"> <!--|| !message.mj_image_url-->
                  AI绘图中...
                  <CountDown :target="targetTime" @on-end="handleEnd" v-font="20" />
                </div>
                <div class="mj-msg-back" v-if="message.mj_action == 'DESCRIBE' && (!message.mj_status || (message.mj_status && message.mj_status < 4))"> <!--|| !message.mj_image_url-->
                  AI思考中...
                  <CountDown :target="targetTime" @on-end="handleEnd" v-font="20" />
                </div>
                <div class="mj-msg-back text-warning" v-if="message.mj_status == 4 || message.mj_status == 6">
                  {{ message.failMsg }}
                </div>
                <div class="mj-msg-back" v-if="message.mj_action != 'DESCRIBE' && message.mj_status && message.mj_status < 4 && message.progress && message.progress > 0">
                  图片生成中...{{ message.progress }}%
                </div>
                <!--非图生文-->
                <div v-if="message.mj_action != 'DESCRIBE'">
                    <!-- <NImage show-toolbar-tooltip="true" :src="message.mj_image_url" class="mjImage"/> -->
                    <Image lazy @on-load="onImageLoaded" v-if="message.mj_image_url" :src="message.mj_image_url" fit="contain"  class="mj-Image" :style="{maxWidth: mjMaxWidth}" preview :preview-list="[message.mj_image_url]" >
                      <template #error>
                          <Icon type="ios-image-outline" size="24" color="#ccc" />
                      </template>
                    </Image> <!--:initial-index="index"-->
                </div>
                <!--图生文-->
                <div v-if="message.mj_action == 'DESCRIBE' && message.desStr">
                  <mavon-editor
                    v-model="message.desStr"
                    :subfield="false"
                    :defaultOpen="'preview'"
                    :boxShadow="false"
                    :editable="false"
                    :toolbarsFlag="false"
                    codeStyle="github-dark"
                    previewBackground="#E0E0E0"
                    :ishljs="true"
                    style="min-height: 20px;z-index: 1;border: none;">
                  </mavon-editor>
                </div>
                <div class="mj-trans-back" v-if="message.uOptions && message.uOptions.length > 0">
                  <strong class="trans-title">放大</strong>
                  <Tooltip v-for="uoption in message.uOptions" :key="uoption.label" max-width="200" :content="uoption.content">
                    <Button class="trans-btn" type="success" @click="showHintModal2(message, uoption)">{{ uoption.title }}</Button>
                  </Tooltip>
                </div>
                <div class="mj-trans-back" v-if="message.sOptions && message.sOptions.length > 0">
                  <strong class="trans-title">缩焦</strong>
                  <Tooltip v-for="soption in message.sOptions" :key="soption.label" max-width="200" :content="soption.content">
                    <Button class="trans-btn" type="success" @click="showHintModal2(message, soption)">{{ soption.title }}</Button>
                  </Tooltip>
                </div>
                <div class="mj-trans-back" v-if="message.moveOptions && message.moveOptions.length > 0">
                  <strong class="trans-title">位移</strong>
                  <Tooltip v-for="moveoption in message.moveOptions" :key="moveoption.label" max-width="200" :content="moveoption.content">
                    <Button class="trans-btn" type="success" @click="showHintModal2(message, moveoption)">{{ moveoption.title }}</Button>
                  </Tooltip>
                </div>
                <div class="mj-trans-back" v-if="message.vOptions && message.vOptions.length > 0">
                  <strong class="trans-title">变换</strong>
                  <Tooltip v-for="voption in message.vOptions" :key="voption.label" max-width="200" :content="voption.content">
                    <Button class="trans-btn" type="success" @click="showHintModal2(message, voption)">{{ voption.title }}</Button>
                  </Tooltip>
                </div>
                <div class="mj-trans-back" v-if="message.otherOptions && message.otherOptions.length > 0">
                  <strong class="trans-title">其他</strong>
                  <Tooltip v-for="otheroption in message.otherOptions" :key="otheroption.label" max-width="200" :content="otheroption.content">
                    <Button class="trans-btn" type="success" @click="showHintModal2(message, otheroption)">{{ otheroption.title }}</Button>
                  </Tooltip>
                </div>
                
              </div>
            </div>
            <div class="rightseizeseat"></div>
          </div>
          <!--MJ回复End-->
        </div>
      </div>
      <div class="chat-seizeseat"></div>
    </div>
    <!-- <Modal
        v-model="hintModal"
        :title="hintModalInfo.title"
        @on-ok="handlerOk"
        @on-cancel="handlerCancel">
        <p>{{ hintModalInfo.content }}</p>
    </Modal> -->
    <Modal v-model="hintModal" :closable="true" :mask-closable="false" footer-hide width="500">
      <div>
        <h1 class="modal-title">{{ hintModalInfo.title }}</h1>
        <h1 class="modal-content">{{ hintModalInfo.content }}</h1>
        <div class="vary-input-bg" v-if="hintModalInfo.label == 'Vary (Strong)' || hintModalInfo.label == 'Vary (Subtle)'">
          <Input :rows="3" :border="false" v-model="hintModalInfo.varyMsg" type="textarea" placeholder="请输入要变换的内容（例如：把头发换成红色，鞋子换成运动鞋）" />
        </div>
        <div class="vary-tran-bg" v-if="hintModalInfo.label == 'Vary (Strong)' || hintModalInfo.label == 'Vary (Subtle)'">
          <Button @click="handlerTrans" type="success">翻译</Button>
          <Button @click="handlerClear">清空</Button>
        </div>
        <div class="modal-footer-btn-back">
          <Button class="modal-footer-btn" @click="handlerOk" type="success">确定</Button>
          <Button class="modal-footer-btn" @click="handlerCancel">取消</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { getMessageList, deleteMessage } from '@/api/MessageAPI.js'
import { getUserInfo } from '@/api/UserAPI.js'
import { getTimeStr, getCurrentTimeStamp } from "@/utils/timeutil.js"
import { addMJMessage, fetchMJResult, changeMJImage, imgCreateText, imgBlend, imageCreateImage } from '@/api/MjAPI.js'
import { addMessageToGalleryAPI } from '@/api/GalleryAPI.js'
import { copyObj } from '@/utils/deepcopy.js'
import { getMJListResult, getMJOptions, getMJOptionStr, getCanAsReferenceImage } from '@/utils/mjmessageutil.js'
import TopicList from '@/components/TopicList.vue';
//import DrawTopNav from '@/components/mj/DrawTopNav.vue'
import clipboard from 'clipboard'
import emitter from '@/hooks/bus.js'
import AIDrawNew from '@/components/mj/AIDrawNew.vue'
import AIImageCreatText from '@/components/mj/AIImageCreatText.vue';
//import AIFace from '@/components/mj/AIFace.vue'
import AIBlend from '@/components/mj/AIBlend.vue'
//import AIDall from '@/components/mj/AIDall.vue'
import { EncodeUtf8 } from '@/utils/utf8util.js'
import { languageTranslate } from '@/api/LanguageTranslateAPI.js'

export default {
  components: {
    TopicList,
    //DrawTopNav
    AIDrawNew,
    AIImageCreatText,
    AIBlend,
    //AIFace,
    // AIDall
  },
  data() {
    return {
      lightmode: 'day',
      token: '',
      mj_token: 0,
      avatar: '',
      chat_type: 1, //MJ
      isLogin: false,
      isMobile: false,
      topicsDrawer: false,
      optionDrawer: false,
      currentTopic: {
        id: -1
      },
      hintModal: false,
      hintModalInfo: {
        id: -1,
        target: 'enlarge', //enlarge-放大，trans-变换，delete删除
        index: -1,
        title: '',
        content: '',
        varyMsg: '', //vary的内容
        varyMsg_cn: '' //vary的中文内容
      },
      currentMessages: [],
      mjFetchInterval: null,
      mjFetchMessageID: -1,
      mjFetchUuid: "",
      mjFetching: false,
      mjFetchCount: 120,
      newMessage: "",
      chatSettings: {
        model: 'gpt-3.5-turbo',
        temperature: "1",
        top_p: 1
      },
      lastScrollTime: -1,
      targetTime: 0,
      wheeled: false,
      currentMenu: '1'
    }
  },
  methods: {
    async userInfoRequest () {
      const res = await getUserInfo(this.token)
      if (res.data.code === 0) {
        this.mj_token = res.data.sys_user_data.mj_token
      }
    },
    onPutPromt(message) {
      console.log('onPutPromt...')
      console.log(message)
      if(this.isMobile) {
        this.optionDrawer = true
      }
      //emitter.emit('putPrompt', message.mj_prompt + "\n" + message.mj_prompt_cn)
      emitter.emit('putPrompt', message.mj_prompt_cn)
    },
    onPutImage(message) {
      console.log('onPutImage...')
      console.log(message)
      emitter.emit('putImage', message)
    },
    onCopyPrompt(prompt) {
      clipboard.copy(prompt)
      this.$Message.success('已复制到剪切板')
    },
    showHintModal(id, target, message, option) {
      this.hintModalInfo = {
        id,
        target
      }
      this.hintModalInfo.message_id = message.id
      if (target === 'enlarge') {
        this.hintModalInfo.title = "图片放大"
        //this.hintModalInfo.message_id = message.id
        this.hintModalInfo.customId = option.custom
        this.hintModalInfo.prompt = option.label
        this.hintModalInfo.content = "确定要放大" + option.title + "吗？每次放大需要消耗1次绘画数"
      } else if (target === 'scale') {
        this.hintModalInfo.title = "图片缩焦"
        //this.hintModalInfo.message_id = message.id
        this.hintModalInfo.customId = option.custom
        this.hintModalInfo.prompt = option.label
        this.hintModalInfo.content = "确定要缩小" + option.title + "吗？每次缩焦需要消耗1次绘画数"
      } else if (target === 'trans') {
        this.hintModalInfo.title = "图片变换"
        //this.hintModalInfo.message_id = message.id
        this.hintModalInfo.customId = option.custom
        this.hintModalInfo.prompt = option.label
        this.hintModalInfo.content = "确定要变换图片" + option.title + "吗？每次转换需要消耗1次绘画数"
      } else if (target === 'delete') {
        this.hintModalInfo.title = "删除消息"
        this.hintModalInfo.content = "删除消息会将提示词与AI回复一起删除，确定要删除此条消息吗？"
      } else if (target === 'share') {
        this.hintModalInfo.title = "添加到画廊"
        this.hintModalInfo.content = "确定要将图片添加到画廊吗？"
      }
      this.hintModal = true
    },
    showHintModal2(message, option) {
      console.log("showHintModal2...")
      console.log(message)
      this.hintModalInfo = {
        target: 'mj',
        message_id: message.id,
        customId: option.custom,
        label: option.label,
        prompt: message.prompt,
        //title: option.title,
        title: option.content,
        //content: option.content + "，每次执行都会消耗1次绘画，确定执行吗？"
        content: "每次执行都会消耗1次绘画，确定执行吗？",
        varyMsg: '',
        varyMsg_cn: ''
      }
      this.hintModal = true
    },
    handlerTrans() {
      if(!this.hintModalInfo.varyMsg || this.hintModalInfo.varyMsg.length == 0) {
        return
      }
      let utf8Str = EncodeUtf8(this.hintModalInfo.varyMsg)
      this.requestLanguageTranslate(utf8Str)
    },
    handlerClear() {
      this.hintModalInfo.varyMsg = ''
      this.hintModalInfo.varyMsg_cn = ''
    },
    async requestLanguageTranslate(text) {
        const res = await languageTranslate(this.token, text)
        console.log(res)
        if(res.data.code == 0) {
            this.hintModalInfo.varyMsg_cn = this.hintModalInfo.varyMsg
            this.hintModalInfo.varyMsg = res.data.target_text
        }
    },
    handlerOk() {
      // if (this.hintModalInfo.target === 'enlarge') {
      //   this.mjChangesRequest()
      // } else if (this.hintModalInfo.target == 'scale') {
      //   this.mjChangesRequest()
      // } else if (this.hintModalInfo.target == 'trans') {
      //   this.mjChangesRequest()
      // } else 
      if (this.hintModalInfo.target == 'mj') {
        if(this.mjFetching == true) {
          this.$Message.warning("请等待当前任务完成后再继续操作")
          return
        }
        if(this.hintModalInfo.label == 'Vary (Strong)' || this.hintModalInfo.label == 'Vary (Subtle)') {
          this.hintModalInfo.prompt = this.hintModalInfo.varyMsg
          this.hintModalInfo.prompt_cn = this.hintModalInfo.varyMsg_cn
          // console.log("this.hintModalInfo.prompt..." + this.hintModalInfo.prompt)
          // console.log("this.hintModalInfo.prompt_cn..." + this.hintModalInfo.prompt_cn)
        }
        this.mjChangesRequest()
      } else if (this.hintModalInfo.target == 'delete') {
        this.messageDeleteRequest()
      } else if (this.hintModalInfo.target == 'share') {
        this.addImageToGalleryRequst()
      }
      this.hintModal = false
    },
    handlerCancel() {
      this.hintModal = false
    },
    //请求添加到画廊
    async addImageToGalleryRequst() {
      const res = await addMessageToGalleryAPI(this.token, this.hintModalInfo.message_id)
      if(res.data.code === 0) {
        this.$Message.success('添加到画廊成功!')
      } else {
        this.$Message.error('添加到画廊失败!' + res.data.message)
      }
    },
    //请求图片变换
    async mjChangesRequest() {
      let message_id = this.hintModalInfo.message_id
      const res = await changeMJImage(this.token, message_id, this.hintModalInfo.customId, this.hintModalInfo.prompt, this.hintModalInfo.prompt_cn)
      this.userInfoRequest()
      if(res === null || res.data === null || res.data.code !== 0) {
        this.$Message.error('任务执行失败!')
        return;
      }
      //添加用户发送
      //if (res.data.data.mj_prompt) {
        let msg2 = copyObj(res.data.data)
        msg2.isUser = true
        msg2.mj_labelStr = getMJOptionStr(msg2.mj_label)
        msg2.timeStr = getTimeStr(msg2.create_time)
        this.currentMessages.push(msg2)
      //}
      //添加MJ回复
      if (res.data.data.mj_task_uuid) {
        let msg = copyObj(res.data.data)
        msg.isUser = false
        msg.timeStr = getTimeStr(msg.create_time)
        this.currentMessages.push(msg)
        //this.startFetchMJ(message_id, res.data.data.mj_task_uuid, res.data.data.mj_action)
        this.startFetchMJ(msg.id, res.data.data.mj_task_uuid, res.data.data.mj_action)
      }
      this.scrollToBottomDelay(50)
    },
    //轮询请求MJ结果
    async mjResultRequest(message_id, task_uuid, mj_action) {
      console.log("mjResultRequest..." + message_id + "," + task_uuid + "," + mj_action)
      const res = await fetchMJResult(this.token, task_uuid)
      const msgID = message_id
      if (res.data.code === 0) {
        //this.$Message.success('轮询任务结果成功')

        if (res.data.task.data.mj_action == "DESCRIBE") { //图生文
          if(res.data.task.data.mj_status == 5) {
            this.stopFetchMJ()
            let mjFetchResult = null
            for(let i=0;i<this.currentMessages.length;i++) {
              let element = this.currentMessages[i]
              //console.log("图生文5 element.id =" + element.id + ", msgID = " + msgID)
              if(element.isUser == false && element.mj_task_uuid == res.data.task.data.mj_task_uuid) {
                mjFetchResult = res.data.task.data
                mjFetchResult.isUser = false
                let desJson = JSON.parse(mjFetchResult.mj_description)
                if(desJson) {
                  let desStr = ""
                  for(let i=0;i<desJson.length;i++) {
                    if (i == desJson.length - 1) {
                      desStr = desStr + desJson[i]
                    } else {
                      desStr = desStr + desJson[i] + "\n"
                    }
                  }
                  console.log("desStr..." + desStr)
                  mjFetchResult.desStr = desStr
                  mjFetchResult.id = msgID
                  mjFetchResult.timeStr = getTimeStr(mjFetchResult.create_time)
                  //mjFetchResult.status = res.data.task.status
                }
                this.currentMessages.splice(i, 1)
                break
              }
            }
            this.currentMessages.push(mjFetchResult)
            this.scrollToBottomDelay(50)
          }
        } else {
          if(res.data.task.data.mj_status == 3) {
            let mjFetchResult = null
            for(let i=0;i<this.currentMessages.length;i++) {
              let element = this.currentMessages[i]
              //console.log("3 element.id =" + element.id + ", msgID = " + msgID)
              if(element.isUser == false && element.mj_task_uuid == res.data.task.data.mj_task_uuid) {
                mjFetchResult = res.data.task.data
                mjFetchResult.isUser = false
                mjFetchResult.progress = res.data.task.progress
                mjFetchResult.id = msgID
                this.currentMessages.splice(i, 1)
                break
              }
            }
            this.currentMessages.push(mjFetchResult)
            this.scrollToBottomDelay(50)
          } else if(res.data.task.data.mj_status == 4 || res.data.task.data.mj_status == 6
                    || res.data.task.status == 4 || res.data.task.status == 6) {//为什么res.data.task.data.mj_status是3，res.data.task.status是4
            this.stopFetchMJ()
            let mjFetchResult = null
            for(let i=0;i<this.currentMessages.length;i++) {
              let element = this.currentMessages[i]
              if(element.isUser == false && element.mj_task_uuid == res.data.task.data.mj_task_uuid) {
                mjFetchResult = res.data.task.data
                mjFetchResult.isUser = false
                mjFetchResult.progress = res.data.task.progress
                mjFetchResult.id = msgID
                if(res.data.task.data.mj_status == 4 || res.data.task.status == 4) {
                  mjFetchResult.mj_status = 4
                  mjFetchResult.failMsg = '失败（绘画次数返还）'
                } else if(res.data.task.data.mj_status == 6 || res.data.task.status == 6) {
                  mjFetchResult.mj_status = 6
                  mjFetchResult.failMsg = '超时（绘画次数返还）'
                }
                mjFetchResult.timeStr = getTimeStr(mjFetchResult.create_time)
                this.currentMessages.splice(i, 1)
                break
              }
            }
            this.currentMessages.push(mjFetchResult)
            this.scrollToBottomDelay(50)
          } else if(res.data.task.data.mj_status == 5) {
            if(res.data.task.data.mj_image_url 
              && res.data.task.data.mj_image_url.length > 0
              && !res.data.task.data.mj_image_url.endsWith(".webp")) {
                this.stopFetchMJ()
              }
            let mjFetchResult = null
            for(let i=0;i<this.currentMessages.length;i++) {
              let element = this.currentMessages[i]
              //console.log("i" + i + ", 5 element.id =" + element.id + ", msgID = " + msgID)
              if(element.isUser == false && element.mj_task_uuid == res.data.task.data.mj_task_uuid) {
                //console.log("element.id =" + element.id + ", msgID = " + msgID)
                mjFetchResult = res.data.task.data
                mjFetchResult.isUser = false
                mjFetchResult.canAsReferenceImage = getCanAsReferenceImage(mjFetchResult)
                mjFetchResult.progress = res.data.task.progress
                let ops = getMJOptions(mjFetchResult.mj_options)
                mjFetchResult.uOptions = ops.uOptions
                mjFetchResult.sOptions = ops.sOptions
                mjFetchResult.moveOptions = ops.moveOptions
                mjFetchResult.vOptions = ops.vOptions
                mjFetchResult.otherOptions = ops.otherOptions
                mjFetchResult.id = msgID
                mjFetchResult.timeStr = getTimeStr(mjFetchResult.create_time)
                this.currentMessages.splice(i, 1)
                break
              }
            }
            this.currentMessages.push(mjFetchResult)
            this.scrollToBottomDelay(50)
          }
        }
        
      } else {
        //this.$Message.error('轮询任务结果失败：' + res.data.message)
        this.$Message.error('获取任务结果失败：' + res.data.message)
        this.stopFetchMJ()
        for(let i=0;i<this.currentMessages.length;i++) {
              let element = this.currentMessages[i]
              console.log("失败。。。element.mj_task_uuid == " + element.mj_task_uuid)
              console.log("失败。。。this.mjFetchUuid == " + task_uuid)
              if(element.isUser == false && element.mj_task_uuid == task_uuid) {
                let timeStr = getTimeStr(element.create_time)
                let mjFetchResult = {
                  mj_status: 4,
                  failMsg: '失败（绘画次数返还）',
                  id: msgID,
                  isUser: false,
                  timeStr: timeStr
                }
                this.currentMessages.splice(i, 1)
                this.currentMessages.push(mjFetchResult)
                this.scrollToBottomDelay(50)
                break
              }
            }
            
      }
    },
    async messageDeleteRequest () {
      const deleteMessageID = this.hintModalInfo.message_id
      const res = await deleteMessage(this.token, this.hintModalInfo.message_id)
      console.log("delete...deleteMessageID..." + deleteMessageID)
      console.log("delete...this.mjFetchMessageID..." + this.mjFetchMessageID)
      if(deleteMessageID == this.mjFetchMessageID) {
        this.stopFetchMJ()
        this.mjFetchMessageID = -1
      }
      if (res.data.code === 0) {
        this.$Message.success('删除消息成功！')
      } else {
        this.$Message.error('删除消息失败：' + res.data.message)
      }
      this.messageListRequest(this.currentTopic.id)
    },
    async messageListRequest (topicID) {
      const res = await getMessageList(this.token, topicID, 1, 100)
      if (res.data.code === 0) {
        if (res.data.records.length > 0) {
          let messages = getMJListResult(res.data.records)
          this.currentMessages = messages
          this.scrollToBottomDelay(50)
        } else {
          this.currentMessages = []
          //this.stopFetchMJ()
        }
      }
      this.restartMJFetch()
    },
    restartMJFetch() {
      console.log("restartMJFetch...")
      if(this.currentMessages.length > 0) {
        //for(let i=0;i<this.currentMessages.length;i++) {
          //最后一条消息如果没有结果，重新开始轮询
          let cMessage = this.currentMessages[this.currentMessages.length - 1]
          if(cMessage.mj_status == null || cMessage.mj_status == undefined || (cMessage.mj_status && cMessage.mj_status < 4)) {
            console.log("restartMJFetch...in...")
            console.log(cMessage)
            this.startFetchMJ(cMessage.id, cMessage.mj_task_uuid, cMessage.mj_action)
            return
          }
        //}
      }
    },
    //请求文生图
    async mjTextCreateImageRequest(topicID, newMsg, prompt_cn, botId) {
      //console.log("chatSettings..." + this.chatSettings.model + "," + this.chatSettings.temperature);
      this.requesting = true
      const res = await addMJMessage(this.token, topicID, newMsg, prompt_cn, botId)
      this.requesting = false
      this.userInfoRequest()
      if(res === null || res.data === null) {
        this.$Message.error('文生图失败!')
        return
      }
      if(res.data.code !== 0) {
        this.$Message.error('文生图失败!' + res.data.message)
        return
      }
      //id
      //chat_type
      //isUser
      //mj_action
      //mj_botId
      //mj_prompt
      //mj_task_uuid
      //topic_id
      //user_id
      //create_time
      if (res.data.data.mj_prompt) {
        let msg2 = copyObj(res.data.data)
        msg2.isUser = true
        msg2.timeStr = getTimeStr(msg2.create_time)
        this.currentMessages.push(msg2)
      }
      if (res.data.data.mj_task_uuid) {
        let msg = copyObj(res.data.data)
        msg.isUser = false
        msg.timeStr = getTimeStr(msg.create_time)
        this.currentMessages.push(msg)
        this.startFetchMJ(res.data.data.id, res.data.data.mj_task_uuid, res.data.data.mj_action)
      }
      this.scrollToBottomDelay(50)
    },
    //请求图生图
    async mjImageCreateImageRequest(topicID, newMsg, prompt_cn, botId, base64, file_name, url, iw) {
      //console.log("chatSettings..." + this.chatSettings.model + "," + this.chatSettings.temperature);
      this.requesting = true
      console.log('url --- ' + url)
      const res = await imageCreateImage(this.token, topicID, newMsg, prompt_cn, botId, base64, file_name, url, iw)
      this.requesting = false
      this.userInfoRequest()
      if(res === null || res.data === null) {
        this.$Message.error('图生图失败!')
        return
      }
      if(res.data.code !== 0) {
        this.$Message.error('图生图失败!' + res.data.message)
        return
      }
      if (res.data.data.mj_prompt) {
        let msg2 = copyObj(res.data.data)
        msg2.isUser = true
        msg2.mj_labelStr = newMsg
        if(url) {
          msg2.sourceUrl1 = url
        } else {
          msg2.sourceUrl1 = base64
        }
        msg2.timeStr = getTimeStr(msg2.create_time)
        this.currentMessages.push(msg2)
      }
      if (res.data.data.mj_task_uuid) {
        let msg = copyObj(res.data.data)
        msg.isUser = false
        msg.timeStr = getTimeStr(msg.create_time)
        this.currentMessages.push(msg)
        this.startFetchMJ(res.data.data.id, res.data.data.mj_task_uuid, res.data.data.mj_action)
      }
      this.scrollToBottomDelay(50)
    },
    //请求混图
    async mjImageBlendRequest(topicID, base64, file_name, base64_2, file_name_2) {
      this.requesting = true
      const res = await imgBlend(this.token, topicID, base64, file_name, base64_2, file_name_2)
      this.requesting = false
      this.userInfoRequest()
      if(res === null || res.data === null || res.data.code !== 0) {
        this.$Message.error('混图失败!')
        return;
      }
       //添加用户发送
       if (res.data.data.mj_action) {
        let msg2 = copyObj(res.data.data)
        msg2.isUser = true
        msg2.timeStr = getTimeStr(msg2.create_time)
        msg2.sourceUrl1 = base64
        msg2.sourceUrl2 = base64_2
        this.currentMessages.push(msg2)
      }
      //添加MJ回复
      if (res.data.data.mj_task_uuid) {
        let msg = copyObj(res.data.data)
        msg.isUser = false
        msg.timeStr = getTimeStr(msg.create_time)
        this.currentMessages.push(msg)
        this.startFetchMJ(res.data.data.id, res.data.data.mj_task_uuid, res.data.data.mj_action)
      }
      this.scrollToBottomDelay(50)
    },
    //请求图生文
    async mjImageCreateTextRequest(topicID, base64, file_name) {
      this.requesting = true
      const res = await imgCreateText(this.token, topicID, base64, file_name)
      this.requesting = false
      this.userInfoRequest()
      if(res === null || res.data === null || res.data.code !== 0) {
        this.$Message.error('图生文失败!')
        return;
      }
      //添加用户发送
      if (res.data.data.mj_action) {
        let msg2 = copyObj(res.data.data)
        msg2.isUser = true
        msg2.timeStr = getTimeStr(msg2.create_time)
        msg2.sourceUrl1 = base64
        this.currentMessages.push(msg2)
      }
      //添加MJ回复
      if (res.data.data.mj_task_uuid) {
        let msg = copyObj(res.data.data)
        msg.isUser = false
        msg.timeStr = getTimeStr(msg.create_time)
        this.currentMessages.push(msg)
        this.startFetchMJ(res.data.data.id, res.data.data.mj_task_uuid, res.data.data.mj_action)
      }
      this.scrollToBottomDelay(50)
    },
    scrollToBottomDelay(delayTime) {
      let currentTimeStamp = getCurrentTimeStamp()
      //if (currentTimeStamp - this.lastScrollTime > 1) {
        this.lastScrollTime = currentTimeStamp
        setTimeout(this.scrollToBottom, delayTime)
      //}
    },
    scrollToBottom() {
      if(this.$refs.mjchatbox) {
        this.$refs.mjchatbox.scrollTop = this.$refs.mjchatbox.scrollHeight;
        //console.log("this.$refs.mjchatbox.scrollTop..." + this.$refs.mjchatbox.scrollTop)
      }
    },
    handleEnd() {
      this.targetTime = new Date().getTime() + 20000
    },
    startFetchMJ(message_id, uuid, mj_action) {
      console.log("startFetchMJ..." + uuid + "," + mj_action)
      this.targetTime = new Date().getTime() + 120000
      this.mjFetchMessageID = message_id
      console.log("this.mjFetchMessageID..." + message_id)
      this.mjFetchUuid = uuid
      this.mjFetching = true
      this.mjFetchInterval = setInterval(() => {
        this.mjResultRequest(message_id, uuid, mj_action)
      }, 3000)
    },
    stopFetchMJ() {
      console.log("stopFetchMJ...")
      if(this.mjFetchInterval) {
        clearInterval(this.mjFetchInterval)
      }
      this.mjFetchUuid = ""
      this.mjFetching = false
      this.mjFetchCount = 120
    }, 
    //修改对话名称
    onTopicNameModifyed (id, title) {
      if (id == this.currentTopic.id) {
        this.currentTopic.title = title
      }
    },
    //清空对话
    onTopicsClean() {
      this.currentTopic = {id: -1}
      this.currentMessages = []
    },
    //清空消息
    onTopicClearMessage (id) {
      if (id == this.currentTopic.id) {
        this.currentMessages = []
      }
    },
    //选中对话
    onTopicSelected(topic) {
      if(this.currentTopic && topic.id == this.currentTopic.id) {
        return
      }
      this.wheeled = false
      this.stopFetchMJ()
      this.currentTopic = topic
      this.currentMessages = topic.messages
      if (topic.messages.length == 0) {
        this.messageListRequest(topic.id)
      } else {
        this.scrollToBottomDelay(50)
      }
    },
    //文生图
    onTextCreateImage(data) {
      this.optionDrawer = false
      if (this.currentTopic.id == -1) {
        this.$Message.warning("请先建一个对话吧")
        return
      }
      console.log("onTextCreateImage..........")
      console.log(data)
      this.wheeled = false
      if(this.mjFetching == true) {
        this.$Message.warning("请等待当前任务完成后再继续操作")
        return
      }
      this.mjTextCreateImageRequest(this.currentTopic.id, data.prompt, data.prompt_cn, data.botId)
    },
    //图生图
    onImageCreateImage(data) {
      this.optionDrawer = false
      if (this.currentTopic.id == -1) {
        this.$Message.warning("请先建一个对话吧")
        return
      }
      console.log("onImageCreateImage..........")
      console.log(data)
      this.wheeled = false
      if(this.mjFetching == true) {
        this.$Message.warning("请等待当前任务完成后再继续操作")
        return
      }
      this.mjImageCreateImageRequest(this.currentTopic.id, data.prompt, data.prompt_cn, data.botId, data.base64, data.file_name, data.url, data.iw)
    },
    //图生文
    onImageCreateText(base64, file_name) {
      this.wheeled = false
      if(this.mjFetching == true) {
        this.$Message.warning("请等待当前任务完成后再继续操作")
        return
      }
      this.mjImageCreateTextRequest(this.currentTopic.id, base64, file_name)
    },
    //混图
    onBlend(data) {
      this.wheeled = false
      if(this.mjFetching == true) {
        this.$Message.warning("请等待当前任务完成后再继续操作")
        return
      }
      this.mjImageBlendRequest(this.currentTopic.id, data.file0.base64, data.file0.file_name, data.file1.base64, data.file1.file_name)
    },
    //图片加载成功
    onImageLoaded() {
      if(this.wheeled) {
        return
      }
      this.scrollToBottomDelay(20)
    },
    //鼠标滚轮事件
    onChatBoxMouseWheel() {
      //("onChatBoxMouseWheel.........")
      this.wheeled = true
    },
    //没有对话时创建对话
    noChatCreateClick() {
      emitter.emit('requestCreateNewChat', '1')
    },
    //没有登录时登录
    noChatLoginClick() {
      emitter.emit('requestLogin', '1')
    },
    onMenuSelected(name) {
      this.currentMenu = name
    },
    handleResize() {
      //获取屏幕长宽
      let screenWidth = document.documentElement.clientWidth
      let screeHeight = document.documentElement.clientHeight
      //console.log("screenWidth..." + screenWidth + ", screeHeight..." + screeHeight)
      if(screenWidth / screeHeight < 1) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      //console.log("this.isMobile === " + this.isMobile)
    }
  },
  created() {
    this.lightmode = this.$store.lightmode
    this.token = localStorage.getItem('token')
    if(this.token && this.token != "") {
      this.isLogin = true
      this.userInfoRequest()
    }
    this.avatar = localStorage.getItem('avatar')
    if(this.avatar == undefined || this.avatar == 'undefined' || this.avatar == null || this.avatar == 'null') {
      this.avatar = ''
    }
  },
  beforeUnmount () {
    this.stopFetchMJ()
    emitter.off('loginsuccess')
    emitter.off('loginsuccess-mobile')
    emitter.off('textCreateImage')
    emitter.off('imageCreateImage')
    emitter.off('imageCreateText')
    emitter.off('blend')
    emitter.off('chagelightmode')
    emitter.off('topiclistchange')
  },
  mounted() {
    emitter.on('loginsuccess', (msg) => {
      if (msg === '1') {
        this.isLogin = true
        this.token = localStorage.getItem('token');
      } else {
        this.isLogin = false
        this.token = ''
      }
    })
    emitter.on('loginsuccess-mobile', (msg) => {
      if (msg === '1') {
        this.isLogin = true
        this.token = localStorage.getItem('token');
      } else {
        this.isLogin = false
        this.token = ''
      }
    })
    emitter.on('textCreateImage', (data) => {
      this.onTextCreateImage(data)
    })
    emitter.on('imageCreateImage', (data) => {
      this.onImageCreateImage(data)
    })
    emitter.on('imageCreateText', (data) => {
      this.onImageCreateText(data.base64, data.file_name)
    })
    emitter.on('blend', (data) => {
      this.onBlend(data)
    })
    emitter.on('chagelightmode', (mode) => {
      this.lightmode = mode
    })
    emitter.on('topiclistchange', (data) => {
      if(data.direct == 'clearMsg') {
        this.onTopicClearMessage(data.topicID)
      } else if(data.direct == 'clearTopics') {
        this.onTopicsClean()
      } else if(data.direct == 'selectTopic') {
        this.onTopicSelected(data.topic)
      } else if(data.direct == 'topicNameModifyed') {
        this.onTopicNameModifyed(data.topicID, data.topicName)
      }
    })
  },
  computed: {
    mjMaxWidth: function() {
      if(this.isMobile) {
        return '60%'
      } else {
        return '40%'
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';
.mj-home {
  display: flex;
  height: 100%;
  .chat-list {
    flex: 1;
    min-width: 200px;
  }
  .config {
    flex: 1.5;
    min-width: 250px;
  }
  .chat-back {
    flex: 6;
    display: flex;
    flex-direction: column;
    .chat-top {
      height: 50px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #E0E0E0;
      padding-left: 10px;
      padding-right: 10px;
      .topicname {
        //color: black;
        display: flex;
        align-items: center; //flex高度居中方式
        .token-left {
          margin-left: 20px;
          margin-right: 5px;
        }
      }
    }
    .chat-split {
      height: 1px;
      background-color: #bbbbbb;
    }
    .chat-content {
      padding-left: 2%;
      padding-right: 2%;
      flex: 1;
      overflow: scroll;
    }
    .chat-seizeseat {
      height: 20px;
    }
  }
}

.user-send {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .leftseizeseat {
    width: 50px;
    min-width: 50px;
  }
  .right {
    //background-color: yellow;
    display: flex;
    flex-direction: row;

    // .mj-label {
    //   font-weight: bolder;
    //   font-size: 1.2rem;
    // }
    // .msg-text {
    //   opacity: 1;
    //   color: #181818;
    // }
    
    .user-msg-bigback {
      text-align: right;
      .user-msg-back {
        padding: 10px;
        border-radius: 5px;
        display: inline-block;
        text-align: left;
        background-color: rgba(8, 193, 96, 0.4);
        .mj-label {
          font-weight: bolder;
          font-size: 1.2rem;
        }
        .msg-text {
          opacity: 1;
          color: #181818;
        }
      }
      .user-img-back {
        padding: 10px;
        border-radius: 5px;
        display: inline-block;
        text-align: right;
      }
      .user-time-back {
        margin-bottom: 2px;
      }
    }
    
    .user-head {
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
      margin-left: 5px;
      background-color: #bde4b5;
    }
  }
}

.mj-reply {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .left {
    display: flex;
    flex-direction: row;
    .mj-msg-bigback {
      text-align: left;
      .mj-msg-back {
        //padding: 10px;
        border-radius: 5px;
        display: inline-block;
        text-align: left;
        margin-right: 10px;
      }
      .mj-trans-back {
        display: flex;
        align-items: center;
        margin-top: 6px;
      }
      .mj-time-back {
        margin-bottom: 2px;
      }
    }
    .mj-head {
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
      margin-right: 5px;
      background-color: #5023aa;
    }
  }
  .rightseizeseat {
    width: 50px;
    min-width: 50px;
  }
}

.option-back {
  height: 100%;
  border-right: 1px solid #111111;
  //background-color: #F7F7F7;;
  padding-left: 10px;
  padding-right: 10px;
}

.dark-option-bg {
  background-color: #202020;
  color: white;
}
.day-option-bg {
  background-color: #F7F7F7;
  color: #181818;
  border-right: 1px solid #ccc;
}

.mj-Image {
  //max-width: 40%;
  min-width: 100px;
}

.trans-title {
  font-weight: bold;
  white-space: nowrap;
}
.trans-btn {
  white-space: no-wrap;
  margin-left: 10px;
}

/deep/.ivu-btn-success {
  background-color: #08C160;
}

::-webkit-scrollbar {
  width: 5px;
  height: 0px; //水平的隐藏
}
//背景
::-webkit-scrollbar-track {
  background-color: #F3F3F3;
}
//滑块
::-webkit-scrollbar-thumb {
  background-color: #cccccc;
}

.msg-tool-btn {
  margin-left: 10px;
}

.chat-time-right {
  color: #ADADAD;
  font-size: 0.8rem;
  opacity: 1;
  margin-left: 10px;
}

.chat-time-left {
  color: #ADADAD;
  font-size: 0.8rem;
  opacity: 1;
}

.uhead {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #08C160;
  aspect-ratio: 1/1;
  margin-left: 5px;
  margin-right: 5px;
}

/deep/.ivu-input {
  background-color: #F7F7F7;
}

// .menu {
//   margin-left: 20px;
//   margin-right: 20px;
// }
.menu-content-bg {
  padding-top: 10px;
}

.vary-input-bg {
  border: #aaaaaa 1px solid;
}
.vary-tran-bg {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 20px;
}

</style>

<template>
  <div class="bigback" :class="lightmode == 'day' ? 'day-normal-big-bg' : 'dark-normal-big-bg'" v-resize="handleResize">
    <div class="title">画廊</div>
    <Menu class="menu" mode="horizontal" active-name="1" @on-select="onMenuSelected">
        <MenuItem name="1">
          <!-- <Icon type="ios-people" /> -->
          全部
        </MenuItem>
        <MenuItem name="2">
          <!-- <Icon type="md-body" /> -->
          我的精选
        </MenuItem>
    </Menu>
    <div class="no-image" :class="lightmode == 'day' ? 'day-normal-big-bg' : 'dark-normal-big-bg'" v-if="imageList.length==0">
      <h1>画廊空空如也~</h1>
    </div>
    <div class="images-back" :class="lightmode == 'day' ? 'day-normal-big-bg' : 'dark-normal-big-bg'" v-if="my_gallery==1 && imageList.length>0">
      <!-- <Image v-if="message.mj_image_url" :src="message.mj_image_url" fit="contain"  class="mjImage" preview :preview-list="[message.mj_image_url]" :initial-index="index" /> -->
      <!-- <div class="image-little-back">
        <Image src="https://ai.pnxuetang.com/mj_image/undefined/efbdbe06-92a8-9cf6-830b-d496810a0141.png" fit="fill"  class="image-item" preview :preview-list="['https://ai.pnxuetang.com/mj_image/undefined/efbdbe06-92a8-9cf6-830b-d496810a0141.png']" :initial-index="index" />
        dsfds
      </div>
      <div class="image-little-back">
        <Image src="https://ai.pnxuetang.com/mj_image/undefined/efbdbe06-92a8-9cf6-830b-d496810a0141.png" fit="contain"  class="image-item" preview :preview-list="['https://ai.pnxuetang.com/mj_image/undefined/efbdbe06-92a8-9cf6-830b-d496810a0141.png']" :initial-index="index" />
      </div>
      <div class="image-little-back">
        <Image src="https://ai.pnxuetang.com/mj_image/undefined/efbdbe06-92a8-9cf6-830b-d496810a0141.png" fit="cover"  class="image-item" preview :preview-list="['https://ai.pnxuetang.com/mj_image/undefined/efbdbe06-92a8-9cf6-830b-d496810a0141.png']" :initial-index="index" />
      </div>
      <div class="image-little-back">
        <Image src="https://ai.pnxuetang.com/mj_image/undefined/efbdbe06-92a8-9cf6-830b-d496810a0141.png" fit="none"  class="image-item" preview :preview-list="['https://ai.pnxuetang.com/mj_image/undefined/efbdbe06-92a8-9cf6-830b-d496810a0141.png']" :initial-index="index" />
      </div>
      <div class="image-little-back">
        <Image src="https://ai.pnxuetang.com/mj_image/undefined/efbdbe06-92a8-9cf6-830b-d496810a0141.png" fit="scale-down"  class="image-item" preview :preview-list="['https://ai.pnxuetang.com/mj_image/undefined/efbdbe06-92a8-9cf6-830b-d496810a0141.png']" :initial-index="index" />
      </div> -->
      <div :class="isMobile ? 'image-little-back-mobile' : 'image-little-back'" v-for="(item, index) in imageList" :key="item.message_id">
        <Image :src="item.mj_image_url" fit="cover"  class="image-item" preview :preview-list="imageUrlList" :initial-index="index" />
        <!-- <div>提示词：{{ item.mj_prompt }}</div> -->
        <div class="tools-back">
          <div>
            <!-- <span>{{ item.stateStr }}</span> -->
            <Tooltip max-width="200" :content="'审核状态：' + item.stateStr">
              <Icon :style="{color: shenHeColor(item.state)}" type="md-cloud-upload" size="20"/>
            </Tooltip>
            <Tooltip max-width="200" :content="'提示词：' + item.mj_prompt + '\n中文提示词：' + (item.mj_prompt_cn == null ? '无' : item.mj_prompt_cn)">
              <Icon type="ios-copy" class="pointer option-btn" size="20" @click="onCopyPrompt(item.mj_prompt)"/>
            </Tooltip>
            <Tooltip max-width="200" class="pointer option-btn" content="删除">
              <Icon type="ios-trash" color="#ff6633" size="20"  @click="showHintModal(item.id, 'delete')"/>
            </Tooltip>
          </div>
          <span>{{ item.timeStr }}</span>
        </div>
      </div>
      <div class="page-back" v-if="ipagination.current != 1 || imageList.length == ipagination.pagesize">
        <Page :total="ipagination.total" :page-size="ipagination.pagesize" class="page" @on-change="changePage" show-total></Page>
      </div>
    </div>
    <div class="images-back" :class="lightmode == 'day' ? 'day-normal-big-bg' : 'dark-normal-big-bg'" v-if="my_gallery==0 && imageList.length>0">
      <div :class="isMobile ? 'image-little-back-mobile' : 'image-little-back'" v-for="(item, index) in imageList" :key="item.message_id">
        <Image :src="item.mj_image_url" fit="cover"  class="image-item" preview :preview-list="imageUrlList" :initial-index="index" />
        <div class="tools-back">
          <div>
            <Tooltip max-width="200" :content="'提示词：' + item.mj_prompt + '\n中文提示词：' + (item.mj_prompt_cn == null ? '无' : item.mj_prompt_cn)">
              <Icon style="margin-left: 0px;" type="ios-copy" class="pointer option-btn" size="20" @click="onCopyPrompt(item.mj_prompt)"/>
            </Tooltip>
          </div>
          <span style="margin-left: 0px;">{{ item.timeStr }}</span>
        </div>
      </div>
      <div class="page-back" v-if="ipagination.current != 1 || imageList.length == ipagination.pagesize">
        <Page :total="ipagination.total" :page-size="ipagination.pagesize" class="page" @on-change="changePage" show-total></Page>
      </div>
    </div>
    
    <Modal
        v-model="hintModal"
        :title="hintModalInfo.title"
        footer-hide>
        <p style="margin-bottom: 30px;">{{ hintModalInfo.content }}</p>
        <div class="modal-footer-btn-back">
          <Button class="modal-footer-btn" @click="handlerOk" type="success">确定</Button>
          <Button class="modal-footer-btn" @click="handlerCancel">取消</Button>
        </div>
    </Modal>
  </div>
</template>
<script>
import { getGalleryImageListAPI, deleteGalleryMessageAPI } from '@/api/GalleryAPI.js'
import emitter from '@/hooks/bus.js'
// import { copyObj } from '@/utils/deepcopy.js'
import { getTimeStrWithoutSeconds } from "@/utils/timeutil.js"
import clipboard from 'clipboard'
export default {
  data() {
    return {
      lightmode: 'day',
      token: "",
      isLogin: false,
      isMobile: false,
      my_gallery: 0,
      ipagination: {
        current: 1,
        total: 0,
        pagesize: 20
      },
      imageList: [],
      imageUrlList: [],
      hintModal: false,
      hintModalInfo: {
        id: -1,
        target: 'shenhe' //shenhe-审核，delete-删除
      },
    }
  },
  methods: {
    async requestDeleteImage() {
      const res = await deleteGalleryMessageAPI(this.token, this.hintModalInfo.id)
      if(res.data.code == 0) {
        this.$Message.success('删除成功')
        this.requestGalleryImageList()
      }
    },
    async requestGalleryImageList() {
      const res = await getGalleryImageListAPI(this.token, this.my_gallery, this.ipagination.current, this.ipagination.pagesize)
      console.log(res)
      if (res.data.code === 0) {
        //this.sessions = res.data.records
        let iUrls = []
        let images = []
        res.data.records.forEach(element => {
          iUrls.push(element.mj_image_url)
          element.timeStr = getTimeStrWithoutSeconds(element.create_time)
          if(element.state == 0) {
            element.stateStr = "待审核"
          } else if(element.state == 1) {
            element.stateStr = "审核通过"
          } else if(element.state == 2) {
            element.stateStr = "审核失败"
          }
          images.push(element)
        });
        this.imageUrlList = iUrls
        //this.imageList = res.data.records
        this.imageList = images
        this.ipagination.total = res.data.total
        console.log("this.imageList..." + this.imageList.length)
      }
    },
    changePage (val) {
      this.ipagination.current = val
      this.requestGalleryImageList()
    },
    onMenuSelected(name) {
      console.log("onMenuSelected..." + name)
      if(name == '1') {
        if(this.my_gallery == 0) {
          return
        }
        this.my_gallery = 0
        this.ipagination.current = 1
        this.ipagination.total = 0
        this.requestGalleryImageList()
      } else if(name == '2') {
        if(this.my_gallery == 1) {
          return
        }
        this.my_gallery = 1
        this.ipagination.current = 1
        this.ipagination.total = 0
        this.requestGalleryImageList()
      }
    },
    onCopyPrompt(mj_prompt) {
      clipboard.copy(mj_prompt)
      this.$Message.success('已复制到剪切板')
    },
    showHintModal(id, target) {
      this.hintModalInfo = {
        id,
        target
      }
      if (target === 'shenhe') {
        this.hintModalInfo.title = "提交审核"
        this.hintModalInfo.content = "确定要提交审核吗？"
      } else if (target === 'delete') {
        this.hintModalInfo.title = "删除"
        this.hintModalInfo.content = "确定要删除此张图片吗？"
      }
      this.hintModal = true
    },
    handlerOk() {
      if (this.hintModalInfo.target === 'shenhe') {
        //this.mjChangesRequest()
      } else if (this.hintModalInfo.target == 'delete') {
        this.requestDeleteImage()
      }
      this.hintModal = false
    },
    handlerCancel() {
      this.hintModal = false
    },
    handleResize() {
      //获取屏幕长宽
      let screenWidth = document.documentElement.clientWidth
      let screeHeight = document.documentElement.clientHeight
      console.log("screenWidth..." + screenWidth + ", screeHeight..." + screeHeight)
      if(screenWidth / screeHeight < 1) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      console.log("this.isMobile === " + this.isMobile)
    }
  },
  created() {
    this.lightmode = this.$store.lightmode
    this.token = localStorage.getItem('token');
    if(this.token && this.token != "") {
      this.isLogin = true
      this.requestGalleryImageList()
    }
    emitter.on('chagelightmode', (mode) => {
      this.lightmode = mode
    })
  },
  beforeUnmount() {
    emitter.off('loginsuccess')
    emitter.off('chagelightmode')
  },
  mounted() {
    emitter.on('loginsuccess', (msg) => {
      console.log('GalleryHome loginsuccess..' + msg)
      if (msg === '1') {
        this.isLogin = true
        this.token = localStorage.getItem('token');
      } else {
        this.isLogin = false
        this.token = ''
      }
    })
  },
  computed: {
    shenHeColor() {
      return function(state) {
        if(state == 1) {
          return "#00cc66"
        } else if(state == 2) {
          return "#ff0000"
        }
        return "#333333"
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';

.bigback {
  //background-color: red;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;

  .title {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 1.3rem;
    font-weight: bolder;
  }

  .menu {
    width: 100%;
  }
  .no-image {
    //background-color: aqua;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    h1 {
      font-weight: bolder;
      font-size: 2rem;
    }
  }

  .images-back {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: scroll;
    align-content: flex-start; //解决flex布局换行后出现间隙问题

    .image-little-back {
      width: 20%;
      height: 65%;
      //border: 1px solid #ccc;
      padding: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      
      .image-item {
        flex: 1;
        overflow: hidden;
      }

      .tools-back {
        margin-top: auto;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
      }
    }

    .image-little-back-mobile {
      width: 50%;
      height: 50%;
      //border: 1px solid #ccc;
      padding: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      
      .image-item {
        flex: 1;
        overflow: hidden;
      }

      .tools-back {
        margin-top: auto;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
  height: 0px; //水平的隐藏
}
// //背景
// ::-webkit-scrollbar-track {
//   background-color: #F7F7F7;
// }
// //滑块
// ::-webkit-scrollbar-thumb {
//   background-color: #cccccc;
// }

.page-back {
  width: 100%;
  display: block;
  margin-top: 50px;
  text-align: center;
}

.option-btn {
  margin-left: 10px;
}

/deep/.ivu-image-img {
  border-radius: 10px;
}

/deep/.ivu-image-mark {
  border-radius: 10px;
}
</style>
<template>
  <div class="bigback" :class="lightmode == 'day' ? 'day-normal-big-bg' : 'dark-normal-big-bg'">
    <div class="title">公告</div>
    <div class="content">
      <div v-for="item in noticeList" :key="item.id" class="content-item"
        :class="lightmode=='day' ? 'day-content-item' : 'dark-content-item'"
        @click="noticeClick(item)">
        <span class="notice-title">{{ item.title }}</span>
        <span class="notice-time">{{ item.timeStr }}</span>
      </div>
    </div>
    <Modal v-model="showModal" footer-hide>
      <div class="modal-back">
        <h1 class="modal-title">{{ modalContent.title }}</h1>
        <div class="modal-content">{{ modalContent.body }}</div>
        <div class="modal-time">{{ modalContent.timeStr }}</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getNoticeListAPI } from '@/api/NoticeAPI.js'
import { getTimeStr2 } from "@/utils/timeutil.js"
import emitter from '@/hooks/bus.js'
export default {
  data() {
    return {
      lightmode: 'day',
      isLogin: false,
      noticeList: [],
      showModal: false,
      modalContent: {
        title: '',
        body: '',
        timeStr: ''
      }
    }
  },
  methods: {
    async noticeListRequest () {
      const res = await getNoticeListAPI(this.token, 1, 100)
      let nList = []
      res.data.records.forEach(element => {
        element.timeStr = getTimeStr2(element.create_time)
        nList.push(element)
      });
      this.noticeList = nList
    },
    noticeClick(item) {
      console.log(item)
      this.modalContent = item
      this.showModal = true
    }
  },
  created() {
    this.lightmode = this.$store.lightmode
    this.token = localStorage.getItem('token')
    if(this.token && this.token != "") {
      this.noticeListRequest()
    }
    emitter.on('chagelightmode', (mode) => {
      this.lightmode = mode
    })
  },
  beforeUnmount() {
    //emitter.off('loginsuccess');
    emitter.off('chagelightmode')
  },
  mounted() {
    // emitter.on('loginsuccess', (msg) => {
    //   console.log('ImageHome loginsuccess..' + msg)
    //   if (msg === '1') {
    //     this.isLogin = true
    //     this.token = localStorage.getItem('token');
    //     //this.topicListRequest(true)
    //     this.userInfoRequest()
    //   } else {
    //     this.isLogin = false
    //     this.token = ''
    //   }
    // })
  }
}
</script>

<style lang="less" scoped>
.bigback {
  //background-color: red;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;

  .title {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 1.3rem;
    font-weight: bolder;
  }

  .content {
    //background-color: #5023aa;
    margin-top: 20px;
    margin-right: 50px;
    .content-item {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      padding-bottom: 10px;
      align-items: center;
      //border-bottom: 1px solid #E0E0E0;
      cursor: pointer;

      .notice-title {
        font-weight: bold;
      }
    }
    .dark-content-item {
      border-bottom: 1px solid #111111;
    }
    .day-content-item {
      border-bottom: 1px solid #E0E0E0;
    }
  }
}

.modal-back {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  .modal-title {
    text-align: center;
    font-weight: bolder;
    margin-bottom: 30px;
    color: #181818;
    margin-top: 10px;
    font-size: 1.2rem;
  }

  .modal-content {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    height: 50px;
    color: #181818;
  }

  .modal-time {
    margin-top: 10px;
    text-align: right;
    color: #181818;
  }
}
</style>
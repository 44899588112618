<!--MJ绘图-->
<template>
  <div class="big-back" :class="lightmode=='day'?'day-bg':'dark-bg'">
    <h1 class="child-title">AI将根据提示词生成图片</h1>
    <section class="mb-4 mt-3">
        <div class="mr-1  mb-2 flex justify-between items-center">
            <div class="text-sm">
                图片比例
                <Tooltip max-width="200" content="生成图片的宽高比例" placement="right">
                    <Icon size="18" type="ios-alert-outline" />
                </Tooltip>
            </div>
        </div>
        <div class="flex items-center justify-between space-x-1">
            <template v-for="(item,index) in imageRatio" :key="index">
              <section class="aspect-item flex-1 rounded border-2 dark:border-neutral-700 cursor-pointer"  :class="{'active':index==f.bili}"  @click="f.bili=index">
                  <div class="aspect-box-wrapper mx-auto my-2 flex h-5 w-5 items-center justify-center">
                      <div class="aspect-box rounded border-2 dark:border-neutral-700" :style="item.s"></div>
                  </div>
                  <p class="mb-1 text-center text-sm">{{ item.label }}</p>
              </section>
            </template>
        </div>
    </section>
    <div :class="lightmode=='day'?'day-select-deep':'dark-select-deep'">
        <div class="option-item">
            <span>画质</span>
            <Select v-model="quality" style="width:150px" clearable>
                <Option v-for="item in qualityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
        </div>
        <div class="option-item">
            <span>风格</span>
            <Select v-model="style" style="width:150px" clearable>
                <Option v-for="item in styleList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
        </div>
        <div class="option-item">
            <span>视角</span>
            <Select v-model="view" style="width:150px" clearable>
                <Option v-for="item in viewList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
        </div>
        <div class="option-item">
            <span>人物镜头</span>
            <Select v-model="shot" style="width:150px" clearable>
                <Option v-for="item in shotList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
        </div>
        <div class="option-item">
            <span>灯光</span>
            <Select v-model="light" style="width:150px" clearable>
                <Option v-for="item in lightList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
        </div>
        <div class="option-item">
            <span>艺术程度</span>
            <Select v-model="styles" style="width:150px" clearable>
                <Option v-for="item in stylesList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
        </div>
        <div class="option-item">
            <span>模型</span>
            <Select v-model="botId" style="width:150px" clearable>
                <Option v-for="item in botIdList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
        </div>
        <div>
            <div style="margin-top: 20px;margin-bottom: 5px;">
                参考图片
                <Tooltip max-width="200" content="AI将根据参考图片生成相似的图片，相似度可在下方设置" placement="right">
                    <Icon size="18" type="ios-alert-outline" />
                </Tooltip>
            </div>          
            <div class="new-input-back2">
                <img class="new-input-img" v-if="file && file.base64.length > 0" @click="selectImgClicked" :src="file.base64"/>
                <img class="new-input-img" v-if="imageUrl && imageUrl.length > 0" @click="selectImgClicked" :src="imageUrl"/>
                <div v-if="(file == null || file.base64.length == 0) && (imageUrl == null || imageUrl.length == 0)" @click="selectImgClicked" :class="lightmode=='day'?'day-new-input-btn':'dark-new-input-btn'">选择图片</div>
                <!-- <span class="new-input-hint">{{ file.file_name }}</span> -->
                <div class="file-close-back">
                    <!-- v-if="file && file.base64.length > 0" -->
                    <Icon style="cursor: pointer;" size="25" v-if="(file && file.base64.length > 0) || (imageUrl && imageUrl.length > 0)" @click="deleteCurrentImage" type="md-close" />
                </div>
            </div>
            <input ref="file" class="native-input" type="file" v-on:change="selectFile" nullMsg="文件不能为空" id="fileInput" accept="image/*">
        </div>
        <div v-if="(file && file.base64.length > 0) || (imageUrl && imageUrl.length > 0)" class="option-item" style="margin-top: 0px;">
            <span>相似度</span>
            <Slider v-model="iw" style="width: 150px;" :min="0.1" :max="2.0" :step="0.1"></Slider>
            <!-- <Select v-model="botId" style="width:150px" clearable>
                <Option v-for="item in botIdList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select> -->
        </div>
    </div>

    <div :class="lightmode == 'day' ? 'day-normal-big-bg' : 'dark-normal-big-bg'" style="background-color: transparent;">
        <Input
            style="margin-bottom: 10px;"
            :class="lightmode=='day'?'day-input':'dark-input'" 
            :rows="3"
            class="send-input" 
            v-model="st.text"
            type="textarea"
            placeholder="请输入提示词（必填，请务必翻译成英文）" />
        <Input
            style="margin-bottom: 10px;"
            :class="lightmode=='day'?'day-input':'dark-input'" 
            :rows="3"
            class="send-input" 
            v-model="st.notext"
            type="textarea"
            placeholder="请输入不希望出现的事务（非必填）" />
      
     <!-- <n-input type="textarea"  v-model:value="st.notext"   placeholder="不希望出现的事物" round clearable maxlength="500" show-count 
      :autosize="{   minRows:2, maxRows:5 }" />
     <n-input style="margin-top: 10px;" type="textarea"  v-model:value="st.text"   placeholder="提示词" round clearable maxlength="500" show-count 
      :autosize="{   minRows:2, maxRows:5 }" /> -->
    </div>
    <div class="mb-4 flex justify-between items-center">
        <div class="btn-back">
            <div>
                <Button type="success" class="btn" @click="textLanguageTranslate">翻译</Button>
                <!-- <Icon size="20" type="ios-alert" /> -->
            </div>
            <Button type="success" class="btn" @click="textCreateImage">绘图</Button>
        </div>
    </div>
  </div>
</template>
<script>
import { languageTranslate } from '@/api/LanguageTranslateAPI.js'
import { EncodeUtf8 } from '@/utils/utf8util.js'
import emitter from '@/hooks/bus.js'

export default {
    props: {
        token: {
            type: String,
            required: true
        }
    },
  data() {
        return {
            file: {
                base64: '',
                file_name: '请选择图片'
            },
            iw: 1, //相似度
            lightmode: 'day',
            isMobile: false,
            quality: '', //画质
            style: '', //风格
            view: '', //视角
            shot: '', //人物镜头
            light: '', //灯光
            styles: '', //艺术程度  
            botId: 'MJBot', //风格2  允许值: "MJBot", "NijiBot"  默认风格,Niji偏重于二次元
            imageUrl: null,
            optionList: [
            { k:'style',v:'风格'},
            { k:'view',v:'视角'},
            { k:'shot',v:'人物镜头'},
            { k:'light',v:'灯光'},
            { k:'quality',v:'画质'},
            { k:'styles',v:'艺术程度'},
            { k:'version',v:'模型版本'}
            ],
            imageRatio: [
            {s:'width: 100%; height: 100%;',label:'1:1'},
            {s:'width: 100%; height: 75%;',label:'4:3'},
            {s:'width: 75%; height: 100%;',label:'3:4'},
            {s:'width: 100%; height: 50%;',label:'16:9'},
            {s:'width: 50%; height: 100%;',label:'9:16'}
            ],
            f: {
            bili:-1, 
            quality:'',
            view:'',
            light:'',
            shot:'',
            style:'',
            styles:'',
            referenceImage: '', //参考图片
            version:'--v 5.2'
            },
            st: {
                notext: '',
                text: '',
                fileBase64: '',
                prompt_cn: ''
            },
            qualityList: 
                [
                    {
                        "label": "一般",
                        "value": "0.25"
                    },
                    {
                        "label": "清晰",
                        "value": "0.5"
                    },
                    {
                        "label": "高清",
                        "value": "1"
                    },
                    {
                        "label": "超高清",
                        "value": "2"
                    }
                ],
            styleList: 
                [
                    {
                        "label": "赛博朋克",
                        "value": "Cyberpunk"
                    },
                    {
                        "label": "星际",
                        "value": "Warframe"
                    },
                    {
                        "label": "二次元",
                        "value": "ACGN"
                    },
                    {
                        "label": "日本漫画",
                        "value": "Japanese comics/manga"
                    },
                    {
                        "label": "水墨",
                        "value": "Ink Wash Painting Style"
                    },
                    {
                        "label": "原画风格",
                        "value": "Original"
                    },
                    {
                        "label": "山水画",
                        "value": "landscape"
                    },
                    {
                        "label": "插画",
                        "value": "illustration"
                    },
                    {
                        "label": "漫画",
                        "value": "Manga"
                    },
                    {
                        "label": "现代自然",
                        "value": "modern organic"
                    },
                    {
                        "label": "创世纪",
                        "value": "Genesis"
                    },
                    {
                        "label": "海报",
                        "value": "posterstyle"
                    },
                    {
                        "label": "超现实主义",
                        "value": "surrealism"
                    },
                    {
                        "label": "素描",
                        "value": "sketch"
                    },
                    {
                        "label": "写实",
                        "value": "realism"
                    },
                    {
                        "label": "水彩绘画",
                        "value": "Watercolor painting"
                    },
                    {
                        "label": "立体派",
                        "value": "Cubism"
                    },
                    {
                        "label": "黑白",
                        "value": "black and white"
                    },
                    {
                        "label": "电影摄影风格",
                        "value": "fm photography"
                    },
                    {
                        "label": "电影般的",
                        "value": "cinematic"
                    },
                    {
                        "label": "清晰的面部特征",
                        "value": "dlear facial features"
                    }
                ],
            viewList:
                [
                    {
                        "label": "宽景",
                        "value": "Wide view"
                    },
                    {
                        "label": "鸟瞰",
                        "value": "Bird view"
                    },
                    {
                        "label": "俯视",
                        "value": "Top view"
                    },
                    {
                        "label": "仰视",
                        "value": "Upview"
                    },
                    {
                        "label": "正面",
                        "value": "Front view"
                    },
                    {
                        "label": "头部特写",
                        "value": "Headshot"
                    },
                    {
                        "label": "超广角",
                        "value": "Ultrawideshot"
                    },
                    {
                        "label": "中景",
                        "value": "Medium Shot(MS)"
                    },
                    {
                        "label": "远景",
                        "value": "Long Shot(LS)"
                    },
                    {
                        "label": "景深",
                        "value": "depth offield(dof)"
                    }
                ],
            shotList:
                [
                    {
                        "label": "脸部特写",
                        "value": "Face Shot (VCU)"
                    },
                    {
                        "label": "头部以上",
                        "value": "Big Close-Up(BCU)"
                    },
                    {
                        "label": "颈部以上",
                        "value": "Close-Up(CU)"
                    },
                    {
                        "label": "腰部以上",
                        "value": "Waist Shot(WS)"
                    },
                    {
                        "label": "膝盖以上",
                        "value": "KneeShot(KS)"
                    },
                    {
                        "label": "全身",
                        "value": "Full Length Shot(FLS)"
                    },
                    {
                        "label": "人在远方",
                        "value": "Extra Long Shot(ELS)"
                    }
                ],
            stylesList:
                [
                    {
                        "label": "低",
                        "value": "--s 50"
                    },
                    {
                        "label": "中",
                        "value": "--s 100"
                    },
                    {
                        "label": "高",
                        "value": "--s 250"
                    },
                    {
                        "label": "极高",
                        "value": "--s 750"
                    }
                ],        
            lightList:
                [
                    {
                        "label": "冷光",
                        "value": "Cold light"
                    },
                    {
                        "label": "暖光",
                        "value": "Warm light"
                    },
                    {
                        "label": "强光",
                        "value": "hard lighting"
                    },
                    {
                        "label": "戏剧光",
                        "value": "Dramatic light"
                    },
                    {
                        "label": "反光",
                        "value": "reflection light"
                    },
                    {
                        "label": "雾气朦胧",
                        "value": "Misty foggy"
                    },
                    {
                        "label": "自然光",
                        "value": "Natural light"
                    },
                    {
                        "label": "太阳光",
                        "value": "Sun light"
                    },
                    {
                        "label": "暗黑的",
                        "value": "moody"
                    }
                ],
            versionList:
                [
                    {
                        "label": "MJ V5.2",
                        "value": "--v 5.2"
                    },
                    {
                        "label": "MJ V5.1",
                        "value": "--v 5.1" 
                    },
                    {
                        "label": "二次元 niji V5",
                        "value": "--niji 5"
                    },
                    {
                        "label": "二次元 niji V4",
                        "value": "--niji 4"
                    },
                    {
                        "label": "Niji・journey",
                        "value": "NIJI_JOURNEY"
                    }
                ],
            botList:
                [
                    {
                        "label": "Midjourney Bot",
                        "value": "MID_JOURNEY"
                    },
                    {
                        "label": "Niji・journey",
                        "value": "NIJI_JOURNEY"
                    }
                ],
            dimensionsList:
                [
                    {
                        "label": "广场 SQUARE(1:1)",
                        "value": "SQUARE"
                    },
                    {
                        "label": "肖像 PORTRAIT(2:3)",
                        "value": "PORTRAIT"  
                    },
                    {
                        "label": "景观 LANDSCAPE(3:2)",
                        "value": "LANDSCAPE"  
                    }
                ],
            botIdList:
                [
                    {
                        "label": "通用模型",
                        "value": "MJBot"
                    },
                    {
                        "label": "动漫风格",
                        "value": "NijiBot"  
                    }
                ]
        }
    },
    methods: {
        //--ar 1:1  图片比例
        textCreateImage() {
            console.log("textCreateImage..." + this.st.text + ",f.bili==" + this.f.bili)
            //this.$parent.onTextCreateImage(this.st.text)
            if (this.st.text == null || this.st.text.length == 0) {
                this.$Message.error("请输入提示词")
                return
            }
            let prompt = ""
            if(this.referenceImage && this.referenceImage.length > 0) {
                prompt = this.referenceImage + " " +  this.st.text
            } else {
                prompt = this.st.text
            }
            //quality: '', //画质
            //style: '', //风格
            //view: '', //视角
            //shot: '', //人物镜头
            //light: '', //灯光
            //styles: '', //艺术程度  
            console.log("quality=" + this.quality + ", style=" + this.style + ", view=" + this.view
            + ", shot=" + this.shot + ", light=" + this.light + ", styles=" + this.styles)
            
            if(this.style && this.style.length > 0) {
                prompt = prompt + " , " + this.style
            }
            if(this.view && this.view.length > 0) {
                prompt = prompt + " , " + this.view
            }
            if(this.shot && this.shot.length > 0) {
                prompt = prompt + " , " + this.shot
            }
            if(this.light && this.light.length > 0) {
                prompt = prompt + " , " + this.light
            }
            if(this.styles && this.styles.length > 0) {
                prompt = prompt + " " + this.styles
            }
            if(this.f.bili >= 0 && this.f.bili < this.imageRatio.length) {
                prompt = prompt + " --ar " + this.imageRatio[this.f.bili].label
            }
            if(this.quality && this.quality.length > 0) {
                prompt = prompt + " --q " + this.quality
            }
            if(this.st.notext && this.st.notext.length > 0) {
                prompt = prompt + " --no " + this.st.notext
            }
            console.log("prompt: " + prompt)
            if(this.st.prompt_cn.length == 0) {
                this.st.prompt_cn = '无'
            }
            if(this.file && this.file.base64 && this.file.base64.length > 0) {
                emitter.emit('imageCreateImage', {
                    prompt: prompt,
                    prompt_cn: this.st.prompt_cn,
                    botId: this.botId,
                    base64: this.file.base64,
                    file_name: this.file.file_name,
                    iw: this.iw
                })
            } else if(this.imageUrl && this.imageUrl.length > 0){
                emitter.emit('imageCreateImage', {
                    prompt: prompt,
                    prompt_cn: this.st.prompt_cn,
                    botId: this.botId,
                    url: this.imageUrl,
                    iw: this.iw
                })
            } else {
                emitter.emit('textCreateImage', {
                    prompt: prompt,
                    prompt_cn: this.st.prompt_cn,
                    botId: this.botId,
                    
                })
            }
            this.st.prompt = ''
            this.st.prompt_cn = ''
        },
        textLanguageTranslate() {
            console.log("textCreateImage..." + this.st.text)
            if(this.st.text.length > 0) {
                let utf8Str = EncodeUtf8(this.st.text)
                console.log("utf8Str === " + utf8Str)
                this.st.prompt_cn = this.st.text
                this.requestLanguageTranslate(utf8Str, 'yes')
                //this.requestLanguageTranslate("&#x4F60;&#x597D;")
            }
            if(this.st.notext.length > 0) {
                let noUtf8Str = EncodeUtf8(this.st.notext)
                console.log("noUtf8Str === " + noUtf8Str)
                this.requestLanguageTranslate(noUtf8Str, 'no')
            }
        },
        async requestLanguageTranslate(text, type) {
            const res = await languageTranslate(this.token, text)
            console.log(res)
            if(res.data.code == 0) {
                if(type == 'yes') {
                    this.st.text = res.data.target_text
                } else if(type =='no') {
                    this.st.notext = res.data.target_text
                }
            }
        },
        selectImgClicked() {
            this.$refs.file.click();
        },
        selectFile() {
            console.log("selectFile --- ")
            const file = document.getElementById('fileInput').files[0]
            console.log(file)
            if(file == undefined || file == null) {
                return
            }
            if(file.size > 1024 * 1024 * 5) {
                this.$Message.warning("图片大小不能超过5M")
                return
            }
            let reader = new FileReader();
            let that = this
            reader.onload = function() {
                let content = reader.result;
                // console.log("content.......")
                // console.log(content);
                // that.send(content, file.name)
                that.imageUrl = null
                that.file = {
                    base64: content,
                    file_name: file.name
                }
            };
            reader.readAsDataURL(file)
            // const fileInput = document.getElementById('fileInput' + index).files[0]
            // console.log(fileInput)
            console.log("---------------")
            //let img = new Image()
        },
        deleteCurrentImage() {
            this.$refs.file.value = ''
            this.file.file_name = ''
            this.file.base64 = ''
            this.imageUrl = null
        }
    },
    created() {
      //
    },
    beforeUnmount() {
      emitter.off('chagelightmodechild2')
      emitter.off('receiveReferenceImage')
      emitter.off('putPrompt')
      emitter.off('putImage')
    },
    mounted() {
        this.lightmode = this.$store.lightmode
        emitter.on('chagelightmodechild2', (mode) => {
            this.lightmode = mode
        })
        emitter.on('receiveReferenceImage', (imageUrl) => {
            console.log("receiveReferenceImage..." + imageUrl)
            this.referenceImage = imageUrl
        })
        emitter.on('putPrompt', (prompt) => {
            console.log("putPrompt..." + prompt)
            this.st.text = prompt
        })
        emitter.on('putImage', (message) => {
            console.log("putImage...")
            console.log(message)
            this.$refs.file.value = ''
            this.file.file_name = ''
            this.file.base64 = ''
            this.imageUrl = message.mj_image_url
            // let xhr = new XMLHttpRequest()
            // xhr.open('get', message.mj_image_url, true)
            // xhr.responseType = 'blob'
            // xhr.onload = function () {
            //     let reader = new FileReader();
            //     reader.onloadend = function() {
            //         let base64String = reader.result;
            //         console.log('base64String...' + base64String)
            //     }
            //     reader.readAsDataURL(xhr.response);
            // }
            // xhr.send()
        })
    }
}
</script>
<style scoped lang="less">
@import '@/views/GeneralStyle.less';
.big-back {
    padding-left: 10px;
    padding-right: 10px;
}
.aspect-item.active, .aspect-item.active .aspect-box{
    border-color:#08C160; 
}
.btn-back {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.option-item {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .option-select {
        width: 150px;
    }
}

.dark-bg {
  background-color: #202020;
  color: #E0E0E0;
}
.day-bg {
  background-color: #F7F7F7;
  color: #181818;
}
.file-close-back {
    //background-color: red;
    display: flex;
    height: 100%;
}
.new-input-back2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .new-input-img {
        border-radius: 5px;
        max-width: 100px;
        max-height: 100px;
        cursor: pointer;
    }
    .day-new-input-btn {
        border-radius: 5px;
        border: 1px solid #E0E0E0;
        color: #191919;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
    }
    .dark-new-input-btn {
        border-radius: 5px;
        border: 1px solid #999999;
        color: #999999;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
    }
    .new-input-hint {
        margin-left: 15px;
        color: #999999;
    }
}
</style>
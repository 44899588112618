// import { createApp } from 'vue'
// import App from './App.vue'

// createApp(App).mount('#app')


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import iView from 'iview'
// import 'iview/dist/styles/iview.css'
import ViewUIPlus from 'view-ui-plus'; // 导入 view-ui-plus
import 'view-ui-plus/dist/styles/viewuiplus.css'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import naive from 'naive-ui'
import './assets/tailMain.css'

const app = createApp(App)

app.config.productionTip = false

app.use(mavonEditor)
app.use(ViewUIPlus)
app.use(router)
app.use(store)
app.use(naive)
app.mount('#app')
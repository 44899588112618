<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="userinfo-bigback" :class="lightmode == 'day' ? 'day-normal-big-bg' : 'dark-normal-big-bg'">
    <img @click="changeHeadClick" :src="userInfo.avatar" v-if="userInfo.avatar != null && userInfo.avatar.length > 0" class="uhead cursor-pointer" />
    <img @click="changeHeadClick" src="@/assets/default_head.png" v-if="userInfo.avatar == null || userInfo.avatar.length == 0" class="uhead cursor-pointer" />
    <div @click="changeHeadClick" class="hint">点击可更换头像</div>
    <input ref="file2" type="file" style="height: 0px;width: 0px;visibility: hidden" class="file" v-on:change="upload()" nullMsg="文件不能为空" id="fileInput">
    <!-- <Upload action="https://api.pnxuetang.com/api/util/upload_file"
      :data="{token: token}"
      @on-success="onChangeHeadSuccess"
      @on-error="onChangeHeadError">
        <Button icon="ios-cloud-upload-outline">更换头像</Button>
    </Upload> -->
    <div class="item-back">
      <span class="item-title">用户名：</span>
      <span :class="lightmode=='day' ? 'day-item-content': 'dark-item-content'" class="item-content">{{ userInfo.username }}</span>
    </div>
    <div class="item-back">
      <span class="item-title">昵称：</span>
      <span :class="lightmode=='day' ? 'day-item-content': 'dark-item-content'">{{ userInfo.realname }}</span>
      <span class="item-modify" @click="modifyInfoClick('nickname')">修改</span>
    </div>
    <div class="item-back">
      <span class="item-title">邮箱：</span>
      <span :class="lightmode=='day' ? 'day-item-content': 'dark-item-content'" class="item-content">{{ userInfo.email }}</span>
      <span class="item-modify" @click="modifyInfoClick('email')">修改</span>
    </div>
    <div class="item-back">
      <span class="item-title">手机号：</span>
      <span :class="lightmode=='day' ? 'day-item-content': 'dark-item-content'" class="item-content">{{ userInfo.phone }}</span>
      <span class="item-modify" @click="changePhoneModal=true">修改</span>
    </div>
    <div>
      <div class="logout" @click="logoutModal=true">退出登录</div>
    </div>

    <Modal v-model="changePhoneModal" footer-hide style="width: 100px;background-color: #ed4014;">
        <div class="sms-back">
          <h1 class="sms-login-title">更换手机号</h1>
          <Form :model="smsLogin">
            <FormItem>
              <div class="sms-login-item">
                <Input type="tel" v-model="smsLogin.phone" :border="false" placeholder="新手机号" />
              </div>
            </FormItem>
            <FormItem>
              <div class="sms-login-item">
                <Input v-model="smsLogin.code" size="small" :border="false" placeholder="验证码" />
                <span @click="sendSMS" class="send-sms" :style="{color: sendSMSColor}">{{ sendSmsText }}</span>
              </div>
            </FormItem>
            <!-- <Button type="success" class="login-btn" long @click="performSmsLogin">登录</Button> -->
            <div class="modal-footer-btn-back">
              <Button class="modal-footer-btn" @click="handleChangePhone" type="success">确定</Button>
              <Button class="modal-footer-btn" @click="handleCancel">取消</Button>
            </div>
          </Form>
        </div>
      </Modal>


    <Modal
        footer-hide
        v-model="modifyModal"
        title="编辑个人信息">
        <Form ref="formInline" :model="formInline" :rules="ruleInline">
          <Form-item prop="realname" v-if="modifyType == 'nickname'">
              <Input type="text" v-model="formInline.realname" placeholder="请输入昵称">
                  <template #prepend>昵称</template>
              </Input>
          </Form-item>
          <Form-item prop="email" v-if="modifyType == 'email'">
              <Input type="text" v-model="formInline.email" placeholder="请输入邮箱">
                  <template #prepend>邮箱</template>
              </Input>
          </Form-item>
      </Form>
      <div class="modal-footer-btn-back">
        <Button class="modal-footer-btn" @click="handleSubmit('formInline')" type="success">确定</Button>
        <Button class="modal-footer-btn" @click="handleCancel">取消</Button>
      </div>
    </Modal>

    <Modal v-model="logoutModal" footer-hide width="400">
      <div class="logout-back">
        <h1 class="modal-title">退出登录</h1>
        <h1 class="modal-content">确定要退出登录吗？</h1>
        <div class="modal-footer-btn-back">
          <Button class="modal-footer-btn" @click="handleLogout" type="success">确定</Button>
          <Button class="modal-footer-btn" @click="logoutModal=false">取消</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { logoutAPI, sendVerifyCodeAPI, changeBindPhoneAPI } from '@/api/LoginAPI.js'
import { getUserInfo, editUser, editHead } from '@/api/UserAPI.js'
import axios from 'axios'
import emitter from '@/hooks/bus.js'
export default {
  data() {
    return {
      lightmode: 'day',
      token: '',
      userInfo: {},
      modifyType: '',
      modifyModal: false,
      formInline: {
        realname: '',
        email: ''
      },
      ruleInline: {
        realname: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
      },
      logoutModal: false,
      changePhoneModal: false,
      smsLogin: {
        phone: "",
        code: "",
      },
      sendSmsText: "发送验证码",
    }
  },
  methods: {
    async sendSMSRequest() {
      const res = await sendVerifyCodeAPI(this.smsLogin.phone)
      console.log(res)
      if (res.data.code !== 0) {
        this.$Message.error('发送验证码失败：' + res.data.message)
        return
      }
      this.$Message.success('发送验证码成功')
      this.startSMSSendInterval()
    },
    async changePhoneRequest() {
      const res = await changeBindPhoneAPI(this.token, this.smsLogin.phone, this.smsLogin.code)
      console.log(res)
      if (res.data.code !== 0) {
        this.$Message.error('更换手机号失败：' + res.data.message)
        return
      }
      this.$Message.success('更换手机号成功')
      this.startSMSSendInterval()
    },
    async userInfoRequest () {
      const res = await getUserInfo(this.token)
      if (res.data.code === 0) {
        this.userInfo = res.data.sys_user_data
        emitter.emit('changehead', res.data.sys_user_data.avatar)
      }
    },
    async modifyInfoRequest() {
      const res = await editUser(this.token,
        this.userInfo.id,
        this.formInline.realname,
        this.formInline.email)
      if(res.data.code == 0) {
        this.$Message.success("更新个人信息成功")
        this.modifyModal = false
        this.userInfoRequest()
      } else {
        this.$Message.error("更新个人信息失败：" + res.data.message)
      }
    },
    handleCancel() {
      this.modifyModal = false
      this.changePhoneModal = false
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.modifyInfoRequest()
        } else {
          this.$Message.error('请按提示正确填入信息')
        }
      })
    },
    handleLogout() {
      this.logoutReuest()
      this.logoutModal = false
    },
    onModifyBtnClick() {
      this.formInline = {
        realname: this.userInfo.realname,
        email: this.userInfo.email
      }
      this.modifyModal = true
    },
    onChangeHeadSuccess() {
      this.$Message.success("更换头像成功")
    },
    onChangeHeadError() {
      this.$Message.error("更换头像成功")
    },
    async requestChangeHead(url) {
      const res = await editHead(this.token, this.userInfo.id, url)
      if(res.data.code === 0) {
        this.$Message.success("更换头像成功")
        this.userInfo.avatar = url
        if(this.userInfo.avatar == undefined || this.userInfo.avatar == 'undefined' || this.userInfo.avatar == null || this.userInfo.avatar == 'null') {
          this.userInfo.avatar = ''
        }
        localStorage.setItem('avatar', url)
        emitter.emit('changehead', url)
      } else {
        this.$Message.error("更换头像失败")
      }
    },
    async logoutReuest() {
      let token = localStorage.getItem('token');
      const res = await logoutAPI(token)
      if (res.data.code !== 0) {
        //console.log("")
      }
      this.token = ''
      this.userName = ''
      this.loginUserName = ''
      emitter.emit('logout', '1')
      localStorage.setItem('token', '')
      localStorage.setItem('avatar', '')
      localStorage.setItem('username', '')
      localStorage.setItem('role_type', '')
    },
    upload () {
      const formData = new FormData()
      const file = document.getElementById('fileInput').files[0]
      formData.append('token', this.token)
      formData.append('file', file)
      axios.post('https://api.pnxuetang.com/api/util/upload_file', formData, {
        'Content-type': 'multipart/form-data'
      }).then(res => {
        this.requestChangeHead(res.data.data.url)
      }, error => {
        console.log(error)
      })
    },
    changeHeadClick() {
      this.$refs.file2.click();
    },
    modifyInfoClick(type) {
      this.modifyType = type
      this.formInline = {
        realname: this.userInfo.realname,
        email: this.userInfo.email
      }
      this.modifyModal = true
    },
    startSMSSendInterval() {
      this.watingSmsCount = 120
      this.smsSendInterval = setInterval(() => {
        this.watingSmsCount = this.watingSmsCount - 1
        this.sendSmsText = this.watingSmsCount + "秒"
        if(this.watingSmsCount <= 0) {
          this.stopSMSSendInterval()
        }
      }, 1000)
    },
    stopSMSSendInterval() {
      console.log("stopSMSSendInterval...")
      if(this.smsSendInterval) {
        clearInterval(this.smsSendInterval)
      }
      this.watingSmsCount = 0
      this.sendSmsText = "发送验证码"
    },
    sendSMS() {
      if(this.watingSmsCount > 0) {
        return
      }
      if(this.smsLogin.phone.length < 11) {
        this.$Message.error('请输入正确的手机号')
        return
      }
      this.sendSMSRequest()
    },
    handleChangePhone() {
      if(this.smsLogin.phone.length < 11) {
        this.$Message.error("请输入正确的手机号")
        return
      }
      if(this.smsLogin.code.length < 4) {
        this.$Message.error("请输入正确的验证码")
        return
      }
      this.changePhoneRequest()
    }
  },
  created() {
    this.token = localStorage.getItem('token');
    if(this.token && this.token != "") {
      this.userInfoRequest(true)
    }
  },
  beforeUnmount() {
    emitter.off('chagelightmodechild')
  },
  mounted() {
    this.lightmode = this.$store.lightmode
    emitter.on('chagelightmodechild', (mode) => {
      this.lightmode = mode
    })
  },
  computed: {
    sendSMSColor: function() {
      if(this.watingSmsCount <= 0) {
        return "#08C160"
      } else {
        return "#999999"
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';
.userinfo-bigback {
  padding: 20px;
  .title {
    font-weight: bold;
  }
  .hint {
    font-size: 0.7rem;
    color: #aaaaaa;
    cursor: pointer;
  }
  .logout {
    margin-top: 20px;
    border-radius: 8px;
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #E0E0E0;
    color: #181818;
    font-weight: bold;
    cursor: pointer;
  }
}

.item-back {
  margin-top: 20px;
}
.item-title {
  font-weight: bolder;
  // min-width: 180px;
}
.dark-item-content {
  margin-left: 10px;
  color: #999999;
}
.day-item-content {
  margin-left: 10px;
  color: #999999;
}
.item-modify {
  margin-left: 20px;
  color: #08C160;
  cursor: pointer;
}

.sms-back {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 20px;

  .sms-login-title {
    text-align: center;
    font-weight: bolder;
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 1.2rem;
  }
  .sms-login-content {
    font-weight: bold;
  }

  .sms-login-item {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    height: 50px;
    border-color: #08C160;
    border-radius: 25px;
    border-style: solid;
    border-width: 1px;

    .send-sms {
      min-width: 150px;
      text-align: center;
      font-size: 1rem;
      cursor: pointer;
      font-weight: bolder;
    }
  }
}
</style>
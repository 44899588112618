<template>
  <div class="page-back">
    <h1 class="title">扫码下方二维码</h1>
    <h1 class="title">添加老师微信</h1>
    <img class="ervmark" src="@/assets/default_ervmark.jpg"/>
    <p class="content">1.长按或扫码上方二维码</p>
    <p class="content">2.在弹出的菜单里选择->打开对方的企业微信名片</p>
    <p class="content">3.滑到底部点击->添加到通讯录</p>
    <p class="hint">注：若无法识别二维码，手机截图保存到相册，再打开微信识别此二维码</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      a: ''
    }
  }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';
.page-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}
.title {
  margin-top: 20px;
  font-size: 2rem;
  font-weight: bolder;
}
.ervmark {
  margin-top: 20px;
  margin-bottom: 10px;
  max-width: 300px;
}
.content {
  margin-top: 10px;
}
.hint {
  margin-top: 30px;
  color: #999999;
}
</style>
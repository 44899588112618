import request from '@/utils/request'

export const addMessageToGalleryAPI = function (token, message_id) {
  return request.post('/sysGallery/add', {
    token,
    sys_gallery: {
      message_id
    }
  })
}

export const deleteGalleryMessageAPI = function (token, id) {
  return request.post('/sysGallery/delete', {
    token,
    id
  })
}

export const getGalleryImageListAPI = function (token, my_gallery, page_no, page_size) {
  return request.post('/sysGallery/list', {
    token,
    my_gallery,
    page_no,
    page_size,
    order_by: 'create_time DESC'
  })
}
<!--AI混图-->
<template>
  <div>
    <h1 class="child-title margin-left5">AI将两张图进行混合生成新的图片</h1>
    <input ref="file0" class="native-input" type="file" v-on:change="selectFile(0)" nullMsg="文件不能为空" id="fileInput0" accept="image/*">
    <div class="new-input-back">
      <img class="new-input-img" v-show="file0 && file0.base64.length > 0" @click="selectImgClicked(0)" :src="file0.base64"/>
      <div v-show="file0 == null || file0.base64.length == 0" @click="selectImgClicked(0)" :class="lightmode=='day'?'day-new-input-btn':'dark-new-input-btn'">选择图片</div>
      <span class="new-input-hint">{{ file0.file_name }}</span>
    </div>
    <input ref="file1" class="native-input" type="file" v-on:change="selectFile(1)" nullMsg="文件不能为空" id="fileInput1" accept="image/*">
    <div class="new-input-back">
      <img class="new-input-img" v-show="file1 && file1.base64.length > 0" @click="selectImgClicked(1)" :src="file1.base64"/>
      <div v-show="file1 == null || file1.base64.length == 0" @click="selectImgClicked(1)" :class="lightmode=='day'?'day-new-input-btn':'dark-new-input-btn'">选择图片</div>
      <span class="new-input-hint">{{ file1.file_name }}</span>
    </div>
    <Button class="send-to-father-btn" @click="send" type="success" long>混图</Button>
  </div>
</template>
<script>
//import { imageToBase64, base64ToFile } from '@/utils/imageutil.js'
import emitter from '@/hooks/bus.js'
export default {
  props: {
      token: {
          type: String,
          required: true
      }
  },
  data() {
    return {
      lightmode: 'day',
      file0: {
        base64: '',
        file_name: '请选择图片1'
      },
      file1: {
        base64: '',
        file_name: '请选择图片2'
      }
    }
  },
  methods: {
    selectFile(index) {
      console.log("selectFile --- " + index)
      const file = document.getElementById('fileInput' + index).files[0]
      console.log(file)
      if(file == undefined || file == null) {
        return
      }
      if(file.size > 1024 * 1024 * 5) {
        this.$Message.warning("图片大小不能超过5M")
        return
      }
      let reader = new FileReader();
      let that = this
      reader.onload = function() {
        var content = reader.result;
        console.log("content.......")
        console.log(typeof(content))
        console.log(content);
        //that.send(content, file.name)
        if(index == 0) {
          that.file0 = {
            base64: content,
            file_name: file.name
          }
        } else if(index == 1) {
          that.file1 = {
            base64: content,
            file_name: file.name
          }
        }
        // if(that.file0 != null && that.file1 != null) {
        //   that.send()
        // }
      };
      reader.readAsDataURL(file)
      // const fileInput = document.getElementById('fileInput' + index).files[0]
      // console.log(fileInput)
      console.log("---------------")
      //let img = new Image()
    },
    send() {
      console.log("send blend--- ")
      console.log(this.file0)
      console.log(this.file0.base64)
      console.log(this.file1)
      if(this.file0.base64.length > 0 && this.file1.base64.length > 0) {
        emitter.emit('blend', {
          file0: this.file0,
          file1: this.file1
        })
      } else {
        this.$Message.warning("请添加两张图片")
      }
    },
    selectImgClicked(index) {
      //this.$refs.file2.click();
      if(index == 0) {
        this.$refs.file0.click();
      } else if(index == 1) {
        this.$refs.file1.click();
      }
    },
  },
  created() {
    //
  },
  beforeUnmount() {
    emitter.off('chagelightmodechild2')
  },
  mounted() {
    this.lightmode = this.$store.lightmode
    emitter.on('chagelightmodechild2', (mode) => {
      this.lightmode = mode
    })
  }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';
</style>
import axios from 'axios'

const request = axios.create({
  //baseURL: 'http://api.chatgpt.xkmob.com/api'
  baseURL: 'https://api.pnxuetang.com/api'
  //baseURL: 'http://api.pnxuetang.com/api'
})

//request.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

request.interceptors.response.use(
  response => {
    // if (response.status !== 200) {
    //   window.showError('状态码：' + response.status)
    //   return null
    // }
    //console.log(response.data)
    if (response.data.code === -2) { // -2无权限拦截
      window.gotoLogin(response.data.message)
      return null
    }
    return response
  },
  error => {
    error.data = {
      code: error.code,
      message: error.code
    }
    return error
  }
)

export default request
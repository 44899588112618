export const getTimeStr = function (timestamp) {
  if ((timestamp + '').length < 11) {
    timestamp = timestamp * 1000
  }
  const date = new Date(timestamp)
  return date.getFullYear() +
    '-' + ((date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +
    '-' + (date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()) +
    ' ' + (date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()) +
    ':' + (date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()) +
    ':' + (date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds())
}

export const getTimeStr2 = function (timestamp) {
  if ((timestamp + '').length < 11) {
    timestamp = timestamp * 1000
  }
  const date = new Date(timestamp)
  return date.getFullYear() +
    '-' + ((date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +
    '-' + (date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()) +
    ' ' + (date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()) +
    ':' + (date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes())
}

export const getTimeHourMinute = function (timestamp) {
  if ((timestamp + '').length < 11) {
    timestamp = timestamp * 1000
  }
  const date = new Date(timestamp)
  return (date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()) +
    ':' + (date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()) +
    ':' + (date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds())
}

export const getCurrentTimeStamp = function () {
  // const date = new Date()
  // console.log('date....', date)
  // return date
  return Date.parse(new Date())
}

export const getTimeStrWithoutSeconds = function (timestamp) {
  if ((timestamp + '').length < 11) {
    timestamp = timestamp * 1000
  }
  const date = new Date(timestamp)
  return date.getFullYear() +
    '-' + ((date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +
    '-' + (date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()) +
    ' ' + (date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()) +
    ':' + (date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes())
}

export const getLeftTimeStr = function(expTimestamp) {
  const currentTimeStamp = Date.parse(new Date()) / 1000
  console.log("currentTimeStamp..." + currentTimeStamp)
  if(expTimestamp - currentTimeStamp <= 0) {
    return "已过期"
  } else {
    const day = (expTimestamp - currentTimeStamp) / (24 * 60 * 60)
    return "有效期：" + Math.floor(day) + "天"
  }
}
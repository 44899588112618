import request from '@/utils/request'

export const getMessageList = function (token, topic_id, page_no, page_size) {
  return request.post('/sysMessage/list', {
    token,
    topic_id,
    page_no,
    page_size,
    order_by: 'create_time DESC'
  })
}

export const addMessage = function (token, topic_id, prompt, model, temperature) {
  return request.post('/sysMessage/add', {
    token,
    topic_id,
    prompt,
    model,
    temperature,
    top_p: 1
  })
}

export const clearMessage = function (token, topic_id) {
  return request.post('/sysMessage/clear', {
    token,
    topic_id
  })
}

export const deleteMessage = function (token, id) {
  return request.post('/sysMessage/delete', {
    token,
    id
  })
}
// 将字符串格式化为UTF8编码的字节
export const writeUTF = function (str, isGetBytes) {
  var back = [];
  var byteSize = 0;
  for (var i = 0; i < str.length; i++) {
    var code = str.charCodeAt(i);
    if (0x00 <= code && code <= 0x7f) {
      byteSize += 1;
      back.push(code);
    } else if (0x80 <= code && code <= 0x7ff) {
      byteSize += 2;
      back.push((192 | (31 & (code >> 6))));
      back.push((128 | (63 & code)))
    } else if ((0x800 <= code && code <= 0xd7ff) 
            || (0xe000 <= code && code <= 0xffff)) {
      byteSize += 3;
      back.push((224 | (15 & (code >> 12))));
      back.push((128 | (63 & (code >> 6))));
      back.push((128 | (63 & code)))
    }
  }
    for (i = 0; i < back.length; i++) {
      back[i] &= 0xff;
    }
    if (isGetBytes) {
      return back
    }
    if (byteSize <= 0xff) {
      return [0, byteSize].concat(back);
    } else {
      return [byteSize >> 8, byteSize & 0xff].concat(back);
    }
}
//readUTF([0, 3, 228, 184, 173]); => '中'

//读取UTF8编码的字节，并专为Unicode的字符串
export const readUTF = function (arr) {
  if (typeof arr === 'string') {
    return arr;
  }
  var UTF = '', _arr = this.init(arr);
  for (var i = 0; i < _arr.length; i++) {
    var one = _arr[i].toString(2),
            v = one.match(/^1+?(?=0)/);
    if (v && one.length == 8) {
      var bytesLength = v[0].length;
      var store = _arr[i].toString(2).slice(7 - bytesLength);
      for (var st = 1; st < bytesLength; st++) {
          store += _arr[st + i].toString(2).slice(2)
      }
      UTF += String.fromCharCode(parseInt(store, 2));
      i += bytesLength - 1
    } else {
      UTF += String.fromCharCode(_arr[i])
    }
  }
  return UTF
}
//readUTF([0, 3, 228, 184, 173]); => '中'

export const EncodeUtf8 = function(s1) {
  var s = escape(s1);
  var sa = s.split("%");
  var retV = "";
  if (sa[0] != "") {
    retV = sa[0];
  }
  for (var i = 1; i < sa.length; i++) {
    if (sa[i].substring(0, 1) == "u") {
      retV += Hex2Utf8(Str2Hex(sa[i].substring(1, 5)));

    }
    else retV += "%" + sa[i];
  }

  return retV;
}
//console.log(EncodeUtf8('哈哈哈'));

function Str2Hex(s) {
  var c = "";
  var n;
  var ss = "0123456789ABCDEF";
  var digS = "";
  for (var i = 0; i < s.length; i++) {
    c = s.charAt(i);
    n = ss.indexOf(c);
    digS += Dec2Dig(eval(n));

  }
  //return value;
  return digS;
}
function Dec2Dig(n1) {
  var s = "";
  var n2 = 0;
  for (var i = 0; i < 4; i++) {
    n2 = Math.pow(2, 3 - i);
    if (n1 >= n2) {
      s += '1';
      n1 = n1 - n2;
    }
    else
      s += '0';

  }
  return s;

}
function Dig2Dec(s) {
  var retV = 0;
  if (s.length == 4) {
    for (var i = 0; i < 4; i++) {
      retV += eval(s.charAt(i)) * Math.pow(2, 3 - i);
    }
    return retV;
  }
  return -1;
}
function Hex2Utf8(s) {
  var retS = "";
  var tempS = "";
  var ss = "";
  if (s.length == 16) {
    tempS = "1110" + s.substring(0, 4);
    tempS += "10" + s.substring(4, 10);
    tempS += "10" + s.substring(10, 16);
    var sss = "0123456789ABCDEF";
    for (var i = 0; i < 3; i++) {
      retS += "%";
      ss = tempS.substring(i * 8, (eval(i) + 1) * 8);
      retS += sss.charAt(Dig2Dec(ss.substring(0, 4)));
      retS += sss.charAt(Dig2Dec(ss.substring(4, 8)));
    }
    return retS;
  }
  return "";
}
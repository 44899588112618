import request from '@/utils/request'

export const getOrderListAPI = function (token, page_no, page_size) {
  return request.post('/sysOrder/list', {
    token,
    page_no,
    page_size,
    order_by: 'create_time DESC'
  })
}

export const getOrderByIDAPI = function (token, id) {
  return request.post('/sysOrder/queryById', {
    token,
    id
  })
}

export const createAliyunOrderAPI = function (token, package_id) {
  return request.post('/aliyun/create', {
    token,
    package_id,
    return_url: "https://ai.pnxuetang.com"
  })
}

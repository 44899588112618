<template>
  <div class="chat-home" v-resize="handleResize">
    <div class="chat-list" :class="lightmode=='day' ? 'day-topiclist-bg' : 'dark-topiclist-bg'" v-if="!isMobile">
      <TopicList :chat_type="chat_type"></TopicList>
    </div>
    <Drawer v-if="isMobile" title="对话列表" placement="left" :closable="true" :mask-closable="false" v-model="topicsDrawer">
      <TopicList :chat_type="chat_type"></TopicList>
    </Drawer>
    <div class="no-chat-back" :class="lightmode == 'day' ? 'day-normal-big-bg' : 'dark-normal-big-bg'" style="flex: 7.5;" v-if="isLogin==false">
      <div><img class="nochat-img" src="@/assets/big_chat.png"/></div>
      <div class="nochat-title">聊天对话</div>
      <div class="nochat-content">点击下方按钮先登录吧</div>
      <div class="nochat-button-button">
        <Button type="success" @click="noChatLoginClick">登录</Button>
      </div>
    </div>
    <div class="no-chat-back" :class="lightmode == 'day' ? 'day-normal-big-bg' : 'dark-normal-big-bg'" style="flex: 7.5;" v-if="isLogin==true && (!currentTopic || currentTopic.id == -1)">
      <div><img class="nochat-img" src="@/assets/big_chat.png"/></div>
      <div class="nochat-title">聊天对话</div>
      <div class="nochat-content">点击下方按钮开始你的对话吧</div>
      <div class="nochat-button-button">
        <Button type="success" @click="noChatCreateClick">新对话</Button>
      </div>
    </div>
    <div class="chat-back" :class="lightmode == 'day' ? 'day-normal-big-bg' : 'dark-normal-big-bg'" v-if="isLogin==true && (currentTopic && currentTopic.id != -1)">
      <div class="chat-top" :class="lightmode=='day'?'day-chat-top-bg':'dark-chat-top-bg'">
        <div class="topicname">
          <Icon size=20 class="cursor-pointer" @click="topicNameClicked" v-if="isMobile" type="md-chatboxes" />
          <strong :style="{cursor: isPointer}" @click="topicNameClicked">{{ currentTopic.title }}</strong>
          <!-- <span class="token-left">Token剩余：</span> -->
          <Icon class=token-left size="20" type="ios-flash" />
          <strong>{{ ai_token }}</strong>
        </div>
        <div class="topic-options" v-if="!isMobile">
          <!-- <span class="model-title">模型</span> 
          <Tooltip max-width="200" content="消耗1倍">
            <Button type="success" :class="chatSettings.model=='gpt-3.5-turbo' ? 'option-selected': 'option-unselected'" shape="circle" @click="onGptVersionSelect('gpt-3.5-turbo')">chatgpt-3.5</Button>
          </Tooltip>
          <Tooltip max-width="200" content="消耗5倍">
            <Button type="success" :class="chatSettings.model=='gpt-4' ? 'option-selected': 'option-unselected'" shape="circle" @click="onGptVersionSelect('gpt-4')">chatgpt-4</Button>
          </Tooltip> -->
        </div>
        <div class="topic-options" v-if="isMobile">
          <!-- <div class="option-item">
            <Select v-model="chatSettings.model" style="width:120px;margin-left: 5px;">
                <Option value="gpt-3.5-turbo" key="gpt-3.5-turbo">chatgpt-3.5</Option>
                <Option value="gpt-4" key="gpt-4">chatgpt-4</Option>
            </Select>
          </div> -->
        </div>
        <!-- <div >cc</div> -->
      </div>
      <!-- <div class="chat-split"></div> -->
      <div class="chat-content" :class="lightmode=='day'?'day-chat-bg':'dark-chat-bg'" ref="chatbox">
        <div v-for="(message, index) in currentMessages" :key="index">
          <!--用户发送-->
          <div class="user-send" v-if="message.isUser == true">
            <div class="leftseizeseat"></div>
            <div class="right">
              <div class="user-msg-bigback">
                <div class="user-time-back">
                  <Icon type="ios-copy" class="cursor-pointer msg-tool-btn" size="20" @click="onCopyPrompt(message.text)"/>
                  <Icon type="ios-trash" class="cursor-pointer msg-tool-btn" size="20" @click="showHintModal(message.id,'delete', index)"/>
                  <span class="chat-time">{{ message.timeStr }}</span>
                </div>
                <div class="user-msg-back" :class="lightmode=='day'?'day-user-send-bg':'dark-user-send-bg'">
                  <!-- <span class="msg-text">{{ message.text }}</span> -->
                  <mavon-editor
                    v-model="message.text"
                    :subfield="false"
                    :defaultOpen="'preview'"
                    :boxShadow="false"
                    :editable="false"
                    :toolbarsFlag="false"
                    :previewBackground="'#94dfb8'"
                    :codeStyle="lightmode=='github-dark'?'':'androidstudio'"
                    :ishljs="true"
                    style="min-width: 20px;min-height: 10px;z-index: 1;border: none;">
                  </mavon-editor>
                </div>
              </div>
              <!-- <div v-if="avatar == null || avatar.length == 0">
                <img src="@/assets/default_head.png" class="uhead" />
              </div>
              <img :src="avatar" v-if="avatar != null && avatar.length > 0" class="uhead" /> -->
              <img :src="avatar" v-if="avatar != null && avatar.length > 0" class="uhead" />
              <img src="@/assets/default_head.png" v-if="avatar == null || avatar.length == 0" class="uhead" />
            </div>
          </div>
          <!--用户发送End-->
          <!--GPT回复-->
          <div class="gpt-reply" v-if="message.isUser == false">
            <div class="left">
              <!-- <Avatar class="gpt-head" icon="md-barcode" size="large"/> -->
              <img src="@/assets/ai_head.png" class="uhead" />
              <div class="gpt-msg-back" v-if="message.id == -1">
                AI思考中...
                <CountDown :target="replyTime" @on-end="handleReplyTimeEnd" v-font="20" />
              </div>
              <div class="gpt-msg-bigback" v-if="message.id != -1">
                <div class="gpt-time-back">
                  <span class="chat-time-left">{{ message.timeStr }}</span>
                  <span class="chat-time">Token消耗：{{ message.total_tokens }}</span>
                  <Icon type="ios-copy" class="cursor-pointer msg-tool-btn" size="20" @click="onCopyPrompt(message.text)"/>
                  <Tooltip class="cursor-pointer msg-tool-btn" max-width="200" :content="'提示词：' + message.user_ask">
                    <Icon type="md-book" size="20"/>
                  </Tooltip>
                  <Icon type="ios-trash" class="cursor-pointer msg-tool-btn" size="20" @click="showHintModal(message.id,'delete', index)"/>
                </div>
                <div class="gpt-msg-back">
                  <mavon-editor
                    v-model="message.text"
                    :subfield="false"
                    :defaultOpen="'preview'"
                    :boxShadow="false"
                    :editable="false"
                    :toolbarsFlag="false"
                    :previewBackground="lightmode=='day'?'#E0E0E0':'#E0E0E0'"
                    :codeStyle="lightmode=='github-dark'?'':'androidstudio'"
                    :ishljs="true"
                    style="min-width: 20px;min-height: 10px;z-index: 1;border: none;">
                  </mavon-editor>
                  <!-- :previewBackground="lightmode=='day'?'#E0E0E0':'#ff0000'" -->
                  <!-- <VueMarkdown :source="message.text"></VueMarkdown> -->
                </div>
              </div>
            </div>
            <div class="rightseizeseat"></div>
          </div>
          <!--GPT回复End-->
        </div>
      </div>
      <!-- <div class="chat-split"></div> -->
      <!--发送背景-->
      <div class="chat-bottom" :class="lightmode=='day'?'day-chat-bottom-bg':'dark-chat-bottom-bg'">
        <Input :class="lightmode=='day'?'day-input':'dark-input'" @on-keydown="onKeyDown" :rows="6" :border="false" class="send-input" v-model="newMessage" type="textarea" placeholder="来说点什么吧…（按下Enter发送，Shift + Enter换行）" />
        <Button @click="sendMsg" type="success" size="large" class="send-btn">发送</Button>
      </div>
      <!--发送背景End-->
    </div>
    <Modal v-model="hintModal" :closable="true" :mask-closable="false" footer-hide width="400">
      <div>
        <h1 class="modal-title">{{ hintModalInfo.title }}</h1>
        <h1 class="modal-content">{{ hintModalInfo.content }}</h1>
        <div class="modal-footer-btn-back">
          <Button class="modal-footer-btn" @click="handlerOk" type="success">确定</Button>
          <Button class="modal-footer-btn" @click="handlerCancel">取消</Button>
        </div>
      </div>
    </Modal>
    <Modal v-model="failModal" :closable="true" :mask-closable="true" footer-hide width="400" :styles="{top: '30%'}">
      <div>
        <h1 class="modal-title">提示</h1>
        <h1 class="modal-content">发送信息失败：{{ failReason }}</h1>
        <!-- <div class="modal-footer-btn-back">
          <Button class="modal-footer-btn" @click="handlerOk" type="success">确定</Button>
          <Button class="modal-footer-btn" @click="handlerCancel">取消</Button>
        </div> -->
      </div>
    </Modal>
  </div>
</template>
<script>
import { getMessageList, addMessage, deleteMessage } from '@/api/MessageAPI.js'
import { getUserInfo } from '@/api/UserAPI.js'
import TopicList from '@/components/TopicList.vue'
import clipboard from 'clipboard'
import emitter from '@/hooks/bus.js'
import { getTimeStr, getCurrentTimeStamp } from "@/utils/timeutil.js"
//import VueMarkdown from 'vue-markdown'
export default {
  components: {
    TopicList,
    //VueMarkdown
  },
  data() {
    return {
      lightmode: 'day',
      token: '',
      avatar: '',
      ai_token: 0,
      chat_type: 0, //文本
      replyTime: 20,
      isLogin: false,
      isMobile: false,
      topicsDrawer: false,
      currentTopic: {
        id: -1
      },
      failModal: false,
      failReason: '',
      hintModal: false,
      hintModalInfo: {
        id: -1,
        target: 'enlarge', //enlarge-放大，trans-变换，delete删除
        index: -1,
        title: '',
        content: ''
      },
      // currentMessage: {
      //   text: '月饼是中国传统的节日食品，通常在中秋节期间食用。发斯蒂芬撒大声地发顺丰手打发撒大萨达放大说法是打发大萨达说法的算法的算法的发顺丰时代大厦下面是制作月饼的一般做法：\\n \n材料：\n- 月饼馅：常见的有莲蓉、红豆沙、五仁等\n- 月饼皮：一般使用植物油、糖水和面粉制作\n\n步骤：\n\n1. 准备月饼馅：\n   - 如果你选择莲蓉馅，将莲子炒熟，去皮，然后捣成泥。\n   - 红豆沙馅需要将红豆洗净后煮熟，再加入适量的糖浆，搅拌至成糊状。\n   - 五仁馅由核桃肉、花生碎、杏仁碎、瓜子仁和莲子核组成。\n\n2. 制作月饼皮：\n   - 将面粉倒入大碗中，用开水慢慢搅拌，直到形成均匀的团状物。\n   - 在饧面团充分冷却后，加入糖水和植物油，揉匀至面团光滑。\n   - 将饧好的面团分割成小份，每个约50克左右，并搓圆备用。\n\n3. 包制月饼：\n   - 取一个面团搓圆，再用手掌轻压扁。\n   - 把馅料放入饼皮中间，然后将饼皮包裹住馅料，封口处朝下，形成圆形月饼。\n   - 如果有模具，可将月饼放入模具中，通过模具压花来增加美观度。\n\n4. 烘烤月饼：\n   - 将做好的月饼放在烤盘上，刷上一层薄薄的蛋黄液，增加色泽。\n   - 将烤盘放入预热至180°C的烤箱中，烘烤约15-20分钟或直到月饼表面金黄。\n\n5. 冷却保存：\n   - 将烤好的月饼取出，待其自然冷却。\n   - 月饼冷却后，将其保存在干燥清洁的容器中，可保存半个月至一个月。\n\n这是一般月饼的制作方法，但不同地区和个人有不同的习惯和口味，因此做法可以略有不同。希望你能享受制作和品尝月饼的乐趣！',
      //   isUser: true
      // },
      currentMessages: [],
      newMessage: "",
      chatSettings: {
        model: 'gpt-3.5-turbo',
        temperature: "1",
        top_p: 1
      },
      lastScrollTime: -1
    }
  },
  methods: {
    async userInfoRequest () {
      const res = await getUserInfo(this.token)
      if (res.data.code === 0) {
        this.ai_token = res.data.sys_user_data.ai_token
      }
    },
    onCopyPrompt(prompt) {
      console.log("onCopyPrompt" + prompt)
      clipboard.copy(prompt)
      this.$Message.success('已复制到剪切板')
    },
    showHintModal(id, target, index) {
      console.log("showHintModal..." + id)
      this.hintModalInfo = {
        id,
        target,
        index
      }
      if (target === 'enlarge') {
        this.hintModalInfo.title = "图片放大"
        this.hintModalInfo.content = "确定要放大图片" + index + "吗？每次放大需要消耗1次绘画数"
      } else if (target === 'trans') {
        this.hintModalInfo.title = "图片转换"
        this.hintModalInfo.content = "确定要转换图片" + index + "吗？每次转换需要消耗1次绘画数"
      } else if (target === 'delete') {
        this.hintModalInfo.title = "删除消息"
        this.hintModalInfo.content = "删除消息会将提示词与AI回复一起删除，确定要删除此条消息吗？"
      }
      this.hintModal = true
    },
    handlerOk() {
      if (this.hintModalInfo.target == 'delete') {
        this.messageDeleteRequest()
      }
      this.hintModal = false
    },
    handlerCancel() {
      this.hintModal = false
    },
    // onEnter() {
    //   console.log("onEnter。。。")
    // },
    onKeyDown(event) {
      if (!event.shiftKey && event.keyCode == 13) {
        event.cancelBubble = true; //ie阻止冒泡行为
        event.stopPropagation();//Firefox阻止冒泡行为
        event.preventDefault(); //取消事件的默认动作*换行
        //以下处理发送消息代码
        //console.log(this.textarea);
        this.sendMsg()
      }
    },
    sendMsg() {
      console.log("sendMsg..." + this.newMessage)
      if(this.requesting) {
        this.$Message.warning("请等待AI回复完成再发送")
        return
      }
      if (this.currentTopic.id == -1) {
        this.$Message.warning("请先建一个对话吧")
        return
      }
      if(!this.newMessage || this.newMessage == 0) {
        this.$Message.warning("发送消息不能为空")
        this.newMessage = ""
        return
      }
      if(this.ai_token == null || this.ai_token < 100) {
        this.$Message.warning("对话Token不足，请先去充值吧")
        return
      }
      //this.newMessage = this.newMessage.replace(/\n/g,'<br>')
      this.replyTime = new Date().getTime() + 60000
      this.currentMessages.push({
        id: -1,
        topic_id: this.currentTopic.topic_id,
        user_id: this.currentTopic.user_id,
        text: this.newMessage,
        ai_token: "",
        //parent_message_id: element.parent_message_id,
        //chatgpt_uuid: element.chatgpt_uuid,
        create_time: getCurrentTimeStamp(),
        timeStr: getTimeStr(getCurrentTimeStamp()),
        isUser: true
      });
      this.currentMessages.push({
        id: -1,
        topic_id: this.currentTopic.topic_id,
        user_id: this.currentTopic.user_id,
        text: "",
        ai_token: "",
        //parent_message_id: element.parent_message_id,
        //chatgpt_uuid: element.chatgpt_uuid,
        create_time: getCurrentTimeStamp(),
        timeStr: getTimeStr(getCurrentTimeStamp()),
        isUser: false
      });
      this.scrollToBottomDelay()
      this.chatRequest(this.currentTopic.id, this.newMessage)
      this.newMessage = ""
    },
    async messageDeleteRequest () {
      const res = await deleteMessage(this.token, this.hintModalInfo.id)
      if (res.data.code === 0) {
        this.$Message.success('删除消息成功！')
        setTimeout(() => {
          this.messageListRequest(this.currentTopic.id)
        }, 1000)
      } else {
        this.$Message.error('删除消息失败：' + res.data.message)
      }
      //this.messageListRequest(this.currentTopic.id)
    },
    async messageListRequest (topicID) {
      const res = await getMessageList(this.token, topicID, 1, 100)
      if (res.data.code === 0) {
        if (res.data.records.length > 0) {
          let messages = []
          res.data.records.forEach(element => {
            if (element.chat_reply) {
              messages.unshift({
                id: element.id,
                topic_id: element.topic_id,
                user_id: element.user_id,
                text: element.chat_reply.replace(/\\n/g, "\n"),
                ai_token: element.ai_token,
                parent_message_id: element.parent_message_id,
                chatgpt_uuid: element.chatgpt_uuid,
                create_time: element.create_time,
                timeStr: getTimeStr(element.create_time),
                user_ask: element.user_ask,
                total_tokens: element.total_tokens,
                isUser: false
              });
            }
            if (element.user_ask) {
              messages.unshift({
                id: element.id,
                topic_id: element.topic_id,
                user_id: element.user_id,
                //text: element.user_ask,
                text: element.user_ask.replace(/\\n/g, "\n"),
                ai_token: element.ai_token,
                parent_message_id: element.parent_message_id,
                chatgpt_uuid: element.chatgpt_uuid,
                create_time: element.create_time,
                timeStr: getTimeStr(element.create_time),
                total_tokens: element.total_tokens,
                isUser: true
              });
            }
          });
          this.currentMessages = messages
          this.scrollToBottomDelay()
          // this.sessions.forEach(element => {
          //   if (element.id === topicID) {
          //     element.messages = messages;
          //   }
          // });
          this.addCopyBtn()
        } else {
          this.currentMessages = []
        }
      }
    },
    async chatRequest(topicID, newMsg) {
      console.log("chatSettings..." + this.chatSettings.model + "," + this.chatSettings.temperature)
      this.requesting = true
      const res = await addMessage(this.token, topicID, newMsg, this.chatSettings.model, parseFloat(this.chatSettings.temperature))
      this.requesting = false
      this.userInfoRequest()
      if(res == null || res.data === null || res.data.code !== 0) {
        // const chat_reply = "这是打字机效果。。。1234567890,abcdefghijklmnopqrstuvwxyz，ABCDEFGHIJKLMNOPQRSTUVWXYZ，嗯嗯，哈哈哈哈，噢噢噢噢，这是打字机效果。。。1234567890,abcdefghijklmnopqrstuvwxyz，ABCDEFGHIJKLMNOPQRSTUVWXYZ，嗯嗯，哈哈哈哈，噢噢噢噢这是打字机效果。。。1234567890,abcdefghijklmnopqrstuvwxyz，ABCDEFGHIJKLMNOPQRSTUVWXYZ，嗯嗯，哈哈哈哈，噢噢噢噢这是打字机效果。。。1234567890,abcdefghijklmnopqrstuvwxyz，ABCDEFGHIJKLMNOPQRSTUVWXYZ，嗯嗯，哈哈哈哈，噢噢噢噢这是打字机效果。。。1234567890,abcdefghijklmnopqrstuvwxyz，ABCDEFGHIJKLMNOPQRSTUVWXYZ，嗯嗯，哈哈哈哈，噢噢噢噢这是打字机效果。。。1234567890,abcdefghijklmnopqrstuvwxyz，ABCDEFGHIJKLMNOPQRSTUVWXYZ，嗯嗯，哈哈哈哈，噢噢噢噢这是打字机效果。。。1234567890,abcdefghijklmnopqrstuvwxyz，ABCDEFGHIJKLMNOPQRSTUVWXYZ，嗯嗯，哈哈哈哈，噢噢噢噢这是打字机效果。。。1234567890,abcdefghijklmnopqrstuvwxyz，ABCDEFGHIJKLMNOPQRSTUVWXYZ，嗯嗯，哈哈哈哈，噢噢噢噢";
        // this.currentMessages.push({ text: '', isUser: false, isTyping: true });

        // for (let i = 0; i < chat_reply.length; i++) {
        //   this.currentMessages[this.currentMessages.length - 1].text += chat_reply[i];
        //   await this.delay(10); // 调整延时时间，控制打字速度
        // }

        // this.currentMessages.pop();
        // this.currentMessages.push({ text: chat_reply, isUser: false });
        //this.$Message.error('发送信息失败! ' + res.data.message)
        if(res.data.code >= 1 && this.currentMessages.length >= 2) {
          //4-根据相关法律法规和政策，部分搜索结果未予显示。
          this.currentMessages.splice(this.currentMessages.length - 2, 2)
        }
        this.failReason = res.data.message
        this.failModal = true
        return;
      }
      if(this.currentMessages.length > 0) {
        let lastMessage = this.currentMessages[this.currentMessages.length - 1]
        if(lastMessage.id < 0 && lastMessage.isUser == false) {
          this.currentMessages.splice(this.currentMessages.length - 1, 1)
          console.log("yichu.......zuihou")
        }
      }

      const chat_reply = res.data.data.chat_reply.replace(/\\n/g, "\n"); //替换所有\\n
      this.testcontent2 = chat_reply;
      this.currentMessages.push(
        {
          text: '', 
          isUser: false,
          isTyping: true,
          id: res.data.data.id,
          chatgpt_uuid: res.data.data.chatgpt_uuid,
          chat_type: res.data.data.chat_type,
          completion_tokens: res.data.data.completion_tokens,
          create_time: res.data.data.create_time,
          timeStr: getTimeStr(res.data.data.create_time),
          prompt_tokens: res.data.data.prompt_tokens,
          topic_id:  res.data.data.topic_id,
          total_tokens: res.data.data.total_tokens,
          user_ask: res.data.data.user_ask,
          user_id: res.data.data.user_id
        });
      
      for (let i = 0; i < chat_reply.length; i++) {
        this.scrollToBottomDelay()
        this.currentMessages[this.currentMessages.length - 1].text += chat_reply[i];
        await this.delay(10) // 调整延时时间，控制打字速度
      }
      this.addCopyBtn()

      // this.currentMessages.pop();
      // this.currentMessages.push({ text: chat_reply, isUser: false });
    },
    scrollToBottomDelay() {
      let currentTimeStamp = getCurrentTimeStamp()
      if (currentTimeStamp - this.lastScrollTime > 1) {
        this.lastScrollTime = currentTimeStamp
        setTimeout(this.scrollToBottom, 20)
      }
    },
    scrollToBottom() {
      this.$refs.chatbox.scrollTop = this.$refs.chatbox.scrollHeight;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    //修改对话名称
    onTopicNameModifyed (id, title) {
      console.log("onTopicNameModifyed..." + id + "," + title)
      if (id == this.currentTopic.id) {
        this.currentTopic.title = title
      }
    },
    //清空对话
    onTopicsClean() {
      console.log("onTopicsClean...")
      this.currentTopic = {id: -1}
      this.currentMessages = []
    },
    //清空消息
    onTopicClearMessage (id) {
      console.log("onTopicClearMessage..." + id)
      if (id == this.currentTopic.id) {
        this.currentMessages = []
      }
    },
    //选中对话
    onTopicSelected(topic) {
      console.log("onTopicSelected...")
      console.log(topic)
      this.currentTopic = topic
      this.currentMessages = topic.messages
      if (topic.messages.length == 0) {
        this.messageListRequest(topic.id)
      } else {
        this.scrollToBottomDelay()
      }
    },
    handleReplyTimeEnd() {
      this.replyTime = new Date().getTime() + 60000
    },
    onGptVersionSelect(value) {
      this.chatSettings.model = value
    },
    //没有对话时创建对话
    noChatCreateClick() {
      emitter.emit('requestCreateNewChat', '1')
    },
    //没有登录时登录
    noChatLoginClick() {
      emitter.emit('requestLogin', '1')
    },
    handleResize() {
      //获取屏幕长宽
      let screenWidth = document.documentElement.clientWidth
      let screeHeight = document.documentElement.clientHeight
      console.log("screenWidth..." + screenWidth + ", screeHeight..." + screeHeight)
      if(screenWidth / screeHeight < 1) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      console.log("this.isMobile === " + this.isMobile)
    },
    // 动态添加复制按钮以及复制方法（获取到文档content成功的时候调用此方法）
    addCopyBtn() {
      // let that = this
      // this.$nextTick(() => {
      //   console.log("addCopyBtn.................")
      //   let content = document.getElementsByClassName('v-show-content')[0]
      //   let precodes = content.getElementsByTagName('pre')
      //   let arr = Array.from(precodes)
      //   arr.forEach((element) => {
      //     console.log("aaaaaaaaaaaaaaaa............")
      //     let btn = document.createElement('div')
      //     btn.innerHTML = '复制代码'
      //     btn.style.color = 'red'
      //     btn.style.cursor = 'pointer'
      //     //btn.setAttribute('class', 'code-copy')
      //     btn.addEventListener('click', function(e) {
      //       let code = e.target.parentElement.children[0] // code 标签
      //       let codeText = code.innerText
      //       // 复制函数
      //       //that.shareCopy(codeText)
      //       clipboard.copy(codeText)
      //       that.$Message.success('已复制到剪切板')
      //     })
      //     element.appendChild(btn)
      //   })
      // })
      this.removeCopy()
      let that = this
      this.$nextTick(() => {
        console.log("addCopyBtn.................")
        let contents = document.getElementsByClassName('v-show-content')
        if(contents && contents.length > 0) {
          for(let i=0;i<contents.length;i++) {
            let content = contents[i]
            let precodes = content.getElementsByTagName('pre')
            let arr = Array.from(precodes)
            // let tt = content.getElementsByClassName("code-copy")
            //   console.log("tt...")
            //   console.log(tt)
            arr.forEach((element) => {
              //console.log("aaaaaaaaaaaaaaaa............")
              let btn = document.createElement('div')
              btn.innerHTML = '复制代码'
              btn.style.color = '#3366ff'
              btn.style.cursor = 'pointer'
              btn.style.marginTop = '5px'
              btn.setAttribute('class', 'code-copy')
              btn.addEventListener('click', function(e) {
                let code = e.target.parentElement.children[0] // code 标签
                let codeText = code.innerText
                // 复制函数
                //that.shareCopy(codeText)
                clipboard.copy(codeText)
                that.$Message.success('已复制到剪切板')
              })
              element.appendChild(btn)
              // let tt2 = content.getElementsByClassName("code-copy")
              // console.log("tt2...")
              // console.log(tt2)
            })
          }
        }
        
      })
    },
    removeCopy() {
      let elements = document.getElementsByClassName("code-copy");
      //element.remove();
      console.log("elements...")
      console.log(elements)
      let arr = Array.from(elements)
      if(arr && arr.length > 0) {
        arr.forEach(element => {
          console.log("remove....")
          element.remove()
        });
        // for(let i=0;i<elements.length;i++) {
        //   let el = elements[i]
        //   el.remove()
        // }
      }
    },
    topicNameClicked() {
      if(!this.isMobile) {
        return
      }
      this.topicsDrawer = true
    }
    // 复制代码函数
    // shareCopy(codeText) {
    //   let that = this
    //   let clipboard = new Clipboard('.code-copy', {
    //     text: function() {
    //       return codeText
    //     }
    //   })
    //   clipboard.on('success', e => {
    //   // 复制成功
    //     clipboard.destroy()
    //   })
    //   clipboard.on('error', e => {
    //   // 复制失败
    //     clipboard.destroy()
    //   })

    // }
  },
  created() {
    this.lightmode = this.$store.lightmode
    this.token = localStorage.getItem('token')
    if(this.token && this.token != "") {
      this.isLogin = true
      this.userInfoRequest()
    }
    this.avatar = localStorage.getItem('avatar')
    console.log("this.avatar == undefined --- " + (this.avatar == undefined))
    if(this.avatar == undefined || this.avatar == 'undefined' || this.avatar == null || this.avatar == 'null') {
      this.avatar = ''
    }
  },
  beforeUnmount() {
    emitter.off('loginsuccess')
    emitter.off('loginsuccess-mobile')
    emitter.off('chagelightmode')
    emitter.off('topiclistchange')
  },
  mounted() {
    emitter.on('loginsuccess', (msg) => {
      console.log('ChatHome loginsuccess..' + msg)
      if (msg === '1') {
        this.isLogin = true
        this.token = localStorage.getItem('token');
        this.userInfoRequest()
      } else {
        this.isLogin = false
        this.token = ''
      }
    })
    emitter.on('loginsuccess-mobile', (msg) => {
      console.log('ChatHome loginsuccess-mobile' + msg)
      if (msg === '1') {
        this.isLogin = true
        this.token = localStorage.getItem('token');
        this.userInfoRequest()
      } else {
        this.isLogin = false
        this.token = ''
      }
    })
    emitter.on('chagelightmode', (mode) => {
      this.lightmode = mode
    })
    emitter.on('topiclistchange', (data) => {
      if(data.direct == 'clearMsg') {
        this.onTopicClearMessage(data.topicID)
      } else if(data.direct == 'clearTopics') {
        this.onTopicsClean()
      } else if(data.direct == 'selectTopic') {
        this.onTopicSelected(data.topic)
      } else if(data.direct == 'topicNameModifyed') {
        this.onTopicNameModifyed(data.topicID, data.topicName)
      }
    })
  },
  computed: {
    isPointer: function() {
      if(this.isMobile) {
        return "pointer"
      } else {
        return "default"
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '@/views/GeneralStyle.less';
.chat-home {
  display: flex;
  height: 100%;
  .chat-list {
    flex: 1;
    min-width: 200px;
  }
  
  .chat-back {
    flex: 7.5;
    display: flex;
    flex-direction: column;
    .chat-top {
      height: 50px;
      display: flex;
      justify-content: space-between;
      padding-left: 10px;
      //padding-right: 10px;
      .topicname {
        //color: black;
        display: flex;
        align-items: center; //flex高度居中方式
        min-width: 200px;
        //color: #181818;
        .token-left {
          margin-left: 20px;
          margin-right: 5px;
        }
      }

      .topic-options {
        padding-right: 20px;
        display: flex;
        align-items: center;
        .model-title {
          margin-left: 5px;
          white-space: nowrap;
        }
        .option-selected {
          background-color: #08C160;
          margin-left: 6px;
          color: white;
        }
        .option-unselected {
          background-color: #aaaaaa;
          border: none;
          margin-left: 6px;
          color: white;
        }
      }
    }
    .chat-content {
      padding-left: 3%;
      padding-right: 3%;
      padding-top: 10px;
      padding-bottom: 10px;
      overflow: scroll;
      flex: 7;
    }
    .chat-bottom {
      border: none;
      padding-left: 10px;
      padding-right: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
      flex: 1;
      display: flex;
      align-items: center;
      text-align: center;
      .send-input {
        flex: 1;
      }
      .send-btn {
        width: 80px;
        margin-left: 10px;
        background-color: #08C160;
      }
    }
  }
}

.user-send {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .leftseizeseat {
    width: 50px;
    min-width: 50px;
  }
  .right {
    //background-color: yellow;
    display: flex;
    flex-direction: row;
    
    .user-msg-bigback {
      //text-align: right;
      //background-color: #0091ff;
      display: flex;
      flex-direction: column;
      align-items: end;
      .user-msg-back {
        padding: 5px;
        border-radius: 5px;
        display: inline-block;
        text-align: left;
        //background-color: rgba(8, 193, 96, 0.4);
        .msg-text {
          opacity: 1;
          color: #181818;
        }
      }
      .user-time-back {
        margin-bottom: 2px;
        display: flex;
        align-items: center;
      }
    }
    
    .user-head {
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
      margin-left: 5px;
      background-color: #bde4b5;
    }
  }
}

.gpt-reply {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .left {
    display: flex;
    flex-direction: row;
    .gpt-msg-bigback {
      text-align: left;
      .gpt-msg-back {
        padding: 5px;
        border-radius: 5px;
        display: inline-block;
        text-align: left;
        background-color: #E0E0E0;
      }
      .gpt-time-back {
        margin-top: 2px;
      }
    }
    .gpt-head {
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
      margin-right: 5px;
      background-color: #5023aa;
    }
  }
  .rightseizeseat {
    width: 50px;
    min-width: 50px;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 0px; //水平的隐藏
}
//背景
::-webkit-scrollbar-track {
  background-color: #F3F3F3;
}
//滑块
::-webkit-scrollbar-thumb {
  background-color: #cccccc;
}

.msg-tool-btn {
  margin-left: 10px;
}

.chat-time {
  color: #ADADAD;
  font-size: 0.8rem;
  opacity: 1;
  margin-left: 10px;
}
.chat-time-left {
  color: #ADADAD;
  font-size: 0.8rem;
  opacity: 1;
}

.uhead {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #08C160;
  aspect-ratio: 1/1;
  margin-left: 5px;
  margin-right: 5px;
}

/deep/.ivu-input {
  background-color: #F7F7F7;
}


.copy-button {
  padding: 2px 8px !important;
  color: #ffffff !important;
  background-color: #9999aa !important;
  margin-bottom: 5px !important;
  border-radius: 5px !important;
  outline: none !important;
  border: none !important;
}

.copy-button {
  float: right !important;
}

.copy-button:hover {
  cursor: pointer !important;
  background-color: black !important;
}

.code-copy {
  cursor: pointer;
  color: #08C160;
}

.vertical-center-modal{
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-center-modal .ivu-modal{
    top: 0;
}
</style>